import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { styled } from "../../../components/styled";
import SpText from "../../../components/atoms/SpText";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getAllPromsSurveyAnswers } from "../../../models/actions/Proms";
import { psTranslate } from "../../shared/translations";
import { dateFormat } from "../../../utils/common";
import moment from "moment-timezone";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";

const PromsTableDetails = (props) => {
  const [currentProms, setCurrentProms] = useState([]);
  const [jsonExcel, setJsonExcel] = useState();
  const [headers, setHeaders] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dates, setDates] = useState([]);
  const reference = createRef(null);

  const { proms, setLoading, patId } = props;

  const fetchData = async () => {
    const patientResults = await getAllPromsSurveyAnswers({
      id_patients: [patId],
      starting_date: moment().subtract(6, "month").format(dateFormat),
      ending_date: moment().add(1, "day").format(dateFormat),
      proms: proms,
    });
    setCurrentProms(patientResults);
  };

  const createHeaders = () => {
    let dataArray = currentProms[0].data.map(({ date, id }) => ({
      date: new Date(date),
      id,
    }));
    const tempDates = dataArray.sort((a, b) => b.date - a.date);
    setDates(tempDates);
    let tempCategories = [];
    currentProms[0].data[0].proms_answers.map(({ proms_question }) =>
      tempCategories.push(proms_question?.question)
    );

    const tempHeaders = [
      {
        id: "category",
        numeric: false,
        disablePadding: false,
        label: psTranslate(currentProms[0].data[0].prom.name),
        isAction: false,
      },
    ];

    tempDates.map(({ date, id }) => {
      tempHeaders.push({
        id: id,
        numeric: false,
        disablePadding: false,
        label: moment(date).format("DD-MM-YYYY"),
        isAction: false,
      });
    });

    setCategories([...new Set(tempCategories)].map((item) => ({ name: item })));
    setHeaders(tempHeaders);
  };

  useEffect(() => {
    console.log("entrato dattetgliii");
    fetchData();
  }, []);

  useEffect(() => {
    if (currentProms.length > 0) {
      createHeaders();
    }
  }, [currentProms]);

  const AssessmentRow = ({ row, index }) => (
    <SpTableRow key={row} tabIndex={index}>
      <SpTableCell style={{ minWidth: "20em" }}>
        <SpText variant={"h4ComponentLabel"}>{psTranslate(row.name)}</SpText>
      </SpTableCell>

      {dates.map(({ id }) => {
        return (
          <SpTableCell style={{ minWidth: "15em" }}>
            {psTranslate(
              currentProms[0].data.find(({ id: selID }) => selID == id)
            )
              ?.proms_answers?.filter(
                ({ proms_question }) => proms_question.question == row.name
              )
              .map(({ answer }) => (
                <SpText variant={"h4ComponentLabel"}>{answer}</SpText>
              ))}
          </SpTableCell>
        );
      })}
    </SpTableRow>
  );

  return (
    categories &&
    categories.length > 0 && (
      <SpTable
        headCells={headers}
        pagination={true}
        rows={categories}
        rowKey="id"
        padding={false}
        notCheckable={false}
        sortable={true}
      >
        <AssessmentRow />
      </SpTable>
    )
  );
};
export default withSnackbar(PromsTableDetails);
