import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const RestInputDetails = ({
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,

  setValue,
  register,
  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  handleSync,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
  snackbarShowErrorMessage,
  snackbarShowMessage,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const { patId, groupId } = useParams();
  const history = useHistory();

  useEffect(() => {
    setLoadParametersList(loadParametersData);
  }, [loadParametersData]);

  useEffect(() => {
    if (templateData) {
      setValue("rest_motivation", templateData.rest_motivation);
    }
  }, [templateData]);

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <SpTextInput
        name="rest_motivation"
        inputRef={register}
        formControlStyle={{ width: "100%" }}
        label={
          labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
        }
        disabled={disabled}
        isPrint={isPrint}
      />
      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "rest",
          showFeedback,
          setShowFeedback,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default RestInputDetails;
