import { call } from "./utils";

export const addPatientToProfessional = async (data) =>
  await call({
    url: "/patients/add/to/professional",
    data: { data },
  });

export const linkPatientToProfessional = async (data) =>
  await call({
    url: "/patient/link/professional",
    data: { data },
  });

export const findPatientByPk = async (data) =>
  await call({ url: "/patient/by/id", data: { data } });

export const findPatients = async (data) =>
  await call({ url: "/patient/by/pattern", data: { data } });

export const updatePatient = async (data) =>
  await call({ url: "/patient/update", data: { data } });

export const getPatientTrainingNotes = async (data) =>
  await call({ url: "/patient/notes", data: { data } });

export const getPatientTrainingStatus = async (data) =>
  await call({ url: "/patient/status", data: { data } });

export const getPatientRegistry = async (data) =>
  await call({ url: "/patient/registry", data: { data } });

export const updatePatientRegistry = async (data) =>
  await call({ url: "/patient/registry/update", data: { data } });

export const getPatientPastHistory = async (data) =>
  await call({ url: "/past/history/by/patient", data: { data } });

export const updatePatientPastHistory = async (data) =>
  await call({ url: "/past/history/update", data: { data } });
export const getPatientPastHistoryBasic = async (data) =>
  await call({ url: "/past/history/basic/by/patient", data: { data } });

export const updatePatientPastHistoryBasic = async (data) =>
  await call({ url: "/past/history/basic/update", data: { data } });

export const acceptRequest = async (data) =>
  await call({ url: "/patient/accept/request", data: { data } });

export const refuseRequest = async (data) =>
  await call({ url: "/patient/refuse/request", data: { data } });

export const getPatientPastHistoryAnswers = async (data) =>
  await call({
    url: "/patient/past/history/answers",
    data: { data },
  });

export const getPatientPastHistorySumup = async (data) =>
  await call({
    url: "/patient/past/history/sumup",
    data: { data },
  });

export const sendCompilationRequestToPatient = async (data) =>
  await call({
    url: "/patient/past/history/send/compilation/request",
    data: { data },
  });

export const savePastHistoryPathologies = async (data) =>
  await call({
    url: "/patient/past/history/save/answers",
    data: { data },
  });

export const deletePastHistoryPathology = async (data) =>
  await call({
    url: "/patient/past/history/delete/pathology",
    data: { data },
  });

export const getPatientPastHistoryPathologies = async (data) =>
  await call({
    url: "/patient/past/history/pathologies",
    data: { data },
  });

export const getPatientRegistryAnswers = async (data) =>
  await call({
    url: "/patient/registry/get/answers",
    data: { data },
  });

export const setUserProfileImage = async (data) =>
  await call({ url: "/patient/set/profile/image", data: { data } });

export const getUserProfileImage = async (data) =>
  await call({ url: "/patient/get/profile/image", data: { data } });

export const getRedFlagsByPatientId = async (data) =>
  await call({ url: "/patient/get/red/flags", data: { data } });

export const setAnalyticsConfigurationBy = async (data) =>
  await call({
    url: "/patient/set/analytics/configuration/by",
    data: { data },
  });
export const getAnalyticsConfigurationBy = async (data) =>
  await call({
    url: "/patient/get/analytics/configuration/by",
    data: { data },
  });
export const setStatisticsConfigurationBy = async (data) =>
  await call({
    url: "/patient/set/statistics/configuration/by",
    data: { data },
  });
export const getStatisticsConfigurationBy = async (data) =>
  await call({
    url: "/patient/get/statistics/configuration/by",
    data: { data },
  });

export const getCharts = async () => await call({ url: "/patient/get/charts" });

export const getStudies = async () =>
  await call({ url: "/patient/get/studies" });

export const getTimeViews = async () =>
  await call({ url: "/patient/get/timeviews" });

export const getCombinedParametersFor = async (data) =>
  await call({
    url: "/patient/get/feedback/parameter/combined",
    data: { data },
  });

export const getActivityResponseParameterFeedbacks = async (data) =>
  await call({
    url: "/patient/get/activity/response/parameter/feedbacks",
    data: { data },
  });

export const getActivityResponseParameterFeedbacksGroups = async (data) =>
  await call({
    url: "/patient/get/activity/response/parameter/feedbacks/groups",
    data: { data },
  });

export const fetchPatAnswersToQuest = async (data) =>
  await call({
    url: "/patient/get/questionnaires/response",
    data: { data },
  });

export const saveControlQuestionnaires = async (data) =>
  await call({
    url: "/patient/save/questionnaires",
    data: { data },
  });

export const removeFeedbackById = async (data) =>
  await call({
    url: "/patient/delete/questionnaire",
    data: { data },
  });

export const getPatientsNames = async () =>
  await call({ url: "/patients/names" });

export const getPatientsNamesSameGroupOf = async (data) =>
  await call({
    url: "/patients/same/groups/names",
    data: { data },
  });

export const getGroupsSameGroupOf = async (data) =>
  await call({ url: "/groups/same/groups", data: { data } });

export const getMeasurementsParameters = async (data) =>
  await call({ url: "/patient/get/measure/parameters", data: { data } });

export const getMeasurementsParametersAnswers = async (data) =>
  await call({
    url: "/patient/get/measure/parameters/answers",
    data: { data },
  });

export const getMeasurementsParametersAnswersGroups = async (data) =>
  await call({
    url: "/patient/get/measure/parameters/answers/groups",
    data: { data },
  });

export const deletePatient = async (data) =>
  await call({
    url: "/patient/delete",
    data: { data },
  });

export const getReadinessTable = async (data) =>
  await call({
    url: "/get/readiness/table",
    data: { data },
  });
export const getSports = async (data) =>
  await call({
    url: "/get/sports",
    data: { data },
  });
