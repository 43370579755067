import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { getExerciseTemplateDetail } from "../../../models/actions/Excercise";
import ExerciseSheetRows from "../../mylab/MyLabAddTemp/Rows/ExerciseSheetRows";
import { labels } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const ExerciseInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,

  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  defaultParameter,
  control,
  setValue,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  handleSync,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
  snackbarShowErrorMessage,
  snackbarShowMessage,
}) => {
  const {
    fields: fieldsExercise,
    append: appendExcercise,
    remove: removeExcercise,
  } = useFieldArray({
    control,
    name: "excercises",
    keyName: "id",
    defaultValues: [],
  });
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();
  const { patId, groupId } = useParams();

  useEffect(async () => {
    const templateData = await getExerciseTemplateDetail({
      id_template: selectedTemplate,
    });
    if (templateData) {
      removeExcercise();
      setValue("quantity_excercise", templateData.excercise_quantity);
      appendExcercise(templateData.excercise_sheet_template_rows);
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeExcercise();
      setValue("excercises_motivation", templateData.excercises_motivation);
      setValue("quantity_excercise", templateData.excercises?.length ?? 0);
      appendExcercise(templateData.excercises);
    }
  }, [templateData]);

  const fieldExercisesUpdated = useWatch({
    name: "excercises",
    control: control,
  });

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid item xs={12} container style={{ justifyContent: "flex-start" }}>
        <SpTextInput
          name="excercises_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
        <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
          <SpIconButton
            variant="activityAddRemoveButton"
            buttonType={"accept"}
            width={10}
            onClick={() => {
              removeExcercise(fieldsExercise.length - 1);
              if (fieldsExercise.length > 0)
                setValue("quantity_excercise", fieldsExercise.length - 1);
            }}
            disabled={disabled}
          >
            <Remove />
          </SpIconButton>
          <SpTextInput
            type="number"
            name="quantity_excercise"
            inputRef={register}
            formControlStyle={{
              marginLeft: "3px",
              maxWidth: 150,
              width: "100%",
            }}
            label={labels.mylab.tempAdd.excerciseSheet.inputs.quantity}
            disabled={disabled}
            isPrint={isPrint}
          />
          <SpIconButton
            variant="activityAddRemoveButton"
            width={10}
            buttonType={"accept"}
            onClick={() => {
              appendExcercise({ id: fieldsExercise.length });
              setValue("quantity_excercise", fieldsExercise.length + 1);
            }}
            disabled={disabled}
          >
            <Add />
          </SpIconButton>
        </div>
      </Grid>

      {
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          internalLoadOnly={true}
        />
      }

      {fieldsExercise.length > 0 && (
        <Grid xs={12} direction="row" container style={{ marginTop: "10px" }}>
          <SpText variant="h1PageSubtitle">
            {labels.mylab.tempAdd.excerciseSheet.list.title}
          </SpText>
        </Grid>
      )}
      {fieldsExercise.length > 0 && (
        <Grid item xs={12} container>
          <ExerciseSheetRows
            isPrint={isPrint}
            rows={fieldExercisesUpdated}
            updateRow={(idx, key, value) => {
              setValue(`excercises[${idx}].${key}`, value);
            }}
          />
        </Grid>
      )}
      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "exercise",
          showFeedback,
          setShowFeedback,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default ExerciseInputDetails;
