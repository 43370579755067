import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../App.css";
import SpButton from "../../../../components/atoms/SpButton";
import SpText from "../../../../components/atoms/SpText";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import {
  getPathologyMonitoringDetails,
  updatePathologyEndDate,
} from "../../../../models/actions/Pathologies";
import { labels, psTranslate } from "../../../shared/translations";
import { styled } from "../../../../components/styled";
import moment from "moment";
import { useForm } from "react-hook-form";
import { getAllCalendarActivityByMonitoring } from "../../../../models/actions/CalendarActivity";
import { getSelectmenu } from "../../../../utils/common";
import SpTextInput from "../../../../components/atoms/SpTextInput";

const PatientsMonitoringDetailPathology = (props) => {
  const [pathologyDetails, setPathologyDetails] = useState();
  const [monitoringPathologyDetails, setMonitoringPathologyDetails] =
    useState();
  const [timeLoss, setTimeLoss] = useState([]);
  const { patId } = useParams();

  const { pathology, setLoading } = props;

  const {
    reset,
    register,
    errors,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getPathologyMonitoringDetails({
        id_pathology: pathology?.pathology?.id,
        id_monitoring_pathology: pathology?.id,
        id_patient: patId,
      });

      let startDateIllness = moment(
        result?.pathologyMonitoring?.start_date
      ).format("YYYY-MM-DD");
      setValue("startDate", startDateIllness);
      setValue("endDate", result?.pathologyMonitoring?.end_date);

      setLoading(false);
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        setPathologyDetails(result?.pathology);
        setMonitoringPathologyDetails(result?.pathologyMonitoring);
        setValue("selectreinjury", result?.pathologyMonitoring?.reinjury);
        setValue("selecttimeloss", result?.pathologyMonitoring?.time_loss);
        setValue("selectactivity", result?.pathologyMonitoring?.activity);

        setTimeLoss(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.timeLoss.name,
            labels.patient.presentation.add.stepper.step0.detail.timeLoss
              .choise,
            `selecttimeloss`,
            labels.patient.presentation.add.stepper.step0.detail.timeLoss.name,
            control
          )
        );
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const endPathology = async () => {
    try {
      const newData = {
        id_patient: patId,
        id_monitoring_pathology: pathology?.id,
        activity: getValues("selectactivity"),
        time_loss: getValues("selecttimeloss"),
        reinjury: getValues("selectreinjury"),
        end_date: getValues("endDate") ? getValues("endDate") : null,
        start_date: getValues("startDate")
          ? getValues("startDate")
          : moment().format("YYYY-MM-DD"),
      };
      const result = await updatePathologyEndDate(newData);
      if (result.message) {
        props.snackbarShowMessage(result.message);
        props.closeAssessmentAdd();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const StyledRow = styled("div")({
    flex: 1,
    display: "flex",
    width: "100%",
  });

  return (
    <Grid container direction="column" spacing={1} style={{ marginTop: "3%" }}>
      <StyledRow style={{ marginTop: "2%" }}>
        <Grid item xs={4} direction="row">
          {monitoringPathologyDetails?.end_date ? (
            <Grid item xs={4} direction="row">
              <SpText variant="text">
                {labels.patient.monitoring.pathologiesStep.table.toDate}
              </SpText>
              <SpText variant="h4ComponentLabelRegular">
                {monitoringPathologyDetails?.start_date &&
                  moment(monitoringPathologyDetails?.start_date).format(
                    "DD/MM/YYYY"
                  )}
              </SpText>
            </Grid>
          ) : (
            <SpTextInput
              name="startDate"
              type="date"
              id="startDate"
              maxValue={"2100-12-31"}
              defaultValue={
                getValues("startDate")
                  ? moment(getValues("startDate")).format("DD/MM/YYYY")
                  : null
              }
              inputRef={register}
              style={{ width: "100%" }}
              label={labels.patient.monitoring.pathologiesStep.table.toDate}
            />
          )}
        </Grid>
        {monitoringPathologyDetails?.end_date ? (
          <Grid item xs={4} direction="row">
            <SpText variant="text">
              {labels.patient.monitoring.pathologiesStep.table.fromDate}
            </SpText>
            <SpText variant="h4ComponentLabelRegular">
              {monitoringPathologyDetails?.end_date &&
                moment(monitoringPathologyDetails?.end_date).format(
                  "DD/MM/YYYY"
                )}
            </SpText>
          </Grid>
        ) : (
          <Grid item xs={4} direction="row">
            <SpTextInput
              name="endDate"
              type="date"
              id="endDate"
              maxValue={"2100-12-31"}
              defaultValue={
                getValues("endDate")
                  ? moment(getValues("endDate")).format("DD/MM/YYYY")
                  : null
              }
              inputRef={register}
              style={{ width: "100%" }}
              label={labels.patient.monitoring.pathologiesStep.table.fromDate}
            />
          </Grid>
        )}
      </StyledRow>
      <StyledRow style={{ marginTop: "2%" }}>
        <Grid item xs={4} direction="row">
          {timeLoss}
        </Grid>
      </StyledRow>

      <StyledRow style={{ marginTop: "2%" }}>
        <Grid item xs={12} direction="row">
          <SpText variant="text">
            {labels.patient.monitoring.pathologiesStep.detail.pathologyName}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(pathologyDetails?.name)}
          </SpText>
        </Grid>

        <Grid item xs={12} direction="row">
          <SpText variant="text">
            {labels.patient.monitoring.pathologiesStep.detail.etiology}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {pathologyDetails?.etiology?.name
              ? psTranslate(pathologyDetails?.etiology?.name)
              : "-----"}
          </SpText>
        </Grid>

        <Grid item xs={12} direction="row">
          <SpText variant="text">
            {labels.patient.monitoring.pathologiesStep.detail.medicalSystem}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {pathologyDetails?.medical_system_type?.name
              ? psTranslate(pathologyDetails?.medical_system_type?.name)
              : "-----"}
          </SpText>
        </Grid>
      </StyledRow>

      <Grid item xs={12} direction="row">
        <SpText variant="text">
          {labels.patient.monitoring.pathologiesStep.detail.note}
        </SpText>
        <SpText variant="h4ComponentLabelRegular">
          {monitoringPathologyDetails?.notes}
        </SpText>
      </Grid>
      <StyledRow style={{ marginTop: "2%" }}>
        <Grid item xs={12}>
          {/* {!monitoringPathologyDetails?.end_date && ( */}
          <SpButton
            text={labels.patient.monitoring.pathologiesStep.saveAndClose}
            onClick={() => endPathology()}
            buttonType="accept"
          />
          {/* )} */}
        </Grid>
      </StyledRow>
    </Grid>
  );
};

export default withSnackbar(PatientsMonitoringDetailPathology);
