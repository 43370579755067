import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import { Grid } from "@material-ui/core";
import {
  getAreasByRegion,
  getDysfunctionsCategoriesByDisorderType,
  getInterviewByArea,
  getInterviewByRegion,
  getPathologyByArea,
  getPathologyByRegion,
  getPathologyByStructure,
  getStructuresByArea,
  getPathologies,
} from "../../../models/actions/Pathologies";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { getRegionAreaStructureLists } from "../../../models/actions/Activity";
import {
  getPresentationTypeList,
  updatePresentation as updatePresentationBE,
} from "../../../models/actions/Presentation";
import SpButton from "../../../components/atoms/SpButton";
import { getSortedAndTranslatedArray, rollbar } from "../../../utils/common";

const PatientsPresentationUpdate = (props) => {
  const [pathologies, setPathologies] = useState([]);
  const [pathologySelected, setPathologySelected] = useState({});
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [structures, setStructures] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [areaSelected, setAreaSelected] = useState(null);
  const [structureSelected, setStructureSelected] = useState(null);
  const [structureFiltered, setStructureFiltered] = useState([]);
  const [regionSelected, setRegionSelected] = useState(null);
  const [presentationTypeList, setPresentationTypeList] = useState();
  const [dysfunctionTypeList, setDysfunctionTypeList] = useState();
  const [refreshData, setRefreshData] = useState(false);

  const { control, handleSubmit, getValues, setValue, watch, reset } = useForm({
    shouldUnregister: false,
  });

  const watchRegionSelect = watch("id_region");
  const watchAreaSelect = watch("id_area");
  const watchStructureSelect = watch("id_structure");
  const watchTypeSelect = watch("type");
  const { patId } = useParams();

  const { setLoading } = props;

  const defaultValues = {
    type: null,
    dysfunction: null,
    severity: null,
    id_area: null,
    id_region: null,
    id_structure: null,
  };

  useEffect(async () => {
    //set all pathologies
    let pathologiesTemp = await getPathologies();
    pathologiesTemp.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );
    setPathologies(pathologiesTemp);

    fetchData();
    if (props?.presentation) {
      setRegionSelected(props.presentation.region?.key);
      setValue("id_region", props.presentation.id_region);
      setValue("id_area", props.presentation.id_area);
      setStructureSelected(props.presentation.structure?.id);
      const newPathSelected = pathologiesTemp.find(
        (el) => el?.id == props.presentation?.pathology.id
      );
      setPathologySelected(newPathSelected);

      setValue("dysfunction", newPathSelected.dysfunction.id);

      setValue("id_structure", props.presentation.structure?.id);
      await updateRegionFunction(props.presentation.id_region);
      fetchStructuresByArea(props.presentation.id_area);
    }
  }, []);

  useEffect(async () => {
    await fetchData();
  }, [refreshData]);

  const updateRegionFunction = async (idRegion) => {
    let foundRegion = regions.find((region) => region.key === regionSelected);
    if (idRegion)
      foundRegion = regions.find((region) => region.id === idRegion);
    if (foundRegion) {
      const areasnew = areas?.filter(
        ({ id_region }) => foundRegion.id == id_region
      );
      const newstructures = structures.filter(({ id_area }) =>
        areasnew.map(({ id }) => id).includes(id_area)
      );
      setStructureFiltered(getSortedAndTranslatedArray(newstructures));
    }
    setValue("id_region", foundRegion?.id);
  };

  useEffect(() => {
    updateRegionFunction();
  }, [regionSelected]);

  useEffect(() => {
    const foundArea = areas.find((area) => area.key === areaSelected);
    setValue("id_area", foundArea?.id);
  }, [areaSelected]);

  const updateStructureFunction = async (idStructure) => {
    setInterviews(null);
    const data = idStructure ? idStructure : getValues("id_region");
    if (data) {
      if (!getValues("id_structure") && !getValues("id_area")) {
        await fetchAreasByRegion(data);
        await fetchInterviewRegion(data);
        await fetchPathologiesByRegion(data);
      }
    }
  };

  useEffect(async () => {
    await updateStructureFunction();
  }, [watchRegionSelect]);

  useEffect(async () => {
    const data = getValues("id_area");
    setInterviews(null);
    if (data) {
      //looking for the structure in the array
      if (!getValues("id_region")) {
        const foundArea = areas.find((elem) => elem.id == data);
        if (foundArea) setValue("id_region", foundArea?.id_region);
      }
      if (!getValues("id_structure")) {
        await fetchStructuresByArea(data);
        await fetchInterviewArea(data);
        await fetchPathologiesByArea(data);
      }
    }
  }, [watchAreaSelect]);

  useEffect(async () => {
    const data = getValues("id_structure");
    if (data) {
      //looking for the structure in the array
      if (!getValues("id_area")) {
        const foundStructure = structures.find((elem) => elem.id == data);

        if (foundStructure) setValue("id_area", foundStructure?.id_area);
      }
      await fetchPathologiesByStructure(data);
    }
  }, [watchStructureSelect]);

  useEffect(async () => {
    await fetchDysfunctionsCategoriesByDisorderTypeAndStructures();
  }, [watchTypeSelect]);

  const resetFunction = () => {
    reset(defaultValues);
    setRefreshData(!refreshData);
    setRegionSelected(null);
    setAreaSelected(null);
    setStructureSelected(null);
    setPathologySelected(null);
  };

  const saveOrUpdatePresentation = async (data, next) => {
    data.id_patient = patId;
    let newData = data;
    newData.id_pathology = pathologySelected?.id;
    // time loss injury as default
    newData.time_loss = 1;

    Object.keys(data).map((key) => {
      if (!data[key]) {
        newData[key] = null;
      }
    });

    try {
      newData["pathology_operation_type"] =
        labels.patient.presentation.pathology_operation_type;
      newData["id_presentation"] = props.presentation.id;
      newData["start_date"] = props.presentation.start_date;
      newData["end_date"] = props.presentation.end_date;
      newData["activity"] = props.presentation.activity;
      newData["activity_during_injury"] =
        props.presentation.activity_during_injury;
      newData["club_national"] = props.presentation.club_national;
      newData["estimated_end_date"] = props.presentation.estimated_end_date;
      newData["intervention"] = props.presentation.intervention;
      newData["mechanism"] = props.presentation.mechanism;
      newData["note"] = props.presentation.note;
      newData["onset"] = props.presentation.onset;
      newData["part"] = props.presentation.part;
      newData["reinjury"] = props.presentation.reinjury;
      newData["relationship"] = props.presentation.relationship;
      newData["specific_action"] = props.presentation.specific_action;

      await updatePresentationBE(newData);
      await props.closeUpdate();
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - savePresentation", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getRegionAreaStructureLists();
      if (result) {
        setRegions(getSortedAndTranslatedArray(result.regionList));
        setAreas(getSortedAndTranslatedArray(result.areaList));
        setStructures(getSortedAndTranslatedArray(result.structureList));
        setStructureFiltered(getSortedAndTranslatedArray(result.structureList));
      }
      const resultPresentationTypeList = await getPresentationTypeList();
      if (resultPresentationTypeList) {
        setPresentationTypeList(resultPresentationTypeList);
        const tempCurrPresType = resultPresentationTypeList.find(
          (synthom) => synthom.name === "Infortunio"
        );
        setValue("type", tempCurrPresType.name);
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchData", error);
      props.snackbarShowErrorMessage(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInterviewRegion = async (id_region) => {
    try {
      if (!getValues("id_area")) {
        let currentInterviews;
        currentInterviews = await getInterviewByRegion({
          id_region: id_region,
        });
        if (currentInterviews.length > 1) {
          setInterviews(currentInterviews);
        } else {
          if (currentInterviews.length === 1) {
            setValue("id_interview", currentInterviews[0]?.id_interview);
          }
        }
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchInterviewRegion",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchInterviewArea = async (id_area) => {
    try {
      let currentInterviews;
      currentInterviews = await getInterviewByArea({ id_area: id_area });
      if (currentInterviews.length > 1) {
        setInterviews(currentInterviews);
      } else {
        if (currentInterviews.length === 1) {
          setValue("id_interview", currentInterviews[0]?.id_interview);
        }
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchInterviewArea", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchAreasByRegion = async (id_region) => {
    try {
      if (id_region) {
        const currentAreas = await getAreasByRegion({ id_region: id_region });
        setAreas(getSortedAndTranslatedArray(currentAreas));
      }
    } catch (error) {
      rollbar.error("PatientsPresentationAddStep1 - fetchAreasByRegion", error);
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchStructuresByArea = async (id_area) => {
    try {
      if (id_area) {
        const currentStructures = await getStructuresByArea({
          id_area: id_area,
        });
        setStructures(
          getSortedAndTranslatedArray(currentStructures?.structures)
        );
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchStructuresByArea",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchPathologiesByRegion = async (id_region) => {
    try {
      if (id_region) {
        const currentPathologies = await getPathologyByRegion({
          id_region: id_region,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByRegion",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchPathologiesByArea = async (id_area) => {
    try {
      if (id_area) {
        const currentPathologies = await getPathologyByArea({
          id_area: id_area,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByArea",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };
  const fetchPathologiesByStructure = async (id_structure) => {
    try {
      if (id_structure) {
        const currentPathologies = await getPathologyByStructure({
          id_structure: id_structure,
        });
        if (
          currentPathologies &&
          currentPathologies[0] &&
          currentPathologies[0].pathologies
        )
          setPathologies(currentPathologies[0]?.pathologies);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchPathologiesByStructure",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchDysfunctionsCategoriesByDisorderTypeAndStructures = async () => {
    try {
      const disorder_type = presentationTypeList?.find(
        (elem) => elem?.name == getValues("type")
      )?.id;
      if (disorder_type) {
        let currentDysfunctionCategories =
          await getDysfunctionsCategoriesByDisorderType({
            id_dysfunction_category: disorder_type,
          });
        //order currentDysfunctionCategories
        currentDysfunctionCategories.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setDysfunctionTypeList(currentDysfunctionCategories);
      }
    } catch (error) {
      rollbar.error(
        "PatientsPresentationAddStep1 - fetchDysfunctionsCategoriesByDisorderTypeAndStructures",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const formClearFieldsAfter = (startField) => {
    const ids = ["", "id_region", "id_area", "id_interview", "id_structure"];
    const startId = ids.findIndex((elem) => elem === startField) + 1;
    if (startId !== 0)
      for (let i = startId; i < ids.length; i++) setValue(ids[i], null);
  };

  const updateArea = async (newArea) => {
    //if area exist update area and region
    if (!newArea) {
      setAreaSelected(null);
    } else {
      const updateArea = areas.find((area) => area.id === newArea.id_area);
      const updateRegion = regions.find((el) => el.id == updateArea.id_region);
      await fetchInterviewArea(updateArea.id);
      setAreaSelected(updateArea.key);
      setRegionSelected(updateRegion.key);
    }
  };

  return (
    <form onSubmit={handleSubmit(saveOrUpdatePresentation)}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs={12} container spacing={1}>
          <Grid item container style={{ height: "100%" }} xs={4}>
            <Grid
              item
              container
              direction="column"
              style={{ height: "100%" }}
              xs={12}
            >
              <SpAutocomplete
                id="regionAutocomplete"
                formControlWidth={"100%"}
                label={labels.patient.presentation.add.selectRegion}
                value={regions.find((el) => el.key == regionSelected) ?? ""}
                selectPlaceholder={labels.patient.presentation.add.selectAnswer}
                onChange={(e, newValue) => {
                  setRegionSelected(newValue?.key);
                  setValue("id_region", newValue?.id);
                  formClearFieldsAfter("id_region");
                }}
                options={regions}
                getOptionLabel={(option) => psTranslate(option.name)}
                getOptionSelected={(option, value) => option?.id === value?.id}
                isRequired={true}
              />
              <SpAutocomplete
                id="structureAutocomplete"
                formControlWidth={"100%"}
                label={labels.patient.presentation.add.selectStructure}
                value={
                  structures.find((el) => el.id == structureSelected) ?? ""
                }
                selectPlaceholder={labels.patient.presentation.add.selectAnswer}
                onChange={async (e, newValue) => {
                  await updateArea(newValue);
                  setStructureSelected(newValue?.id);
                  setValue("id_structure", newValue?.id);
                  formClearFieldsAfter("id_structure");
                }}
                options={structureFiltered}
                getOptionLabel={(option) => psTranslate(option.name)}
                getOptionSelected={(option, value) => option.id === value?.id}
                isRequired={false}
              />
              {/* DIAGNOSI */}
              <SpAutocomplete
                id="pathologyAutocomplete"
                formControlWidth={"100%"}
                label={labels.patient.presentation.add.selectPathology}
                value={pathologySelected}
                selectPlaceholder={labels.patient.presentation.add.selectAnswer}
                onChange={(e, newValue) => {
                  if (newValue?.structures?.length > 0) {
                    setStructures(newValue.structures);
                  }
                  if (newValue?.dysfunction) {
                    setValue("dysfunction", newValue.dysfunction.id);
                  }
                  if (newValue?.id) {
                    setPathologySelected(
                      pathologies.find((el) => el?.id == newValue.id)
                    );
                  } else {
                    setPathologySelected(null);
                    setValue("dysfunction", null);
                  }
                }}
                options={pathologies}
                getOptionLabel={(option) => psTranslate(option.name)}
                getOptionSelected={(option, value) => option?.id === value?.id}
              />
              {/* TIPO DI PATOLOGIA == dysfunction */}
              <Controller
                style={{ marginTop: "3%", minWidth: "100%" }}
                render={(props) => (
                  <SpSelect
                    id={"dysfunctionSelect"}
                    label={labels.patient.presentation.add.dysfunction}
                    value={props.value}
                    selectPlaceholder={
                      labels.patient.presentation.add.selectAnswer
                    }
                    disabled={pathologySelected}
                    onChange={(e) => {
                      props.onChange(e.target.value);
                    }}
                  >
                    {dysfunctionTypeList?.map((p) => (
                      <SpSelectMenuItem key={p.id} value={p.id}>
                        {psTranslate(p.name)}
                      </SpSelectMenuItem>
                    ))}
                  </SpSelect>
                )}
                defaultValue={""}
                name={`dysfunction`}
                control={control}
              />

              {interviews && interviews.length > 1 && (
                <Controller
                  render={(props) => (
                    <SpSelect
                      label={labels.patient.presentation.add.selectInterview}
                      disabled={!getValues("id_region")}
                      value={props.value}
                      selectPlaceholder={
                        labels.patient.presentation.add.selectAnswer
                      }
                      onChange={(e) => props.onChange(e.target.value)}
                    >
                      {interviews.map((p) => (
                        <SpSelectMenuItem
                          key={p.id_interview}
                          value={p.id_interview}
                        >
                          {psTranslate(p.name_interview)}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  style={{ marginTop: "3%", minWidth: "100% !important" }}
                  defaultValue={""}
                  name={`id_interview`}
                  control={control}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={8} style={{ minHeight: 400 }}>
            <Sp3DModel
              modelBehaviour={"MARKER"}
              type="PATIENT"
              hideFilters={true}
              setSelectedRegion={setRegionSelected}
              setSelectedArea={setAreaSelected}
              selectedRegion={regionSelected}
              selectedArea={areaSelected}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        xs={4}
        direction="column"
        style={{ width: "98%", minWidth: 0, marginTop: 10 }}
      >
        <SpButton
          id="saveButton"
          buttonType="accept"
          style={{ width: "98%", minWidth: 0 }}
          text={labels.patient.presentation.add.save}
          variant="lightGreenFill"
          disabled={!regionSelected}
          type="submit"
        />
        <SpButton
          buttonType="accept"
          style={{ width: "98%", minWidth: 0, marginTop: "10px" }}
          text={labels.general.reset}
          variant="lightGreenFill"
          onClick={resetFunction}
        />
      </Grid>
    </form>
  );
};

export default withSnackbar(PatientsPresentationUpdate);
