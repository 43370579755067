import { Grid, Divider } from "@material-ui/core";
import React, { useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const A8_RehabilitationDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
  isPrint = false,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const { patId, groupId } = useParams();
  const history = useHistory();
  const RowRehabilitation = ({ row, index }) => (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      {index > 0 && (
        <Divider
          style={{
            padding: "1px",
            width: "100%",
            backgroundColor: isPrint ? "white" : theme.colors.primary.lightBlue,
          }}
        />
      )}
      <Grid
        item
        xs={12}
        container
        justify="flex-start"
        style={{ marginTop: index > 0 && "1%" }}
      >
        <Grid item xs={4}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
            {row?.activity}
          </SpText>
        </Grid>

        <Grid item xs={4}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
            {row?.duration}
          </SpText>
        </Grid>

        <Grid item xs={4}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
            {row?.description}
          </SpText>
        </Grid>
      </Grid>
    </div>
  );

  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
        {data?.rehabilitation_motivation}
      </SpText>
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
            {data.name}
          </SpText>
        </Grid>
        {data?.rehabilitations?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.treatment.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.rehabilitations?.length > 0 && (
          <Grid
            item
            xs={12}
            container
            style={{
              border: `1px solid ${
                isPrint ? "white" : theme.colors.primary.lightBlue
              }`,
            }}
          >
            <Grid item xs={12}>
              <SpList
                rows={data?.rehabilitations}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.treatment.list.header.map((head) => (
                      <Grid key={head} item xs={4}>
                        <SpText
                          variant={isPrint ? "textPrint" : "listHeaderCenter"}
                        >
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowRehabilitation
                  headers={labels.mylab.tempAdd.treatment.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}

        {allowFeedback &&
          showFeedbacksGrid({
            event,
            patId,
            groupId,
            activity: "rehabilitation",
            showFeedback,
            setShowFeedback,
          })}

        {allowFeedback &&
          showFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ))}
      </Grid>
    </>
  );
};

export default A8_RehabilitationDisabledDetails;
