/**
 * Componente creato per definire i grafici da utilizzare nella sezione Injury
 * A seconda del tipo selezionato questo componenete richiama delle funzioni nel percorso
 * ./patientsReportInjury/ per delegare la logica della costruzione del grafico e della modellazione
 * dei dati da mostare.
 * Individuati i dati, questi vengono passati al componente ./patientsReportInjury/TrainingReportGraph
 * che li renderizza
 *
 * @param props
 * @returns {JSX.Element}
 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../../App.css";
import moment from "moment";
import { Grid } from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import {
  getGroupsById,
  listGroupsByProfessional,
} from "../../../../models/actions/Groups";
import SpLoader from "../../../../components/atoms/SpLoader";
import { getAllCalendarActivityByMonitGroup } from "../../../../models/actions/CalendarActivity";
import {
  getGroupPatients,
  getGroupsSelection,
  getHeader,
  getPatientsSelection,
  getRange,
} from "../patientsReportInjury/InjuryReportCommonFunction";

import { labels } from "../../../shared/translations";
import TrainingReport from "../patientsReportInjury/InjuryReportGraph";
import {
  getPatientSelected,
  groupsDetails,
  selectGroupFilteredByMonth,
  selectPatients,
  selectPatientsFilteredByMonth,
} from "../patientsReportInjury/ExposureFunction";
import SpIconButton from "../../../../components/atoms/SpIconButton";
import { theme } from "../../../../components/theme";

// set data range
const DEFAULT_RANGE = getRange();
const dateFormat = "YYYY-MM-DD";
const trainingLabel = labels.analytics.injuryReport.graphs.training;
const partitaLabel = labels.analytics.injuryReport.graphs.partita;
const ratioTMLabel = labels.analytics.injuryReport.graphs.ratioTM;

const PatientsReportsStep5Exposure = (props) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groups, setGroups] = useState([]);
  const [trainingGroups, setTrainingGroups] = useState([]);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [barCharTraining, setBarCharTraining] = useState([]);
  const [barCharMatch, setBarCharMatch] = useState([]);
  const [barCharRatio, setBarCharRatio] = useState([]);
  const [dataChartTraining, setDataChartTraining] = useState({});
  const [dataChartMonth, setDataChartMonth] = useState({});
  const [dataChartMatch, setDataChartMatch] = useState({});
  const [dataChartRatio, setDataChartRatio] = useState({});

  const [fullSizeTraining, setFullSizeTraining] = useState(false);
  const [fullSizeMatch, setFullSizeMatch] = useState(false);
  const [fullSizeRatio, setFullSizeRatio] = useState(false);
  const [fullSizeMonth, setFullSizeMonth] = useState(false);

  const { patId, groupId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    try {
      if (patId) {
        // for patients
        const response = await listGroupsByProfessional();
        const result = getGroupPatients(response, patId);
        setPatients(result.allPatients);

        // seleziono i gruppi dell'atleta
        const tempGroupPatient = result.mapGroup.filter(({ patients }) =>
          patients.find(({ id }) => parseInt(id) === parseInt(patId))
        );

        setGroups(tempGroupPatient);
        // get allenamenti per ogni gruppo
        await selectExposureActivity(
          tempGroupPatient,
          dateRange,
          result.allPatients
        );
      } else {
        // groups
        let tempGroups = await listGroupsByProfessional();
        tempGroups = tempGroups.map(({ group }) => group);
        setGroups(tempGroups);
        const groupsSelected = tempGroups.find(
          ({ id }) => parseInt(id) === parseInt(groupId)
        );

        setSelectedGroups([groupsSelected]);
        await selectExposureActivity(
          [groupsSelected],
          dateRange,
          groupsSelected.patients
        );
      }
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error.message);
    }
  }, []);

  /**
   * Funzione che filtra le attivita' legate all'esposizione, inizializza il grafico con
   * i valori per ogni signolo gruppo
   *
   * @param mapGroup: lista dei gruppi selezionati
   * @param newDateRange: data range selezionata in modo da filtrare in base alla data le attivita'
   * @param tempPatients: patients
   *
   */
  const selectExposureActivity = async (
    mapGroup,
    newDateRange,
    tempPatients = patients
  ) => {
    let temp = [];
    setSelectedGroups(mapGroup);
    setLoading(true);
    for (let tempGroup of mapGroup) {
      let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
        id_group: tempGroup.id,
        hide_feedbacks: true,
        start_date: moment(newDateRange?.start).format("YYYY-MM-DD"),
        end_date: moment(newDateRange?.end).format("YYYY-MM-DD"),
      });
      let item = {};
      item["idGruppo"] = tempGroup.id;
      item["nameGruppo"] = tempGroup.name;
      if (!newDateRange) newDateRange = dateRange;
      allCalendarActivity = allCalendarActivity.filter(
        (item) =>
          moment(item.start_date) > newDateRange.start &&
          moment(item.start_date) < newDateRange.end
      );

      item[trainingLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "training"
      );

      item[partitaLabel] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === "race"
      );

      temp.push(item);
    }
    setSelectedGroups(mapGroup);
    setTrainingGroups(temp);
    setSelectedPatients([]);
    const tempPat = tempPatients.find(({ id }) => id === parseInt(patId));
    if (patId) {
      await setDataByMonthFunctionPatient(tempPat ? [tempPat] : [], temp);
    } else {
      await setDataByMonthFunctionGroup(mapGroup, temp);
    }
    await selectionActionFunction(
      tempPat ? [tempPat] : [],
      "gruppo",
      mapGroup,
      mapGroup,
      temp
    );

    setLoading(false);
  };

  const setDataByMonthFunctionGroup = (
    group,
    tempTrainingGroups = trainingGroups
  ) => {
    return new Promise((resolve, reject) => {
      try {
        let result = selectGroupFilteredByMonth(
          group,
          dateRange,
          tempTrainingGroups
        );
        setDataChartMonth(result);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  const setDataByMonthFunctionPatient = (
    patients,
    tempTrainingGroups = trainingGroups
  ) => {
    return new Promise((resolve, reject) => {
      try {
        let resultMonth = selectPatientsFilteredByMonth(
          patients.find(({ id }) => parseInt(id) === parseInt(patId)),
          tempTrainingGroups,
          trainingLabel,
          dateRange
        );
        setDataChartMonth(resultMonth.dataChart);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  };

  /**
   * Funzione che viene richiamata quando viene cambiato il paziente o il gruppo
   * da visualizzare
   *
   * @param patients: lista di pazienti da visulizzare
   * @param type: variabile utilizzata per capire da qualce dropdown deriva la selezione
   * del paziente
   * @param newSelectedGroups: lista di gruppi da visualizzare
   * @param tempGroup: group selected
   * @param tempTrainingGroups: groups
   */
  const selectionActionFunction = (
    patients,
    type,
    newSelectedGroups = selectedGroups,
    tempGroup = groups,
    tempTrainingGroups = trainingGroups
  ) => {
    return new Promise((resolve, reject) => {
      setSelectedGroups(newSelectedGroups);
      // calcolo dei pazienti selezionati rispetto al dropdown di riferimento
      let newPatientsSelected = getPatientSelected(
        patients,
        type,
        selectedPatients
      );

      let resultTraining;
      let resultMatch;
      let resultRatio;
      // per ogni gruppo calcolo la media delle attivita'
      let groupsAvarageTraining = groupsDetails(
        newSelectedGroups,
        tempTrainingGroups,
        trainingLabel
      );
      let groupsAvarageMatch = groupsDetails(
        newSelectedGroups,
        tempTrainingGroups,
        partitaLabel
      );
      let groupsAvarageRatio = groupsDetails(
        newSelectedGroups,
        tempTrainingGroups,
        ratioTMLabel
      );

      // calcolo delle attivita' per ogni paziente selezionato
      resultTraining = selectPatients(
        newPatientsSelected,
        tempTrainingGroups,
        trainingLabel,
        tempGroup
      );
      resultMatch = selectPatients(
        newPatientsSelected,
        tempTrainingGroups,
        partitaLabel,
        tempGroup
      );
      resultRatio = selectPatients(
        newPatientsSelected,
        tempTrainingGroups,
        ratioTMLabel,
        tempGroup
      );

      // concateno il risultato dei gruppi con quello dei pazienti
      resultTraining.dataChart = resultTraining.dataChart.concat(
        groupsAvarageTraining
      );
      resultMatch.dataChart = resultMatch.dataChart.concat(groupsAvarageMatch);
      resultRatio.dataChart = resultRatio.dataChart.concat(groupsAvarageRatio);

      setLoading(true);

      setDataChartTraining(resultTraining.dataChart);
      setDataChartMatch(resultMatch.dataChart);
      setDataChartRatio(resultRatio.dataChart);
      setSelectedPatients(newPatientsSelected);
      setBarCharTraining(resultTraining.barChart);
      setBarCharMatch(resultMatch.barChart);
      setBarCharRatio(resultRatio.barChart);
      setLoading(false);
      resolve();
    });
  };

  /**
   * Funzione che viene richiamata quando il range temporale selezionato cambia
   *
   * @param newDateRange: nuovo range temporale
   *
   */
  const changeDate = async (newDateRange) => {
    setLoading(true);
    if (newDateRange.start < newDateRange.end) {
      if (patId) {
        setSelectedGroups([]);
        await selectExposureActivity(groups, newDateRange);
      } else {
        selectExposureActivity(selectedGroups, newDateRange);
      }

      setDateRange(newDateRange);
    }
    setLoading(false);
  };

  return (
    <Grid container item xs={12} spacing={2}>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid container item xs={4}>
        {/* selezione del range temporale */}
        {getHeader(changeDate, dateRange, dateFormat)}
      </Grid>
      <Grid item container xs={8}>
        {/* selezione dei pazienti */}
        {patId &&
          getPatientsSelection(
            patients,
            selectedPatients,
            selectionActionFunction
          )}

        {groupId &&
          getGroupsSelection(groups, selectedGroups, selectExposureActivity)}
      </Grid>

      <Grid container item xs={12} spacing={2}>
        <Grid
          container
          item
          xs={fullSizeTraining ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .trainingSessionLabel
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeTraining(!fullSizeTraining);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeTraining ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport
            dataChart={dataChartTraining}
            barChar={barCharTraining}
          />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeMatch ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .matchSessionLabel
              }
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMatch(!fullSizeMatch);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMatch ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport dataChart={dataChartMatch} barChar={barCharMatch} />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeRatio ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {labels.patient.graphReport.section.feedback.filters.ratioLabel}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeRatio(!fullSizeRatio);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeRatio ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>
          <TrainingReport dataChart={dataChartRatio} barChar={barCharRatio} />
        </Grid>
        <Grid
          container
          item
          xs={fullSizeMonth ? 12 : 6}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {/* grafico finale */}
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.graphReport.section.feedback.filters
                  .ratioMonthLabel
              }{" "}
              {patId
                ? `${
                    patients.find(({ id }) => parseInt(id) === parseInt(patId))
                      ?.givenName
                  } ${
                    patients.find(({ id }) => parseInt(id) === parseInt(patId))
                      ?.familyName
                  }`
                : `${selectedGroups[0]?.name}`}
            </SpText>
            <SpIconButton
              onClick={() => {
                setFullSizeMonth(!fullSizeMonth);
              }}
              alignSelf="flex-end"
              color={theme.colors.primary.lightBlue}
              variant={"lightBlue"}
              small
            >
              {fullSizeMonth ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </SpIconButton>
          </Grid>

          <TrainingReport dataChart={dataChartMonth} barChar={barCharRatio} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsReportsStep5Exposure);
