import React, { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa il plugin per le etichette dei dati
import { getLabelFromPatientStatus, titleCase } from "../utils/common";
import { labels, psTranslate } from "../../src/pages/shared/translations";
import moment from "moment";

// Registra i componenti di Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
import { Divider, Grid } from "@material-ui/core";

const ReportGenerator = ({
  reportData,
  handleClick,
  setHandleClick,
  setLoading,
}) => {
  useEffect(() => {
    if (handleClick) {
      generatePDF();
      setHandleClick(false);
    }
  }, [handleClick]);

  const generatePDF = () => {
    setLoading(true);
    const doc = new jsPDF();

    // Titolo del report
    doc.setFontSize(18);
    doc.text("Report Generale", 10, 10);

    // Statistiche generali
    doc.setFontSize(12);
    doc.text("Statistiche Generali", 10, 20);
    const stats = [
      [psTranslate("Soddisfazione Media"), reportData.averageSatisfaction],
      [psTranslate("Numero di Pazienti"), reportData.patientNumber],
      [psTranslate("Numero di Gruppi"), reportData.groupNumber],
      [psTranslate("Numero di Attività"), reportData.activityNumber],
      [
        psTranslate("Tipo di Presentazione Più Frequente"),
        reportData.mostFrequentPresentationType,
      ],
      [
        psTranslate("Giorni di Presentazione più Lunghi"),
        reportData.longerPresentationDays,
      ],
      [psTranslate("Area Più Colpita"), reportData.mostAffectedArea],
      [psTranslate("Struttura Più Colpita"), reportData.mostAffectedStructure],
    ];
    doc.autoTable({
      startY: 25,
      head: [["Descrizione", "Valore"]],
      body: stats,
    });

    // Grafico delle presentazioni per regione
    const presentationChart = document.getElementById("presentationChart");
    if (presentationChart) {
      const chartImage = presentationChart.toDataURL("image/png");
      doc.addPage();
      doc.text(labels.account.accountReports.injuryRegion, 10, 10);
      doc.addImage(chartImage, "PNG", 10, 20, 180, 90);
    }
    // Grafico delle presentazioni per regione
    reportData.availability.map((athlete, index) => {
      const patientChart = document.getElementById(`patientChart${index}`);
      if (patientChart) {
        const chartImage = patientChart.toDataURL("image/png");
        if (index % 2 == 0) doc.addPage();
        doc.text(
          titleCase(
            `${athlete.patient.givenName} ${athlete.patient.familyName}`
          ),
          10,
          index % 2 == 0 ? 10 : 120
        );
        doc.addImage(chartImage, "PNG", 10, index % 2 == 0 ? 20 : 130, 180, 90);
      }
    });

    // Grafico delle attività
    const activityChart = document.getElementById("activityChart");
    if (activityChart) {
      doc.addPage();
      const activityImage = activityChart.toDataURL("image/png");
      doc.text(labels.analytics.account.activityFreq, 10, 10);
      doc.addImage(activityImage, "PNG", 10, 20, 180, 90);
    }

    // Grafico delle attività
    const activityDuration = document.getElementById("activityDuration");
    if (activityDuration) {
      doc.addPage();
      const activityImage = activityDuration.toDataURL("image/png");
      doc.text(labels.analytics.account.activityFreq, 10, 10);
      doc.addImage(activityImage, "PNG", 10, 20, 180, 90);
    }

    // Salvare il PDF
    setLoading(false);
    doc.save(`report${moment().format("DD/MM/YYYY")}.pdf`);
  };

  // Dati per il grafico delle presentazioni per regione
  const regionLabels = reportData.presentationRows.map((row) => row.name);
  const regionCounts = reportData.presentationRows.map(
    (row) => row.regionCount
  );

  const presentationData = {
    labels: regionLabels,
    datasets: [
      {
        label: labels.account.accountReports.injuryRegion,
        data: regionCounts,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        datalabels: {
          color: "white", // Colore del testo dei numeri
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: (value, context) => {
            // Prendi il nome dell'attività
            const activityName = context.chart.data.labels[context.dataIndex];
            // Trova l'attività corrispondente nel reportData.presentationRows
            const activityRow = reportData.presentationRows.find(
              (row) => row.name === activityName
            );
            // Se trovi l'attività, prendi il totalDuration (in minuti) e aggiungi l'etichetta
            const totalDuration = activityRow ? activityRow.daysPassed : 0;

            // Restituisci la formattazione desiderata
            return `${value} ${labels.groups.federationAdd.injuryList.title}\n${totalDuration} ${labels.analytics.account.days}`;
          },
        },
      },
    ],
  };

  // Funzione per raggruppare i dati di stato di salute per atleta
  const processHealthData = (calendarStatus) => {
    const grouped = calendarStatus.reduce((acc, entry) => {
      acc[entry.key] = (acc[entry.key] || 0) + 1;
      return acc;
    }, {});
    return grouped;
  };

  const processSubactivities = (data) => {
    return data.reduce((acc, item) => {
      const { name, duration } = item;
      const durationNumber = parseFloat(duration); // Converte la durata in numero

      // Aggiungi la durata al totale dell'attività
      if (acc[name]) {
        acc[name] += durationNumber;
      } else {
        acc[name] = durationNumber;
      }

      return acc;
    }, {});
  };

  // Dati per il grafico delle attività
  const activityLabels = reportData.activitiesRows.map(
    (row) => row.name || "Non specificato"
  );
  const activityCounts = reportData.activitiesRows.map(
    (row) => row.activityCount
  );

  const activityData = {
    labels: activityLabels,
    datasets: [
      {
        label: labels.account.accountReports.minActivity,
        data: activityCounts,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        datalabels: {
          color: "white", // Colore del testo dei numeri
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: (value, context) => {
            // Prendi il nome dell'attività
            const activityName = context.chart.data.labels[context.dataIndex];

            // Trova l'attività corrispondente nel reportData.activitiesRows
            const activityRow = reportData.activitiesRows.find(
              (row) => row.name === activityName
            );

            // Se trovi l'attività, prendi il totalDuration (in minuti) e aggiungi l'etichetta
            const totalDuration = activityRow ? activityRow.totalDuration : 0;

            // Calcolare i minuti
            const minutes = Math.round(totalDuration / 60); // Converti i secondi in minuti

            // Restituisci la formattazione desiderata
            return `${value} att.\n${minutes} min`;
          },
        },
      },
    ],
  };

  // Colori per ogni tipo di attività
  const activityColors = {
    assessment: "rgba(75, 192, 192, 0.6)",
    treatment: "rgba(255, 99, 132, 0.6)",
    exercise: "rgba(54, 162, 235, 0.6)",
    training: "rgba(255, 206, 86, 0.6)",
    rest: "rgba(153, 102, 255, 0.6)",
    appointment: "rgba(255, 159, 64, 0.6)",
    race: "rgba(100, 100, 100, 0.6)",
    rehabilitation: "rgba(200, 99, 132, 0.6)",
    supplement: "rgba(75, 192, 192, 0.3)",
  };

  const darkenColor = (rgba, factor = 0.8) => {
    // Estrae i valori R, G, B e A dal colore
    const match = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([\d.]+)\)/);
    if (!match) return rgba; // Se il colore non è in formato RGBA, ritorna il colore originale

    const [_, r, g, b, a] = match;
    return `rgba(${Math.floor(r * factor)}, ${Math.floor(
      g * factor
    )}, ${Math.floor(b * factor)}, ${a})`;
  };

  // Raggruppa i dati per creare dataset del grafico
  const generateChartData = () => {
    const athleteNames = reportData.availability.map(
      (athlete, index) =>
        titleCase(
          `${athlete.patient.givenName} ${athlete.patient.familyName}`
        ) || `Atleta ${index + 1}`
    );

    const activityTypes = Object.keys(activityColors);

    // Creare un dataset per ogni attività
    const datasets = activityTypes.map((activityType) => {
      const darkenedColor = "rgb(255, 255, 255)";

      return {
        label: activityType,
        data: reportData.availability.map((athlete) => {
          const activity = athlete.monitoring_table.find(
            (a) => a.name === activityType
          );
          return activity ? activity.total_duration : 0;
        }),
        backgroundColor: activityColors[activityType],
        datalabels: {
          color: darkenedColor,
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: (value, context) => value,
        },
      };
    });

    return {
      labels: athleteNames,
      datasets,
    };
  };

  const chartData = generateChartData();

  const options = {
    indexAxis: "y", // Inverti l'asse da verticale a orizzontale
    plugins: {
      legend: { position: "top" },
      tooltip: { mode: "index", intersect: false },
    },
    responsive: true,
    scales: {
      x: { stacked: true, title: { display: true, text: "Durata (minuti)" } },
      y: { stacked: true },
    },
  };

  const defaulltOptions = {
    indexAxis: "x", // o "y" se hai un grafico orizzontale
    responsive: true,
    elements: {
      bar: {
        maxBarThickness: 4, // Imposta la larghezza massima delle barre
      },
    },
    scales: {
      xAxes: [
        {
          maxBarThickness: 10,
        },
      ],
    },
  };

  const groupedAthletes = reportData.availability.reduce(
    (result, athlete, index) => {
      const groupIndex = Math.floor(index / 2); // Raggruppa per coppie di 2
      if (!result[groupIndex]) result[groupIndex] = [];
      result[groupIndex].push(athlete);
      return result;
    },
    []
  );

  return (
    <div>
      <div>
        <h2>{labels.account.accountReports.injuryRegion}</h2>
        <div style={{ margin: "0 auto" }}>
          <Bar
            data={presentationData}
            options={defaulltOptions}
            id="presentationChart"
            barSize={100}
          />
        </div>
        <h2>{labels.account.accountReports.minActivity}</h2>
        <div style={{ margin: "0 auto" }}>
          <Bar
            data={activityData}
            options={defaulltOptions}
            id="activityChart"
            barSize={40}
          />
        </div>
      </div>
      <h2>Grafici degli Atleti</h2>
      <div>
        <Grid container spacing={3}>
          {groupedAthletes.map((group, groupIndex) => (
            <Grid container item xs={12} spacing={3} key={groupIndex}>
              {group.map((athlete, index) => {
                const healthData = processHealthData(athlete.calendarStatus);
                const treatmentData = processSubactivities(
                  athlete.monitoring_table.find(
                    ({ key }) => key == "treatmentSubactivities"
                  )?.data
                );
                const labelsTextHealth = Object.keys(healthData).map((item) =>
                  getLabelFromPatientStatus({ status: item })
                );
                const labelsTextTreatment = Object.keys(treatmentData).map(
                  (item) => psTranslate(item)
                );

                const datahealth = Object.values(healthData);
                const dataTreatment = Object.values(treatmentData);
                const dataAvarage = Object.values(reportData.daysAvarage);

                const chartData = {
                  labels: labelsTextHealth,
                  datasets: [
                    {
                      label: titleCase(
                        `${athlete.patient.givenName} ${athlete.patient.familyName}`
                      ),
                      data: datahealth,
                      backgroundColor: ["rgba(75, 192, 192, 0.6)"],
                      datalabels: {
                        color: "white", // Colore del testo dei numeri
                        font: {
                          weight: "bold",
                          size: 12,
                        },
                        formatter: (value) => {
                          return `${value} days`;
                        },
                      },
                    },
                    {
                      label: labels.analytics.average,
                      data: dataAvarage,
                      backgroundColor: "rgba(255, 159, 64, 0.6)", // Colore distintivo
                      datalabels: {
                        color: "white", // Colore del testo dei numeri
                        font: {
                          weight: "bold",
                          size: 12,
                        },
                        formatter: (value) => {
                          return `${value} days`;
                        },
                      },
                    },
                  ],
                };
                const chartDataTreatment = {
                  labels: labelsTextTreatment,
                  datasets: [
                    {
                      label: titleCase(
                        `${labels.account.accountReports.treatmentsOf} ${athlete.patient.givenName} ${athlete.patient.familyName} `
                      ),
                      data: dataTreatment,
                      backgroundColor: ["rgba(75, 192, 192, 0.6)"],
                      datalabels: {
                        color: "white", // Colore del testo dei numeri
                        font: {
                          weight: "bold",
                          size: 12,
                        },
                        formatter: (value) => {
                          return `${value} minutes`;
                        },
                      },
                    },
                  ],
                };

                return (
                  <Grid item xs={12} sm={6} key={index}>
                    <h3>
                      {`${athlete.patient.givenName} ${athlete.patient.familyName}` ||
                        `Atleta ${groupIndex * 2 + index + 1}`}
                    </h3>
                    <Bar
                      data={chartData}
                      options={defaulltOptions}
                      id={`patientChart${groupIndex * 2 + index}`}
                    />
                    <Bar
                      data={chartDataTreatment}
                      options={defaulltOptions}
                      id={`patientChartTreatment${groupIndex * 2 + index}`}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Grid>
      </div>
      <h2>{labels.account.accountReports.activityDuration}</h2>
      <Bar data={chartData} options={options} id="activityDuration" />
    </div>
  );
};

export default ReportGenerator;
