import React, { createRef, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../App.css";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { Grid } from "@material-ui/core";
import { getMonitoringData } from "../../../models/actions/Monitoring";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import moment from "moment";
import SpDialog from "../../../components/atoms/SpDialog";
import { makeStyles } from "@material-ui/core/styles";
import AgendaCalendarEventCard from "../../agenda/AgendaCalendarEventCard";
import { Controller, useForm } from "react-hook-form";

import { psTranslate, labels } from "../../shared/translations";
import {
  LegendStatus,
  SelectPeriod,
  SingleCell,
  activities,
  activityExists,
  getActivity,
} from "../../groups/situation/SituationTableHelper";
import {
  downloadExcel,
  getColorFromPatientStatus,
} from "../../../utils/common";
import { getPresentationsByPatProf } from "../../../models/actions/Presentation";

const useStyles = makeStyles((theme) => ({
  root: {
    "&::-webkit-scrollbar": {
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      background: "#012e29",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#31caad",
    },
  },
}));

const tempActivities = [
  "assessment",
  "treatment",
  "exercise",
  "training",
  "rest",
  "appointment",
  "race",
  "rehabilitation",
  "supplement",
];

const PatientsMonitoringStep4 = (props) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState(
    moment().subtract(1, "months")
  );
  const [filterEndDate, setFilterEndDate] = useState(moment());
  const [openDetailActivity, setOpenDetailActivity] = useState([]);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [monitoringData, setMonitoringData] = useState(null);
  const [presentations, setPresentations] = useState([]);

  const minutesActivitiesCount = useRef(0);
  const scrollingDiv = createRef();
  const classes = useStyles();

  const { patId } = useParams();
  const { setLoading } = props;

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await getPresentationsByPatProf({
        id_patient: patId,
      });

      let newResponseArray = [
        { id: -1, name: labels.patient.patientPlan.defaultSelectValue },
      ];
      response.map((elem) => {
        if (elem.region) {
          newResponseArray.push({
            id: elem?.id,
            name:
              (elem.pathology ? elem.pathology?.name : elem.type) +
              " " +
              elem.region?.name.toLowerCase(),
          });
        }
      });
      setPresentations(newResponseArray);

      const monitoringData = await getMonitoringData({
        filter_start_date: filterStartDate,
        filter_end_date: filterEndDate,
        id_patient: patId,
      });

      if (monitoringData) {
        setMonitoringData(monitoringData);
        const el = document.getElementById("scrollingDiv");
        if (el) el.scrollLeft = el.scrollWidth;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  const renderActivityMonitoring = () =>
    monitoringData?.monitoring?.calendar_status?.map((calendarStatusItem) => {
      return (
        <Grid
          item
          xs
          sm={1}
          container
          direction="column"
          style={{ height: "100%", flexBasis: 0 }}
        >
          <Grid
            item
            xs
            style={{
              minHeight: 40,
              width: 80,
              border: "1px solid #31caad",
              padding: 4,
            }}
          >
            <SpText textAlign={"center"} variant="h4ComponentLabel">
              {calendarStatusItem.date}
            </SpText>
          </Grid>
          {calendarStatusItem.key === "in_illness" ? (
            <Grid
              item
              xs
              style={{
                minHeight: 40,
                width: 80,
                border: "1px solid #31caad",
                padding: 4,
                backgroundColor: "#31cc",
              }}
            />
          ) : (
            <Grid
              item
              xs
              style={{
                minHeight: 40,
                width: 80,
                border: "1px solid #31caad",
                padding: 4,
                backgroundColor: getColorFromPatientStatus({
                  status: calendarStatusItem.key,
                }),
              }}
            />
          )}

          {tempActivities.map((act) => (
            <SingleCell
              isSelectable={activityExists(
                act,
                calendarStatusItem.date,
                monitoringData
              )}
              activityData={getActivity(
                act,
                calendarStatusItem.date,
                monitoringData
              )}
              minutesActivitiesCount={minutesActivitiesCount}
              setSelectedActivity={setSelectedActivity}
              setOpenDetailDialog={setOpenDetailDialog}
              setOpenDetailActivity={setOpenDetailActivity}
              openDetailActivity={openDetailActivity}
            />
          ))}
        </Grid>
      );
    });

  const resetFilters = () => {
    setFilterEndDate(moment());
    setFilterStartDate(moment().subtract(3, "months"));
  };

  const renderActivitySummary = () => {
    return monitoringData?.monitoring_table?.map((row) => {
      // return monitoringData?.monitoring?.calendar_activities?.map((row) => {
      return (
        <Grid item xs={12} container>
          <Grid
            item
            xs={4}
            style={{ border: "1px solid #31caad", padding: "1%" }}
          >
            <SpText variant="text">{psTranslate(row?.name)}</SpText>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ border: "1px solid #31caad", padding: "1%" }}
          >
            <SpText variant="text">{row?.n_sessions}</SpText>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ border: "1px solid #31caad", padding: "1%" }}
          >
            <SpText variant="text">{row?.total_duration}</SpText>
          </Grid>
        </Grid>
      );
    });
  };

  useEffect(() => {
    fetchData();
  }, [filterStartDate, filterEndDate]);

  const downloadAllExcels = () => {
    let itemToExcel = [];
    monitoringData?.monitoring?.calendar_status.map((calendarStatusItem) => {
      let item = {};
      item["Date"] = calendarStatusItem.date;
      item["Status"] = calendarStatusItem.key;
      tempActivities.map((act) => {
        const exist = activityExists(
          act,
          calendarStatusItem.date,
          monitoringData
        );
        item[act] = exist ? "X" : "";
      });
      itemToExcel.push(item);
    });

    const objectToExcel = {
      [`${props?.patient?.givenName} ${props?.patient?.familyName}`]:
        itemToExcel,
    };
    downloadExcel(
      objectToExcel,
      `Monitoring table 1/2 ${props?.patient?.givenName} ${props?.patient?.familyName}`
    );

    itemToExcel = [];
    monitoringData?.monitoring_table?.map((row) => {
      let item = {};
      item["Attività"] = row?.name;
      item["Numero di sessioni"] = row?.n_sessions;
      item["Totale minuti"] = row?.total_duration;
      itemToExcel.push(item);
    });
    downloadExcel(
      {
        [`${props?.patient?.givenName} ${props?.patient?.familyName}`]:
          itemToExcel,
      },
      `Monitoring table 2/2 ${props?.patient?.givenName} ${props?.patient?.familyName}`
    );
  };

  return (
    <>
      <Grid container direction="column">
        <SpText variant="h1PageTitle">
          {labels.patient.monitoring.steps[0].value.toUpperCase()}
        </SpText>
        <div style={{ width: "100%" }}>
          <SelectPeriod
            setFilterStartDate={setFilterStartDate}
            filterStartDate={filterStartDate}
            setFilterEndDate={setFilterEndDate}
            filterEndDate={filterEndDate}
          />
          <Grid item xs={12} container spacing={2} style={{ marginBottom: 5 }}>
            <Grid item xs={6}>
              <SpTextInput
                label={labels.patient.monitoring.table.startDate}
                type="date"
                maxValue={"2100-12-31"}
                variant="text"
                style={{ width: "49%", marginRight: "2px" }}
                value={moment(filterStartDate).format("YYYY-MM-DD")}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
              <SpTextInput
                label={labels.patient.monitoring.table.endDate}
                type="date"
                maxValue={"2100-12-31"}
                variant="text"
                style={{ width: "49%" }}
                value={moment(filterEndDate).format("YYYY-MM-DD")}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                style={{ marginTop: 19 }}
                text={labels.patient.monitoring.table.reset}
                onClick={() => {
                  resetFilters();
                  resetFilters();
                }}
              />
            </Grid>
          </Grid>
        </div>

        {monitoringData &&
          monitoringData.monitoring?.calendar_status?.length > 0 && (
            <div style={{ width: "100%" }}>
              <Grid item xs={12} direction="row" container>
                <Grid xs={6}>
                  <SpText variant="h4ComponentLabel">
                    {labels.patient.monitoring.table.patientSituation}
                  </SpText>
                </Grid>
                <LegendStatus />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                style={{ width: "100%", marginBottom: 5 }}
              >
                <Grid item xs={2} container direction="column">
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  />
                  <Grid
                    item
                    xs
                    style={{
                      maxHeight: 40,
                      border: "1px solid #31caad",
                      padding: 4,
                    }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.status}
                    </SpText>
                  </Grid>
                  {activities.map((act) => (
                    <Grid
                      item
                      xs
                      style={{
                        maxHeight: 40,
                        border: "1px solid #31caad",
                        padding: 4,
                      }}
                    >
                      <SpText variant="h4ComponentLabel">
                        {psTranslate(act)}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  id={"scrollingDiv"}
                  ref={scrollingDiv}
                  item
                  xs={10}
                  container
                  direction="row"
                  wrap={"nowrap"}
                  className={classes.root}
                  style={{
                    height: "100%",
                    overflowX: "scroll",
                    maxWidth: 1200,
                  }}
                >
                  {renderActivityMonitoring()}
                </Grid>
              </Grid>
            </div>
          )}

        {monitoringData &&
          monitoringData.monitoring?.calendar_status?.length > 0 && (
            <div style={{ width: "100%" }}>
              <Grid item xs={12}>
                <SpText variant="h4ComponentLabel">
                  {labels.patient.monitoring.table.statusTable}
                </SpText>
              </Grid>
              <Grid item xs={12} container direction="column">
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel" />
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInPresentation}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInGoodHealth}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.dayInIllness}
                    </SpText>
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.status}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInPresentation}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInGoodHealth}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="text">
                      {monitoringData?.daysInIllness}
                    </SpText>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}

        {monitoringData &&
          monitoringData.monitoring?.calendar_activities?.length > 0 && (
            <div style={{ marginTop: "50px", width: "100%" }}>
              <Grid item xs={12}>
                <SpText variant="h4ComponentLabel">
                  {labels.patient.monitoring.table.activityMonitoringTable}
                </SpText>
              </Grid>
              <Grid item xs={12} container direction="column">
                <Grid item xs={12} container>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.activity}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.sessionNumber}
                    </SpText>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ border: "1px solid #31caad", padding: "1%" }}
                  >
                    <SpText variant="h4ComponentLabel">
                      {labels.patient.monitoring.table.minutesNumber}
                    </SpText>
                  </Grid>
                </Grid>
                {renderActivitySummary()}
              </Grid>
            </div>
          )}
        <SpButton
          style={{ maxWidth: "25%", marginBottom: "10px" }}
          onClick={() => downloadAllExcels()}
          text={"Download excel"}
        />
      </Grid>
      <SpDialog
        style={{ display: "flex", flex: 1 }}
        open={openDetailDialog}
        setOpen={setOpenDetailDialog}
      >
        <AgendaCalendarEventCard
          event={selectedActivity}
          fetchData={fetchData}
          setOpenDetailActivity={setOpenDetailActivity}
          loadParametersData={[]}
          loadParametersList={[]}
          forceDisabled={true}
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          presentations={presentations}
          getValues={getValues}
          setValue={setValue}
        />
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsMonitoringStep4);
