import React, { useEffect } from "react";

import { useDropzone } from "react-dropzone";
import filesize from "filesize";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";

import { styled } from "../styled";
import SpText from "./../atoms/SpText";
import SpIconButton from "./../atoms/SpIconButton";

const DragAndDropContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#333333",
  height: 200,
  borderRadius: 4,
});

export const SpDragDropFileUploader = ({
  dragTitle,
  dragSubTitle,
  clickText,
  onLoad,
  onUploadFile,
  onRemove,
  onDownload,
  files = [],
  maxFiles = 0,
  fileLimitText = "Limit of file reached",
  dropzoneAdditionalProps = {},
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    maxFiles: 1,
    multiple: false,
    disabled: maxFiles && files?.length >= maxFiles ? true : false,
    ...dropzoneAdditionalProps,
  });

  useEffect(() => {
    if (acceptedFiles.length) {
      onUploadFile && onUploadFile(acceptedFiles);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    onLoad && onLoad();
  }, []);

  return (
    <>
      <DragAndDropContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <div style={{ display: "flex", flex: 1 }}>
            <div
              style={{
                display: "flex",
                flex: 1,
                margin: 10,
                height: 140,
                background: "white",
                borderRadius: 4,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SpText
                variant="h6"
                margin="0"
                textAlign={"center"}
                style={{
                  color: "lightBlue",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                {clickText}
              </SpText>
              <div
                className="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <div>
                  {maxFiles && files?.length >= maxFiles ? (
                    <Typography
                      variant={"h5"}
                      style={{ opacity: 0.5, cursor: "not-allowed" }}
                    >
                      {fileLimitText}
                    </Typography>
                  ) : (
                    <Typography variant={"h5"}>{dragTitle}</Typography>
                  )}
                </div>
                {maxFiles && files?.length >= maxFiles ? null : (
                  <div style={{ display: "flex" }}>
                    <input {...getInputProps()} />
                    <SpText variant="text" margin="0">
                      {dragSubTitle}&nbsp;
                    </SpText>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div
						style={{
							display: "flex",
							justifyContent: "flex-end",
							alignItems: "center",
							marginBottom: 10,
						}}
					>
						<SpIconButton variant='violetFill' small='true' disabled={maxFiles && files?.length >= maxFiles}>
							<VerticalAlignTopIcon />
						</SpIconButton>
					</div> */}
        </div>
      </DragAndDropContainer>
      <div style={{ paddingBottom: 5 }}>
        {files.map((file, idx) => (
          <Grid
            key={idx}
            container
            alignItems="center"
            style={{
              margin: 10,
              padding: 5,
              backgroundColor: "#333333",
              width: "auto",
            }}
          >
            <Grid item xs={10}>
              <SpText variant={"h4regular"}>{file?.name}</SpText>
            </Grid>
            <Grid
              item
              container
              xs={2}
              alignItems={"center"}
              style={{ textAlign: "center" }}
            >
              <Grid xs={8}>
                <SpText variant={"h4regular"} textAlign="right">
                  {filesize(file?.size || 0)}
                </SpText>
              </Grid>
              <Grid xs={4}>
                <SpIconButton
                  variant="lightBlueFill"
                  small="true"
                  onClick={() => onDownload(file)}
                >
                  <GetAppIcon />
                </SpIconButton>
                <SpIconButton
                  variant="redFill"
                  small="true"
                  onClick={() => onRemove(file)}
                >
                  <CloseIcon />
                </SpIconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </div>
    </>
  );
};
