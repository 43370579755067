import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { getProtocolTemplateById } from "../../../models/actions/Activity";
import CommonRows from "../../mylab/MyLabAddTemp/Rows/CommonRows";
import { labels, psTranslate } from "../../shared/translations";
import LoadParametersField from "./LoadParametersField";
import SpText from "../../../components/atoms/SpText";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import { theme } from "../../../components/theme";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";
const TreatmentInputDetails = ({
  watchTemplateSelect,
  selectedTemplate,
  templateData,
  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  control,
  setValue,
  getValues,
  register,
  disabled = false,
  handleSubmit,
  updateActivityFeedback,
  handleSync,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
  snackbarShowErrorMessage,
  snackbarShowMessage,
  subactivities,
}) => {
  const { patId, groupId } = useParams();
  const history = useHistory();

  const [showFeedback, setShowFeedback] = useState(false);

  const {
    fields: fieldsTreatment,
    append: appendTreatment,
    remove: removeTreatment,
  } = useFieldArray({
    control,
    name: "treatments",
    keyName: "id",
    defaultValues: [],
  });

  useEffect(() => {
    if (event?.data?.treatments) {
      setValue("quantity_treatment", event?.data?.treatments.length);
      event?.data?.treatments.forEach((treatment, index) => {
        setValue(`treatments[${index}].activity`, treatment["activity"]);
        setValue(`treatments[${index}].duration`, treatment["duration"]);
        setValue(`treatments[${index}].description`, treatment["description"]);
        setValue(`treatments[${index}].name`, treatment["name"]);
      });
    }
  }, [event]);

  useEffect(async () => {
    const templateData = await getProtocolTemplateById({
      id_template: selectedTemplate,
    });

    if (templateData) {
      removeTreatment();
      setValue("quantity_treatment", templateData.quantity);
      appendTreatment(templateData.protocol_template_rows);
      templateData.protocol_template_rows.map((item, index) => {
        const keySub = subactivities.find(
          ({ name }) => name == item["activity"]
        )?.key;

        setValue(`treatments[${index}].activity`, keySub);
        setValue(`treatments[${index}].duration`, item["duration"]);
        setValue(`treatments[${index}].description`, item["description"]);
      });
    }
  }, [watchTemplateSelect]);

  useEffect(() => {
    if (templateData) {
      removeTreatment();
      if (templateData?.treatments_motivation)
        setValue("treatments_motivation", templateData.treatments_motivation);
      if (templateData?.treatments) {
        setValue("quantity_treatment", templateData.treatments.length);
        appendTreatment(templateData.treatments);
      }
    }
  }, [templateData]);

  const fieldTreatmentsUpdated = useWatch({
    name: "treatments",
    control: control,
  });

  const RenderRehabMinutesCount = ({ rows }) => {
    if (rows) {
      const minutesSum = rows.reduce(
        (prev, cur) => prev + (cur.duration ? parseInt(cur.duration) : 0),
        0
      );
      return (
        <SpText
          variant="h1PageSubtitleColored"
          style={{ display: "inline-block", padding: "1em" }}
        >
          {isNaN(minutesSum) ? 0 : minutesSum}{" "}
          {labels.agenda.inputDetails.totalMins}
        </SpText>
      );
    }
    return <></>;
  };

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="treatments_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
        {!showFeedback && (
          <div style={{ width: "22%", display: "flex", flexDirection: "row" }}>
            <SpIconButton
              variant="activityAddRemoveButton"
              buttonType={"accept"}
              width={10}
              onClick={() => {
                removeTreatment(fieldsTreatment.length - 1);
                if (fieldsTreatment.length > 0)
                  setValue("quantity_treatment", fieldsTreatment.length - 1);
              }}
              disabled={disabled}
            >
              <Remove />
            </SpIconButton>
            <SpTextInput
              type="number"
              name="quantity_treatment"
              inputRef={register}
              formControlStyle={{
                marginLeft: "3px",
                maxWidth: 150,
                width: "100%",
              }}
              value={getValues("quantity_treatment")}
              label={labels.mylab.tempAdd.treatment.inputs.quantity}
              disabled={disabled}
              isPrint={isPrint}
            />
            <SpIconButton
              variant="activityAddRemoveButton"
              width={10}
              buttonType={"accept"}
              onClick={() => {
                appendTreatment({ id: fieldsTreatment.length });
                setValue("quantity_treatment", fieldsTreatment.length + 1);
              }}
              disabled={disabled}
            >
              <Add />
            </SpIconButton>
          </div>
        )}
      </Grid>
      {
        <LoadParametersField
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          internalLoadOnly={true}
        />
      }
      {fieldsTreatment.length > 0 && !showFeedback && subactivities && (
        <Grid container spacing={2}>
          <RenderRehabMinutesCount rows={fieldTreatmentsUpdated} />
          {fieldsTreatment.map((item, index) => {
            return (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={3} style={{ paddingTop: "1.2em" }}>
                  <SpAutocomplete
                    formControlWidth={"100%"}
                    disableListWrap
                    selectPlaceholder={
                      labels.groups.groupReports.firstTable.selectAtivity
                    }
                    value={
                      subactivities?.find(
                        (el) =>
                          el.key === getValues(`treatments[${index}].activity`)
                      ) ?? null
                    }
                    onChange={(e, newValue) => {
                      setValue(`treatments[${index}].activity`, newValue.key);
                      setValue(`treatments[${index}].name`, newValue.name);
                      setLoadParametersList([{ prova: 1, enable: 1 }]);
                    }}
                    options={subactivities}
                    getOptionLabel={(option) => psTranslate(option.name)}
                    getOptionSelected={(option, value) =>
                      option.key === value?.key
                    }
                    name={`treatments[${index}].activity`}
                    // filterOptions={filterOptions}
                    renderOption={(option) => (
                      <Grid
                        direction="row"
                        container
                        alignItems={"center"}
                        alignSelf={"center"}
                      >
                        <Grid item xs={11}>
                          <SpText variant="text">
                            {psTranslate(option.name)}
                          </SpText>
                        </Grid>
                      </Grid>
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SpText variant="listHeader">
                    {labels.patient.monitoring.table.minutesNumber}
                  </SpText>

                  <Controller
                    render={(props) => (
                      <SpTextInput
                        isPrint={isPrint}
                        type="number"
                        minValue={0}
                        style={{ width: "100%" }}
                        onChange={(evnt) => {
                          setValue(
                            `treatments[${index}].duration`,
                            evnt.target.value
                          );
                          props.onChange(evnt.target.value);
                        }}
                        value={props.value}
                      />
                    )}
                    defaultValue={getValues(`treatments[${index}].duration`)}
                    name={`treatments[${index}].duration`}
                    control={control}
                  />
                </Grid>
                <Grid item xs={3}>
                  <SpText variant="listHeader">
                    {labels.dashboard.operations.table.header.description}
                  </SpText>
                  <Controller
                    render={(props) => (
                      <SpTextInput
                        isPrint={isPrint}
                        style={{ width: "100%" }}
                        onChange={(evnt) => {
                          setValue(
                            `treatments[${index}].description`,
                            evnt.target.value
                          );
                          props.onChange(evnt.target.value);
                        }}
                        value={props.value}
                      />
                    )}
                    defaultValue={getValues(`treatments[${index}].description`)}
                    name={`treatments[${index}].description`}
                    control={control}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      )}

      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "treatment",
          showFeedback,
          setShowFeedback,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
            snackbarShowErrorMessage={snackbarShowErrorMessage}
            snackbarShowMessage={snackbarShowMessage}
          />
        ))}
    </Grid>
  );
};

export default TreatmentInputDetails;
