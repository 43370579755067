import { Chip, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { theme } from "../../../components/theme";
import { getActivityFeedbackParameters } from "../../../models/actions/Activity";
import { getActivityFeedbacksInRange } from "../../../models/actions/CalendarActivity";
import { getSecondTableGroupsData } from "../../../models/actions/Groups";
import { dateFormat, rollbar } from "../../../utils/common";
import {
  addProxyParameters,
  fixForProxyParams,
  fixForProxyParamsResultsSingle,
  fixProxyParamsListAvailable,
} from "../../patients/patientsReports/FeedbackDataHelper";
import { labels, psTranslate } from "../../shared/translations";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
const PATIENT_KEY = "patient";

const StatisticsFeedbacks = ({
  dateRange,
  currActivities,
  setData,
  tableData,
  activities,
  dateLoaded,
  loadConfiguration,
  setConfig,
  requestSaveFlag,
  currStudyParameters,
  setCurrStudyParameters,
  studyParameters,
  setStudyParameters,
  activeStudyParameters,
  ...props
}) => {
  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currStudyParameters)
        setCurrStudyParameters(loadConfiguration.currStudyParameters);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currStudyParameters: currStudyParameters,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={10}>
        <SpAutocomplete
          label={labels.groups.groupReports.secondTable.chooseParameter}
          multiple
          formControlWidth={"100%"}
          value={currStudyParameters}
          onChange={(_, value) => setCurrStudyParameters(value)}
          options={activeStudyParameters}
          getOptionLabel={(paramId) =>
            psTranslate(
              studyParameters?.find((param) => param.id === paramId)?.name
            ) ?? null
          }
          getOptionSelected={(option, value) => option === value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                }}
                label={psTranslate(
                  studyParameters?.find((param) => param.id === option)?.name
                )}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: "auto", marginBotton: "0px" }}>
        <SpButton
          buttonType={"accept"}
          variant="h4ComponentLabel"
          onClick={() => {
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? setCurrStudyParameters([])
              : setCurrStudyParameters(activeStudyParameters);
          }}
          text={
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? labels.groups.plan.deselectAll
              : labels.groups.plan.selectAll
          }
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(StatisticsFeedbacks);
