import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SpText from "../SpText";
import { labels, psTranslate } from "../../../pages/shared/translations";
import "../App.css";
import SpButton from "../SpButton";
import { SpSelect, SpSelectMenuItem } from "../SpSelect";
import { withSnackbar } from "../SpSnackBar";
import { updateStructureSymbolById } from "../../../models/actions/Pathologies";
import { useParams } from "react-router-dom";

import { getLoggedEvents, ELEMENT_TYPE } from "../../../utils/common";
import { SpElementCrudDetails } from "../../bundles/SpElementCrudDetails";
import SpLoader from "../SpLoader";

const commonDateFormat = "YYYY-MM-DD";

const Sp3DModelSymbolDetails = ({
  editable,
  nowDate,
  selectedSymbol,
  updateSymbolDate,
  setDialogOpen,
  ...props
}) => {
  const { patId } = useParams();

  const [editSeverity, setEditSeverity] = useState();

  const [enableLogEvents, setEnableLogEvents] = useState(false);
  const [loggedEvents, setLoggedEvents] = useState({});
  const [loading, setLoading] = useState(false);

  const updateSymbol = async (severity) => {
    try {
      setLoading(true);
      setDialogOpen(false);
      //Update symbol history
      const results = await updateStructureSymbolById({
        id_patient: patId,
        id_symbol: selectedSymbol.id,
        date: nowDate.format(commonDateFormat),
        severity: severity,
      });
      //Refresh symbols
      updateSymbolDate(true, true);
      setLoading(false);
      props.snackbarShowMessage(results.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    setEditSeverity(selectedSymbol.severity);
  }, [selectedSymbol]);

  useEffect(async () => {
    let result;
    if (
      selectedSymbol["professional.name"] &&
      selectedSymbol["professional.surname"]
    ) {
      result["createdBy"] = {
        name: `${selectedSymbol["professional.name"]} ${selectedSymbol["professional.surname"]}`,
        date: moment(selectedSymbol["createdAt"]).format("DD/MM/YYYY"),
        type: "createdBy",
      };
    } else {
      result = await getLoggedEvents({
        record_table_id: selectedSymbol.id,
        element_type: ELEMENT_TYPE.SYMBOL,
      });
    }
    setLoggedEvents(result);
  }, []);

  return (
    <Grid container direction="row">
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={6} container>
        <Grid item xs={12}>
          <SpText variant="text">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.disorder.name
            )}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(
              selectedSymbol.structure_dysfunction?.dysfunction
                ?.dysfunction_category?.name
            )}
          </SpText>
        </Grid>
        {selectedSymbol.structure_dysfunction?.dysfunction?.dysfunction_category
          ?.id_disorder == 1 && (
          <Grid item xs={12}>
            <SpText variant="text">
              {labels.bodymap.dialogDetail.dysfunctionLabel}
            </SpText>
            <SpText variant="h4ComponentLabelRegular">
              {psTranslate(
                selectedSymbol.structure_dysfunction?.dysfunction?.name
              )}
            </SpText>
          </Grid>
        )}

        <Grid item xs={6}>
          <SpText variant="text">
            {labels.patient.graphReport.section.feedback.filters.start_date}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {moment(String(selectedSymbol.start_date)).format("DD/MM/yyyy")}
          </SpText>
        </Grid>
        <Grid item xs={6}>
          <SpText variant="text">
            {labels.patient.graphReport.section.feedback.filters.end_date}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {selectedSymbol?.end_date
              ? moment(String(selectedSymbol?.end_date)).format("DD/MM/yyyy")
              : ""}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.areaLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(selectedSymbol?.["area.name"])}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.regionLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(selectedSymbol?.["region.name"])}
          </SpText>
        </Grid>
        <Grid item xs={12}>
          <SpText variant="text">
            {labels.bodymap.dialogDetail.severityLabel}
          </SpText>
          <SpText variant="h4ComponentLabelRegular">
            {psTranslate(labels.bodymap.severityLabels[editSeverity])}
          </SpText>
        </Grid>

        {editSeverity && editable && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <SpButton
              buttonType={"accept"}
              text={labels.bodymap.deleteButton}
              onClick={() => updateSymbol(0)}
            />
          </Grid>
        )}
      </Grid>
      <Grid item xs={6}>
        <Grid item container direction="column">
          <Grid item xs={6}>
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.patient.monitoring.pathologiesStep.detail.pathologyType}
              </SpText>
              <SpText variant="h4ComponentLabelRegular">
                {psTranslate(selectedSymbol?.["pathology.name"])}
              </SpText>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.patient.presentation.add.stepper.step0.medicalDiagnose}
              </SpText>
              <SpText variant="h4ComponentLabelRegular">
                {psTranslate(selectedSymbol?.["dysfunction.name"])}
              </SpText>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <SpText variant="text">
                {labels.patient.presentation.add.stepper.step0.osiicsCode}
              </SpText>
              <SpText variant="h4ComponentLabelRegular">
                {selectedSymbol?.["pathology.osiics_code"]}
              </SpText>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <SpElementCrudDetails crudDetails={loggedEvents} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withSnackbar(Sp3DModelSymbolDetails);
