import React from "react";
import moment from "moment";
import {
  injuryLocalizationGroups as injuryLocalizationGroupsGeneral,
  selectPatientsInjury as selectPatientsInjuryGeneral,
  selectedGroupsInjury as selectedGroupsInjuryGeneral,
  getAutocomplete as getAutocompleteGeneral,
  createBarschartDataPoints,
} from "./GeneralInjuryFunction";
import {
  getTrainingMinutesFromGroups,
  selectPatientsInjuryRate,
  selectedGroupsInjuryRate,
} from "./TrainingInjuryFunction";
import { getAllCalendarActivityByMonitGroup } from "../../../../models/actions/CalendarActivity";
import { labels } from "../../../shared/translations";
import { matchTrainingArrayUnion } from "./InjuryReportCommonFunction";
import {
  barColors,
  barWithDimension,
  titleCase,
} from "../../../../utils/common";

const gravityRange = labels.analytics.injuryReport.gravityRange;
const injuryRateLabel = labels.analytics.injuryReport.graphs.injuryRate;

export const getAutocomplete = (
  placeholder,
  listItemsSelected,
  listItemAvailable,
  functionAfterClick
) => {
  return getAutocompleteGeneral(
    placeholder,
    listItemsSelected,
    listItemAvailable,
    functionAfterClick
  );
};
export const injuryLocalizationGroups = (injuriesPatient, regions, areas) => {
  return injuryLocalizationGroupsGeneral(injuriesPatient, regions, areas);
};

export const selectPatientsInjury = (selectedInjuryPatient, regions, areas) => {
  const selectedInjuryPatientFiltered = calculateGravity(selectedInjuryPatient);
  return selectPatientsInjuryGeneral(
    selectedInjuryPatientFiltered,
    regions,
    areas
  );
};

export const selectedGroupsInjury = (selectedInjuryPatient, regions, areas) => {
  selectedInjuryPatient.forEach((group) => {
    group.patients = calculateGravity(group.patients);
  });

  return selectedGroupsInjuryGeneral(selectedInjuryPatient, regions, areas);
};
export const calculateGravity = (selectedInjuryPatient) => {
  const severe = gravityRange.find(({ key }) => key === "severe");
  selectedInjuryPatient.forEach((patient) => {
    let newInjuries = [];

    patient?.injuries?.forEach((injury) => {
      if (injury?.end_date) {
        const diffDate = moment(injury?.end_date).diff(
          moment(injury?.start_date),
          "days"
        );
        if (diffDate >= severe.min) newInjuries.push(injury);
      } else if (injury?.estimated_end_date) {
        const diffDate = moment(injury?.estimated_end_date).diff(
          moment(injury?.start_date),
          "days"
        );
        if (diffDate >= severe.min) newInjuries.push(injury);
      }
    });
    patient.injuries = newInjuries;
  });
  return selectedInjuryPatient;
};

export const filterGroupByType = async (groups, dateRange, type) => {
  let temp = [];
  for (let tempGroup of groups) {
    let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
      id_group: tempGroup?.id,
      hide_feedbacks: true,
      start_date: moment(dateRange?.start).format("YYYY-MM-DD"),
      end_date: moment(dateRange?.end).format("YYYY-MM-DD"),
    });
    let item = {};
    item["id"] = tempGroup?.id;
    item["name"] = tempGroup?.name;
    item["patients"] = tempGroup?.patients;

    allCalendarActivity = allCalendarActivity.filter(
      (item) =>
        moment(item?.start_date) > dateRange?.start &&
        moment(item?.start_date) < dateRange?.end
    );
    if (type) {
      item["action"] = allCalendarActivity.filter(
        (all) => all?.activity_datum?.activity_type?.key === type
      );
    } else {
      item["action"] = allCalendarActivity;
    }
    temp.push(item);
  }
  return temp;
};

export const filterActivity = async (mapGroup, newDateRange) => {
  let temp = [];

  for (let tempGroup of mapGroup) {
    let allCalendarActivity = await getAllCalendarActivityByMonitGroup({
      id_group: tempGroup.id,
      hide_feedbacks: true,
    });
    // let activity = getValues("graficoSelezionato");
    let item = {};
    item["id"] = tempGroup.id;
    item["name"] = tempGroup.name;
    item["patients"] = tempGroup.patients;

    allCalendarActivity = allCalendarActivity.filter(
      (item) =>
        moment(item.start_date) > newDateRange.start &&
        moment(item.start_date) < newDateRange.end
    );

    item["action"] = allCalendarActivity;
    temp.push(item);
  }
  return temp;
};

export const selectPatientsSevereInjuryRate = async (
  selectedPatients,
  groups,
  dateRange
) => {
  let resultArray = [];
  let bars = [];
  const barColorValueTemp = barColors();

  selectedPatients = calculateGravity(selectedPatients);
  const activities = await filterActivity(groups, dateRange);
  for (let patient of selectedPatients) {
    let item = {
      hours: 0,
      groupDate: titleCase(`${patient?.givenName} ${patient?.familyName}`),
      injuries: 0,
      [injuryRateLabel]: 0,
    };
    const minutes = getTrainingMinutesFromGroups(activities, patient);
    item.hours = minutes && minutes !== 0 ? minutes / 60 : 0;

    item.injuries = patient?.injuries.length;
    item[injuryRateLabel] =
      item.hours === 0
        ? 1000 * item.injuries
        : ((1000 * item.injuries) / item.hours).toFixed(1);
    resultArray.push(item);
  }
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};

export const selectedGroupsSevereInjuryRate = async (
  newSelectedGroups,
  dateRange
) => {
  const barColorValueTemp = barColors();

  const activities = await filterActivity(newSelectedGroups, dateRange);
  let resultArray = [];
  let bars = [];

  newSelectedGroups.forEach(async (group) => {
    group.patients = calculateGravity(group.patients);
    let patientResultArray = [];

    for (let patient of group.patients) {
      let item = {
        hours: 0,
        groupDate: titleCase(`${patient?.givenName} ${patient?.familyName}`),
        injuries: 0,
      };
      item.hours = getTrainingMinutesFromGroups(activities, patient) / 60;
      item.injuries = patient?.injuries.length;

      item[injuryRateLabel] =
        item.hours === 0
          ? 1000 * item.injuries
          : ((1000 * item.injuries) / item.hours).toFixed(1);
      patientResultArray.push(item);
    }

    let endItem = {
      hours: 0,
      groupDate: titleCase(`${group?.name}`),
      injuries: 0,
    };
    for (let item of patientResultArray) {
      endItem = {
        ...endItem,
        hours: endItem.hours + item.hours,
        injuries: endItem.injuries + item.injuries,
      };
    }
    endItem[injuryRateLabel] =
      endItem.hours === 0
        ? 1000 * endItem.injuries
        : ((1000 * endItem.injuries) / endItem.hours).toFixed(1);
    resultArray.push(endItem);
  });
  bars.push(
    createBarschartDataPoints(
      injuryRateLabel,
      barWithDimension(resultArray.length),
      barColorValueTemp[
        Math.floor(Math.random() * (barColorValueTemp.length + 1)) %
          barColorValueTemp.length
      ]
    )
  );
  return { dataChart: resultArray, barChart: bars };
};
