import { Grid, Divider } from "@material-ui/core";
import React, { useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { SpList } from "../../../components/bundles/SpList";
import { styled } from "../../../components/styled";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const StyledRow = styled("div")({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const A4_TrainingDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
  isPrint = false,
}) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const history = useHistory();
  const { patId, groupId } = useParams();
  //Read only rows
  const RowTraining = ({ row, index }) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "fit-content",
        }}
      >
        {index > 0 && (
          <Divider
            style={{
              padding: "1px",
              width: "100%",
              backgroundColor: isPrint
                ? "white"
                : theme.colors.primary.lightBlue,
            }}
          />
        )}
        <Grid
          item
          xs={12}
          container
          justify="flex-start"
          style={{ marginTop: index > 0 && "1%" }}
        >
          <Grid item xs={4}>
            <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
              {row.activity}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
              {row.time}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText variant={isPrint ? "textPrint" : "h4ComponentLabelCenter"}>
              {row.description}
            </SpText>
          </Grid>
        </Grid>
      </div>
    );
  };

  const data = event.data;
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
        {data?.training_motivation}
      </SpText>
      <Grid direction="column" container style={{ marginBottom: "2%" }}>
        <Grid item xs={12} style={{ justifyContent: "flex-start" }}>
          <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
            {event.data.name}
          </SpText>
        </Grid>
        {data?.training?.length > 0 && (
          <StyledRow>
            <SpText variant="h1PageSubtitle">
              {labels.mylab.tempAdd.training.list.title}
            </SpText>
          </StyledRow>
        )}
        {data?.training?.length > 0 && (
          <Grid
            item
            xs={12}
            container
            style={{ border: `1px solid ${theme.colors.primary.lightBlue}` }}
          >
            <Grid item xs={12}>
              <SpList
                rows={data.training}
                rowKey="id"
                checkable={false}
                deletable={false}
                HeaderRow={
                  <Grid container style={{ justifyContent: "flex-start" }}>
                    {labels.mylab.tempAdd.training.list.header.map((head) => (
                      <Grid key={head} item xs={4}>
                        <SpText
                          variant={isPrint ? "textPrint" : "listHeaderCenter"}
                        >
                          {head}
                        </SpText>
                      </Grid>
                    ))}
                  </Grid>
                }
              >
                <RowTraining
                  headers={labels.mylab.tempAdd.training.list.header}
                />
              </SpList>
            </Grid>
          </Grid>
        )}

        {allowFeedback &&
          showFeedbacksGrid({
            event,
            patId,
            groupId,
            activity: "training",
            showFeedback,
            setShowFeedback,
          })}

        {allowFeedback &&
          showFeedback &&
          (isGroupCard ? (
            <Feedbacks
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ) : (
            <Feedback
              click={handleSubmit(updateActivityFeedback)}
              setValue={setValue}
              event={event}
              register={register}
              isPrint={isPrint}
            />
          ))}
      </Grid>
    </>
  );
};

export default A4_TrainingDisabledDetails;
