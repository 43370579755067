import { Grid } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpTextInput from "../../../components/atoms/SpTextInput";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import SupplementRows from "../../mylab/MyLabAddTemp/Rows/SupplementRows";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const SupplementDisabledDetails = ({
  templateData,

  control,
  setValue,
  register,

  disabled = false,

  handleSubmit,
  updateActivityFeedback,
  event,
  allowFeedback,
  isGroupCard,
  isPrint = false,
}) => {
  const {
    fields: fieldsSupplement,
    append: appendSupplement,
    remove: removeSupplement,
  } = useFieldArray({
    control,
    name: "supplements",
    keyName: "id",
    defaultValues: [],
  });
  const [showFeedback, setShowFeedback] = useState(false);
  const { patId, groupId } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (templateData) {
      setValue("supplement_motivation", templateData.supplement_motivation);
      setValue("quantity_supplement", templateData.supplements.length);
      appendSupplement(templateData.supplements);
    }
  }, [templateData]);

  const fieldTrainingSupplements = useWatch({
    name: "supplements",
    control: control,
  });

  return (
    <Grid
      direction="column"
      container
      style={{ marginBottom: "2%", marginTop: "1%" }}
    >
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start" }}
        direction="row"
      >
        <SpTextInput
          name="supplement_motivation"
          inputRef={register}
          label={
            labels.patient.viewPlan.addActivity.inputForm.assessmentMotivation
          }
          disabled={disabled}
          isPrint={isPrint}
        />
      </Grid>

      {fieldsSupplement.length > 0 && (
        <Grid item xs={12} container style={{ marginTop: "10px" }}>
          <SupplementRows
            rows={fieldTrainingSupplements}
            updateRow={(idx, key, value) => {
              setValue(`supplements[${idx}].${key}`, value);
            }}
          />
        </Grid>
      )}

      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "supplement",
          showFeedback,
          setShowFeedback,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ))}
    </Grid>
  );
};

export default SupplementDisabledDetails;
