import React from "react";
import { Controller } from "react-hook-form";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../styled";
import { labels, psTranslate } from "../../../pages/shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpSelect, SpSelectMenuItem } from "../../atoms/SpSelect";
import { Box, Divider, Grid } from "@material-ui/core";
import SpTextInput from "../../../components/atoms/SpTextInput";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import { theme } from "../../theme";
import moment from "moment";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingTop: "3%",
});

const StyledRowListHeader = styled(Grid)({
  display: "flex",
  flex: "1",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "1rem",
});

const presenceAbsenceArray = [
  {
    value: "0",
    name: "Assenza",
    key: "prensence",
  },
  {
    value: "1",
    name: "Presenza",
    key: "absense",
  },
];

const positiveNegativeArray = [
  {
    value: "1",
    name: "Sì (test positivo)",
    key: "prensence",
  },
  {
    value: "0",
    name: "No (test negativo)",
    key: "absense",
  },
];

const RenderOssQuestionnaires = ({
  currentQuestionnairesList,
  control,
  setValue,
}) => {
  let globalIndex = 0;
  return currentQuestionnairesList.map((questionnairesCategoryItem) => {
    const idy = questionnairesCategoryItem.id;
    return questionnairesCategoryItem.assessment_assessment_categories[0]?.assessment_elements?.map(
      (elementItem, elementItemIdx) => {
        const gridItemQuestSize =
          75 / elementItem?.assessment_element_questions.length;
        const elementQuestions =
          elementItem?.assessment_element_questions?.sort((a, b) =>
            psTranslate(b.assessment_column.name).localeCompare(
              psTranslate(a.assessment_column.name)
            )
          );
        return (
          <Grid
            key={`questionnairesCategoryItem_${idy}${elementItem.id}`}
            container
            direction="column"
            style={{
              paddingTop: elementItemIdx === 0 ? "1%" : "",
              marginTop: "2%",
            }}
            spacing={1}
          >
            {elementItemIdx === 0 && (
              <>
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "2%",
                    backgroundColor: theme.colors.primary.lightBlue,
                  }}
                />

                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  style={{ paddingTop: "1%" }}
                  spacing={1}
                >
                  <Grid
                    item
                    style={{
                      paddingRight: "5px",
                      maxWidth: `25%`,
                      flexBasis: `25%`,
                      paddingLeft: "5px",
                      flexGrow: 0,
                    }}
                  >
                    <SpText variant="h2PageSubtitleColored">
                      {psTranslate(questionnairesCategoryItem.name)}
                    </SpText>
                  </Grid>
                  {elementQuestions?.map((question) => (
                    <Grid
                      item
                      key={`question_${question.id}`}
                      style={{
                        maxWidth: `${gridItemQuestSize}%`,
                        flexBasis: `${gridItemQuestSize}%`,
                        paddingLeft: "5px",
                        flexGrow: 0,
                      }}
                    >
                      <SpText variant="h2PageSubtitleColored">
                        {psTranslate(question?.assessment_column?.name)}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <Grid item container xs={12} alignItems={"center"}>
              <Grid item xs={3} style={{ paddingRight: "5px" }}>
                <SpText variant="h4ComponentLabel">
                  {psTranslate(elementItem?.name)}{" "}
                  {elementItem?.um ? "(" + elementItem?.um + ")" : ""}
                </SpText>
              </Grid>
              {elementQuestions?.map((question) => {
                globalIndex++;
                setValue(
                  `assessmentAnswers[${globalIndex}].id_assessment_element_question`,
                  question.id
                );
                if (question.isMultiple) {
                  return question?.assessment_answer_elements?.map((answer) => (
                    <Grid
                      key={`answer_${answer.id}`}
                      item
                      style={{
                        maxWidth: `${gridItemQuestSize}%`,
                        flexBasis: `${gridItemQuestSize}%`,
                        flexGrow: 0,
                      }}
                    >
                      <Controller
                        label={psTranslate(answer.value)}
                        render={(props) => (
                          <SpCheckbox
                            rightlabel
                            variant="column"
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                            }}
                            checked={props.value}
                          />
                        )}
                        defaultValue={false}
                        name={`assessmentAnswers[${globalIndex}]`}
                        control={control}
                      />
                    </Grid>
                  ));
                } else {
                  return (
                    <Grid
                      key={`questionIdx_${question.id}`}
                      style={{
                        maxWidth: `${gridItemQuestSize}%`,
                        flexBasis: `${gridItemQuestSize}%`,
                        paddingLeft: "5px",
                        flexGrow: 0,
                      }}
                      container
                    >
                      <Grid item xs={12}>
                        <Controller
                          render={(props) =>
                            question?.type !== "number" ? (
                              <SpSelect
                                allowDeselect={true}
                                labelPaddingTop={"0px"}
                                labelMarginBottom={"0px"}
                                formControlStyle={{ width: "100%" }}
                                value={props.value}
                                onChange={(e) => {
                                  props.onChange(e.target.value); //Get the answer
                                }}
                              >
                                {question?.assessment_answer_elements?.map(
                                  (p) => (
                                    <SpSelectMenuItem key={p.value} value={p}>
                                      {psTranslate(p.value)}
                                      <Box
                                        component="span"
                                        color="red"
                                        display="inline"
                                      >
                                        {p.possible_meaning
                                          ? ` (${psTranslate(
                                              p.possible_meaning
                                            )})`
                                          : ""}
                                      </Box>
                                    </SpSelectMenuItem>
                                  )
                                )}
                              </SpSelect>
                            ) : (
                              <SpTextInput
                                type="number"
                                displayLabel={false}
                                style={{
                                  marginBottom: "0px",
                                  width: "100%",
                                }}
                                value={props.value}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                name={"numeric_question_" + question?.id}
                                step={question?.step}
                              />
                            )
                          }
                          defaultValue={""}
                          name={`assessmentAnswers[${globalIndex}].answer`}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "5px" }}>
                        <Controller
                          render={(props) => (
                            <SpTextInput
                              type="text"
                              displayLabel={false}
                              formControlStyle={{ width: "100%" }}
                              placeholder={
                                labels.bundles.assessment.renderUtils
                                  .additionalNotes
                              }
                              value={props.value}
                              onChange={(e) => {
                                props.onChange(e.target.value);
                              }}
                              name={"question_note" + question?.id}
                            />
                          )}
                          defaultValue={""}
                          name={`assessmentAnswers[${globalIndex}].note`}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Grid>
        );
      }
    );
  });
};

const RenderOssQuestionnairesTemplate = ({
  currentQuestionnairesList,
  control,
  setValue,
}) => {
  let globalIndex = 0;
  let categoryList = [];
  setValue("start_date", moment().format("YYYY-MM-DD"));
  return currentQuestionnairesList.map((elementItem, elementItemIdx) => {
    const gridItemQuestSize =
      75 / elementItem?.assessment_element_questions?.length;
    const elementNotExists =
      categoryList.filter(
        (x) =>
          x ===
          elementItem?.assessment_assessment_category?.assessment_category?.name
      ).length <= 0;
    if (elementNotExists) {
      categoryList.push(
        elementItem?.assessment_assessment_category?.assessment_category?.name
      );
    }
    const elementQuestions = elementItem?.assessment_element_questions?.sort(
      (a, b) =>
        psTranslate(b.assessment_column.name).localeCompare(
          psTranslate(a.assessment_column.name)
        )
    );
    return (
      <StyledColumn key={`main_styled_column_${elementItem.id}`}>
        <Grid container direction="column" spacing={1}>
          <Grid
            container
            direction="column"
            style={{ paddingTop: elementItemIdx === 0 ? "1%" : "" }}
          >
            {elementNotExists && (
              <>
                <Divider
                  style={{
                    padding: "1px",
                    marginBottom: "2%",
                    backgroundColor: theme.colors.primary.lightBlue,
                  }}
                />

                <Grid
                  item
                  xs={12}
                  container
                  direction="row"
                  style={{ paddingTop: "1%" }}
                  spacing={1}
                >
                  <Grid
                    item
                    style={{
                      paddingRight: "5px",
                      maxWidth: `25%`,
                      flexBasis: `25%`,
                      paddingLeft: "5px",
                      flexGrow: 0,
                    }}
                  >
                    <SpText variant="h2PageSubtitleColored">
                      {psTranslate(
                        elementItem?.assessment_assessment_category
                          ?.assessment_category?.name
                      )}
                    </SpText>
                  </Grid>

                  <Controller
                    render={(props) => (
                      <SpTextInput
                        name="start_date"
                        label={labels.analytics.account.startDate}
                        type="date"
                        defaultValue={moment().format("YYYY-MM-DD")}
                        maxValue={"2100-12-31"}
                        variant="text"
                        style={{ marginRight: "2px", width: "49%" }}
                        value={props.value}
                        onChange={(e) => {
                          props.onChange(e.target.value);
                        }}
                      />
                    )}
                    defaultValue={false}
                    name="start_date"
                    control={control}
                  />

                  {elementQuestions?.map((question) => (
                    <Grid
                      key={`question_${question.id}`}
                      item
                      style={{
                        maxWidth: `${gridItemQuestSize}%`,
                        flexBasis: `${gridItemQuestSize}%`,
                        paddingLeft: "5px",
                        flexGrow: 0,
                      }}
                    >
                      <SpText variant="h2PageSubtitleColored">
                        {psTranslate(question?.assessment_column?.name)}
                      </SpText>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
            <Grid direction="column">
              <Grid item container xs={12} alignItems={"center"}>
                <Grid item xs={3} style={{ paddingRight: "5px" }}>
                  <SpText variant="h4ComponentLabel">
                    {psTranslate(elementItem?.name)}{" "}
                    {elementItem?.um ? "(" + elementItem?.um + ")" : ""}
                  </SpText>
                </Grid>
                {elementQuestions?.map((question) => {
                  globalIndex++;
                  setValue(
                    `assessmentAnswers[${globalIndex}].id_assessment_element_question`,
                    question.id
                  );
                  let selectionOptionArray = [];
                  if (question.component === "select") {
                    {
                      /* se la domanda è di tipo select, sulla base del tipo di 
                        selection_component_type vengono selezionate le risposete possibili
                      */
                    }
                    switch (question?.selection_component_type) {
                      case "presence-absence":
                        selectionOptionArray = presenceAbsenceArray;
                      case "positive-negative":
                        selectionOptionArray = positiveNegativeArray;
                    }
                  }
                  if (question.isMultiple) {
                    return question?.assessment_answer_elements?.map(
                      (answer) => (
                        <Grid
                          key={`quest_${answer.id}_${question.id}`}
                          item
                          style={{
                            maxWidth: `${gridItemQuestSize}%`,
                            flexBasis: `${gridItemQuestSize}%`,
                            flexGrow: 0,
                          }}
                        >
                          <Controller
                            label={answer.value}
                            render={(props) => (
                              <SpCheckbox
                                rightlabel
                                variant="column"
                                onChange={(e) => {
                                  props.onChange(e.target.checked);
                                }}
                                checked={props.value}
                              />
                            )}
                            defaultValue={false}
                            name={`assessmentAnswers[${globalIndex}]`}
                            control={control}
                          />
                        </Grid>
                      )
                    );
                  } else {
                    return (
                      <Grid
                        key={`quest_${question.id}`}
                        item
                        style={{
                          maxWidth: `${gridItemQuestSize}%`,
                          flexBasis: `${gridItemQuestSize}%`,
                          paddingLeft: "5px",
                          flexGrow: 0,
                        }}
                        container
                      >
                        <Grid item xs={12}>
                          <Controller
                            render={(props) =>
                              question?.component === "select" ? (
                                <SpSelect
                                  allowDeselect={true}
                                  labelPaddingTop={"0px"}
                                  labelMarginBottom={"0px"}
                                  formControlStyle={{ width: "100%" }}
                                  value={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.value); //Get the answer
                                  }}
                                >
                                  {selectionOptionArray?.map((p) => (
                                    <SpSelectMenuItem
                                      key={p.key}
                                      value={p.value}
                                    >
                                      {p.name}
                                    </SpSelectMenuItem>
                                  ))}
                                </SpSelect>
                              ) : question?.type !== "number" ? (
                                <SpSelect
                                  allowDeselect={true}
                                  labelPaddingTop={"0px"}
                                  labelMarginBottom={"0px"}
                                  formControlStyle={{ width: "100%" }}
                                  value={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.value); //Get the answer
                                  }}
                                >
                                  {question?.assessment_answer_elements?.map(
                                    (p) => (
                                      <SpSelectMenuItem key={p.value} value={p}>
                                        {psTranslate(p.value)}
                                        <Box
                                          component="span"
                                          color="red"
                                          display="inline"
                                        >
                                          {p.possible_meaning
                                            ? ` (${psTranslate(
                                                p.possible_meaning
                                              )})`
                                            : ""}
                                        </Box>
                                      </SpSelectMenuItem>
                                    )
                                  )}
                                </SpSelect>
                              ) : (
                                <SpTextInput
                                  type="number"
                                  disabled={elementItem.is_obtained === 1}
                                  displayLabel={false}
                                  style={{
                                    marginBottom: "0px",
                                    width: "100%",
                                  }}
                                  value={props.value}
                                  onChange={(e) => {
                                    props.onChange(e.target.value);
                                  }}
                                  name={"numeric_question_" + question?.id}
                                  step={question?.step}
                                />
                              )
                            }
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].answer`}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "5px" }}>
                          <Controller
                            render={(props) => (
                              <SpTextInput
                                type="text"
                                displayLabel={false}
                                formControlStyle={{ width: "100%" }}
                                placeholder={
                                  labels.bundles.assessment.renderUtils
                                    .additionalNotes
                                }
                                value={props.value}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                name={"question_note" + question?.id}
                              />
                            )}
                            defaultValue={""}
                            name={`assessmentAnswers[${globalIndex}].note`}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledColumn>
    );
  });
};

const handleCategorySelect = (e, props, handleUpdateCategoriesSelected) => {
  props.onChange(e.target.checked);
  handleUpdateCategoriesSelected();
};
const CategoriesCheckboxes = ({
  control,
  assessmentsCategory,
  handleUpdateCategoriesSelected,
}) => (
  <StyledRow>
    <StyledRowListHeader container spacing={1}>
      {assessmentsCategory.map((category) => (
        <Grid
          key={`checkbox_${category.id}`}
          item
          container
          xs={6}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Controller
              style={{ marginTop: "3%" }}
              render={(props) => (
                <SpCheckbox
                  rightlabel
                  variant="column"
                  onChange={(e) =>
                    handleCategorySelect(
                      e,
                      props,
                      handleUpdateCategoriesSelected
                    )
                  }
                  checked={props.value}
                />
              )}
              defaultValue={false}
              name={`category[${[category.id]}]`}
              control={control}
            />
          </Grid>
          <Grid item xs={10}>
            <SpText variant="checkListLabel">
              {psTranslate(category.name)}
            </SpText>
          </Grid>
        </Grid>
      ))}
    </StyledRowListHeader>
  </StyledRow>
);

//Section that renders when we want to add a new assessment out of a template
const AddAssessment = ({
  handleUpdateCategoriesSelected,
  handleSubmit,
  saveAnswers,
  isRepeatAssessment,
  setValue,
  assessmentsCategory,
  setSelectedElement,
  updateQuestionnairesList,
  assessmentsElements,
  assessmentTemplatesList,
  selectedAssessmentElement,
  currentQuestionnairesList,
  selectedCategoriesIds,
  selectedAssessmentTemplate,
  control,
  setSelectedAssessmentTemplate,
  setCurrentQuestionnairesList,
  idTemplate,
}) => {
  if (idTemplate > 0) {
    // set assessmentTemplate if idTemplate is passed from activity plan
    setValue("assessmentTemplate", idTemplate);
  }
  return (
    <form onSubmit={handleSubmit(saveAnswers)}>
      <>
        {!isRepeatAssessment && (
          <Grid container direction={"column"}>
            {!selectedAssessmentElement && (
              <Grid item xs={4}>
                <Controller
                  render={(props) => (
                    <SpSelect
                      allowDeselect={true}
                      label={
                        labels.patient.monitoring.assessments.inputs
                          .selectTemplate
                      }
                      value={props.value}
                      formControlWidth={"100%"}
                      onChange={(e) => {
                        props.onChange(e.target.value);
                        setSelectedAssessmentTemplate(e.target.value);
                        if (e.target.value == -1) {
                          setSelectedAssessmentTemplate(null);
                          setCurrentQuestionnairesList([]);
                        }
                      }}
                    >
                      {assessmentTemplatesList.map((p) => (
                        <SpSelectMenuItem key={p.id} value={p.id}>
                          {p.name}
                        </SpSelectMenuItem>
                      ))}
                    </SpSelect>
                  )}
                  defaultValue={""}
                  name={`assessmentTemplate`}
                  control={control}
                />
              </Grid>
            )}
          </Grid>
        )}
        {selectedAssessmentElement && (
          <CategoriesCheckboxes
            {...{
              control,
              assessmentsCategory,
              handleUpdateCategoriesSelected,
            }}
          />
        )}
        {((selectedCategoriesIds &&
          selectedCategoriesIds.length > 0 &&
          !isRepeatAssessment) ||
          currentQuestionnairesList.length > 0) &&
          !selectedAssessmentTemplate &&
          !isRepeatAssessment && (
            <SpButton
              buttonType={"accept"}
              style={{ marginTop: "4%" }}
              onClick={() => updateQuestionnairesList(selectedCategoriesIds)}
              text={labels.general.update}
            />
          )}
        {selectedAssessmentTemplate && !isRepeatAssessment && (
          <SpButton
            style={{ marginTop: "4%" }}
            buttonType={"accept"}
            onClick={() => {
              updateQuestionnairesList(selectedCategoriesIds);
            }}
            text={labels.general.update}
          />
        )}
        {currentQuestionnairesList.length > 0 && selectedAssessmentTemplate ? (
          <RenderOssQuestionnairesTemplate
            currentQuestionnairesList={currentQuestionnairesList}
            control={control}
            setValue={setValue}
          />
        ) : (
          <RenderOssQuestionnaires
            currentQuestionnairesList={currentQuestionnairesList}
            control={control}
            setValue={setValue}
          />
        )}
        {currentQuestionnairesList.length > 0 && (
          <StyledRow>
            <SpButton
              style={{ marginTop: "4%" }}
              buttonType="accept"
              text={labels.patient.monitoring.pathologiesStep.saveAndClose}
              onClick={handleSubmit(saveAnswers)}
            />
          </StyledRow>
        )}
      </>
    </form>
  );
};

export { AddAssessment };
