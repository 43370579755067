import { Chip, Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { SpAutocomplete } from "../../../components/atoms/SpAutocomplete";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { theme } from "../../../components/theme";
import { labels, psTranslate } from "../../shared/translations";

const StatisticsFeedbacks = ({
  loadConfiguration,
  activeStudyParameters,
  setConfig,
  requestSaveFlag,
  currStudyParameters,
  setCurrStudyParameters,
  studyParameters,
  ...props
}) => {
  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      if (loadConfiguration.currStudyParameters)
        setCurrStudyParameters(loadConfiguration.currStudyParameters);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      setConfig({
        currStudyParameters: currStudyParameters,
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={10}>
        <SpAutocomplete
          label={labels.groups.groupReports.secondTable.chooseParameter}
          multiple
          formControlWidth={"100%"}
          value={currStudyParameters}
          onChange={(_, value) => setCurrStudyParameters(value)}
          options={activeStudyParameters}
          getOptionLabel={(paramId) =>
            psTranslate(
              studyParameters?.find((param) => param.id === paramId)?.name
            ) ?? null
          }
          getOptionSelected={(option, value) => option === value}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option}
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  color: theme.colors.primary.white,
                }}
                label={psTranslate(
                  studyParameters?.find((param) => param.id === option)?.name
                )}
                size="medium"
                {...getTagProps({ index })}
              />
            ))
          }
        />
      </Grid>
      <Grid item xs={2} style={{ marginTop: "auto", marginBotton: "0px" }}>
        <SpButton
          buttonType={"accept"}
          variant="h4ComponentLabel"
          onClick={() => {
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? setCurrStudyParameters([])
              : setCurrStudyParameters(activeStudyParameters);
          }}
          text={
            currStudyParameters.length === activeStudyParameters.length &&
            activeStudyParameters.length !== 0
              ? labels.groups.plan.deselectAll
              : labels.groups.plan.selectAll
          }
        />
      </Grid>
    </Grid>
  );
};

export default withSnackbar(StatisticsFeedbacks);
