import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import SpLoadingButton from "../atoms/SpLoadingButton";
import { labels } from "../../pages/shared/translations";
import { isRoutePermitted } from "./PermissionsRoutes";
import { getUserPermission } from "../../utils/common";
import { NextDirection } from "./PrivateRoutesFunction";
import { ampli } from "../../ampli";

export const setAxiosTokenInterceptor = async (accessToken, idToken) => {
  axios.interceptors.request.use(async (config) => {
    const requestConfig = config;
    if (accessToken && idToken) {
      (requestConfig.headers.common.Authorization = `Bearer ${accessToken}`),
        (requestConfig.headers.common["Id-Token"] = idToken);
    }
    return requestConfig;
  });
};

const PrivateRouteAuth0 = ({ children, ...rest }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
    user,
  } = useAuth0();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const [userTokens, setUserTokens] = useState(null);
  const [permission, setPermission] = useState(true);
  const [isFederation, setIsFederation] = useState(false);

  const getLoadingButton = () => {
    return (
      <SpLoadingButton
        loading={isLoading}
        style={{ marginTop: "3%", marginRight: "0 !important" }}
        text={labels.login.buttons.login}
        buttonType={"accept"}
        variant="none"
        type="submit"
      />
    );
  };

  useEffect(async () => {
    const isRoutePermittedRes = await isRoutePermitted(
      rest?.componentName,
      rest?.location?.pathname,
      getAccessTokenSilently
    );
    setPermission(isRoutePermittedRes);

    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    setIsFederation(permissionFederationGroups);
  }, []);

  useEffect(async () => {
    if (!isLoading && isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      const idToken = await getIdTokenClaims();
      setUserTokens({ accessToken, idToken: idToken?.__raw });
    }
  }, [isLoading, isAuthenticated]);

  useEffect(async () => {
    if (userTokens) {
      await setAxiosTokenInterceptor(
        userTokens.accessToken,
        userTokens.idToken
      );
      setIsTokenSet(true);
    }
  }, [userTokens]);
  if (!isLoading) {
    if (user) {
      const userStorage = localStorage.getItem("user");
      if (!userStorage) localStorage.setItem("user", user);
      ampli.identify(user.name);
    }

    return (
      <NextDirection
        isAuthenticated={isAuthenticated}
        permission={permission}
        isTokenSet={isTokenSet}
        isFederation={isFederation}
        pathname={rest?.location?.pathname}
        getLoadingButton={getLoadingButton}
        rest={rest}
        children={children}
      />
    );
  }
  return getLoadingButton();
};
export default PrivateRouteAuth0;
