import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { ArrowBack } from "@material-ui/icons";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import CommonRows from "./Rows/CommonRows";
import CommonDetails from "./Details/CommonDetails";
import ExerciseSheetRows from "./Rows/ExerciseSheetRows";
import ExerciseSheetDetails from "./Details/ExerciseSheetDetails";
import { theme } from "../../../components/theme";
import { Controller, useForm } from "react-hook-form";
import { getReferenceButton } from "./AddFunctionHelper";
import SpTextArea from "../../../components/atoms/SpTextArea";
import printContext from "../../../utils/printContext";
import { getAllActivitiesTypes } from "../../../models/actions/Activity";
import { useAuth0 } from "@auth0/auth0-react";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const AddCommon = ({
  isClone,
  sectionName,
  titleLabel,
  templateRowsKey,
  templateIdKey,
  getTemplateFn,
  createTemplateFn,
  updateTemplateFn,
  ...props
}) => {
  const [templateDetail, setTemplateDetail] = useState({
    name: "",
    quantity: 0,
    template_reference: "",
    note: "",
    [templateRowsKey]: [],
  });
  const { register, getValues, setValue } = useForm({
    shouldUnregister: false,
  });
  const history = useHistory();

  const { idElement } = useParams();
  const readOnly = idElement && !isClone;

  const CurrentRows =
    sectionName === "excercise" ? ExerciseSheetRows : CommonRows;
  const CurrentDetails =
    sectionName === "excercise" ? ExerciseSheetDetails : CommonDetails;

  //PDF export section
  const [isPrint, setIsPrint] = useState(false);
  const [showReferenceButton, setShowReferenceButton] = useState(false);
  const [subactivities, setSubactivities] = useState([]);
  const reference = createRef(null);

  useEffect(() => {
    const currLen = templateDetail[templateRowsKey].length;
    const targetLen = parseInt(templateDetail.quantity);

    //Adjust array length to match quantity
    if (currLen !== targetLen) {
      if (targetLen > 20) {
        setTemplateDetail({ ...templateDetail, quantity: 20 });
      } else if (targetLen < 1) {
        setTemplateDetail({ ...templateDetail, quantity: 1 });
      } else {
        const newDetails = { ...templateDetail };
        for (let i = currLen; i < targetLen; i++)
          newDetails[templateRowsKey].push({});
        for (let i = currLen - 1; i >= targetLen; i--)
          newDetails[templateRowsKey].pop();
        setTemplateDetail(newDetails);
      }
    }
  }, [templateDetail]);
  const { getAccessTokenSilently } = useAuth0();

  const fetchData = async () => {
    const activityTypeList = await getAllActivitiesTypes(
      getAccessTokenSilently
    );
    if (sectionName == "treatment") {
      const subAct = activityTypeList.find(
        ({ key }) => key === sectionName
      )?.subactivities;
      setSubactivities(subAct);
    }
    if (sectionName == "rehab") {
      const subAct = activityTypeList.find(
        ({ key }) => key === "rehabilitation"
      )?.subactivities;
      setSubactivities(subAct);
    }

    if (idElement) {
      try {
        const result = await getTemplateFn({ id_template: idElement });
        if (result) {
          setValue("template_reference", result.reference);
          if (isClone) result[templateRowsKey].forEach((row) => delete row.id);
          //Fix for exercise/training different names
          if (sectionName === "excercise")
            result.quantity = result.excercise_quantity;
          if (sectionName === "training") {
            result.quantity = result.number;
            result[templateRowsKey].forEach((row) => (row.duration = row.time));
          }
          setTemplateDetail(result);
        }
      } catch (error) {
        props.snackbarShowErrorMessage(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const adjustNames = () => {
    //Fix for exercise/training different db names
    const fixedTemplateDetails = { ...templateDetail };
    fixedTemplateDetails.template_reference = getValues("template_reference");
    if (sectionName === "excercise")
      fixedTemplateDetails.excercise_quantity = templateDetail.quantity;
    if (sectionName === "training") {
      fixedTemplateDetails.number = templateDetail.quantity;
      fixedTemplateDetails[templateRowsKey].forEach(
        (row) => (row.time = row.duration)
      );
    }
    return fixedTemplateDetails;
  };

  const editTemplate = async () => {
    try {
      const fixedTemplateDetails = adjustNames();
      //Update template
      const results = await updateTemplateFn({
        ...fixedTemplateDetails,
        [templateIdKey]: idElement,
      });
      if (results.error) {
        props.snackbarShowMessage(results.error);
      } else {
        props.snackbarShowMessage(results.message);
        history.goBack();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const saveTemplate = async () => {
    if (!templateDetail.name) {
      props.snackbarShowErrorMessage(labels.mylab.insertTemplateName);
      return;
    }
    try {
      const fixedTemplateDetails = adjustNames();
      //Create template
      const result = await createTemplateFn({ ...fixedTemplateDetails });
      if (result.message) {
        props.snackbarShowMessage(result.message);
        history.goBack();
      } else {
        props.snackbarShowMessage(result.error);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const hasRows = templateDetail[templateRowsKey]?.length > 0;
  return (
    <>
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item xs={8}>
            <TitleToolbarSection>
              <ArrowBack
                onClick={() => history.goBack()}
                style={{
                  width: 30,
                  color: theme.colors.primary.lightBlue,
                  height: 50,
                  cursor: "pointer",
                }}
              />
              <SpText variant="h1">
                {labels.mylab.tempDetail.backButtonList + " / "}{" "}
                <span style={{ color: theme.colors.primary.white }}>
                  {titleLabel}
                </span>
              </SpText>
            </TitleToolbarSection>
          </Grid>
          {/*Top create button*/}
          {hasRows && (
            <Grid
              item
              xs={4}
              alignItems={"center"}
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <SpButton
                variant="none"
                style={{ marginLeft: "2%" }}
                text={
                  readOnly
                    ? labels.mylab.tempAdd.treatment.actions.rename
                    : labels.mylab.tempAdd.treatment.actions.create
                }
                buttonType="accept"
                onClick={() => (readOnly ? editTemplate() : saveTemplate())}
              />
            </Grid>
          )}
        </Grid>

        <printContext.Provider value={{ isPrint, setIsPrint }}>
          <div ref={reference}>
            {/*Name & quantity*/}
            <Grid
              item
              xs={12}
              container
              style={{ justifyContent: "flex-start", padding: "8px" }}
            >
              <Grid item xs={6} style={{ padding: "8px", paddingTop: "1.5em" }}>
                <SpTextInput
                  style={{ width: "100%" }}
                  label={labels.mylab.tempAdd.treatment.inputs.name}
                  value={templateDetail.name}
                  onChange={(evnt) =>
                    setTemplateDetail({
                      ...templateDetail,
                      name: evnt.target.value,
                    })
                  }
                />
              </Grid>

              {getReferenceButton({
                showReferenceButton,
                setShowReferenceButton,
                register,
                getValues,
              })}

              <Grid item xs={6} style={{ padding: "8px" }}>
                <SpTextArea
                  name="template_note"
                  id="template_note"
                  value={templateDetail.note}
                  label={psTranslate(
                    labels.patient.monitoring.pathologiesStep.note
                  )}
                  onChange={(evnt) =>
                    setTemplateDetail({
                      ...templateDetail,
                      note: evnt.target.value,
                    })
                  }
                />
              </Grid>

              <Grid item xs={6} style={{ padding: "8px" }}>
                {readOnly ? (
                  <Grid
                    direction="row"
                    container
                    style={{ paddingTop: "1.2em" }}
                  >
                    <SpText
                      variant="h4ComponentLabelColored"
                      style={{
                        border: `1px solid ${theme.colors.primary.lightBlue}`,
                        padding: "8px",
                      }}
                    >
                      {labels.mylab.tempAdd.training.inputs.quantity}
                      <Box
                        color={
                          isPrint
                            ? theme.colors.primary.black
                            : theme.colors.primary.white
                        }
                      >
                        {templateDetail?.quantity}
                      </Box>
                    </SpText>
                  </Grid>
                ) : (
                  <SpTextInput
                    style={{ maxWidth: "40%" }}
                    type="number"
                    minValue={0}
                    label={labels.mylab.tempAdd.treatment.inputs.quantity}
                    value={templateDetail.quantity}
                    onChange={(evnt) =>
                      setTemplateDetail({
                        ...templateDetail,
                        quantity: evnt.target.value,
                      })
                    }
                  />
                )}
              </Grid>
            </Grid>

            {/*Data rows*/}
            {hasRows &&
              (readOnly ? (
                <CurrentDetails
                  rows={templateDetail[templateRowsKey]}
                  isPrint={isPrint}
                />
              ) : (
                <CurrentRows
                  sectionName={sectionName}
                  subactivities={subactivities}
                  rows={templateDetail[templateRowsKey]}
                  updateRow={(idx, key, value) => {
                    templateDetail[templateRowsKey][idx][key] = value;
                    setTemplateDetail({ ...templateDetail });
                  }}
                  isPrint={isPrint}
                />
              ))}
          </div>
        </printContext.Provider>

        {/*Bottom create button*/}
        {hasRows && !readOnly && (
          <Grid
            item
            xs={12}
            spacing={0}
            style={{ paddingLeft: "0" }}
            container
            justify={"flex-end"}
          >
            <SpButton
              text={labels.mylab.tempAdd.treatment.actions.create}
              buttonType="accept"
              variant="h1"
              onClick={() => saveTemplate()}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default withSnackbar(AddCommon);
