import React, { useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Importa il plugin per le etichette dei dati
import { getLabelFromPatientStatus, titleCase } from "../utils/common";
import { labels, psTranslate } from "./shared/translations";
import moment from "moment";
// Registra i componenti di Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataLabels
);
import { Divider, Grid } from "@material-ui/core";

const ProfessionalReportGenerator = ({
  reportData,
  handleClick,
  setHandleClick,
  setLoading,
}) => {
  useEffect(() => {
    if (handleClick) {
      generatePDF();
      setHandleClick(false);
    }
  }, [handleClick]);

  const generatePDF = () => {
    setLoading(true);
    const doc = new jsPDF();

    // Titolo del report
    doc.setFontSize(18);

    // Grafico delle attività
    const activityDurationProfessional = document.getElementById(
      "activityDurationProfessional"
    );
    if (activityDurationProfessional) {
      const activityImage = activityDurationProfessional.toDataURL("image/png");
      doc.text(labels.analytics.account.activityFreq, 10, 10);
      doc.addImage(activityImage, "PNG", 10, 20, 180, 90);
    }

    // Salvare il PDF
    setLoading(false);
    doc.save(`reportProfessional${moment().format("DD/MM/YYYY")}.pdf`);
  };

  // Colori per ogni tipo di attività
  const activityColors = {
    [psTranslate("Valutazione")]: "rgba(75, 192, 192, 0.6)",
    [psTranslate("Trattamento")]: "rgba(255, 99, 132, 0.6)",
    [psTranslate("Esercizio")]: "rgba(54, 162, 235, 0.6)",
    [psTranslate("Allenamento")]: "rgba(255, 206, 86, 0.6)",
    [psTranslate("Riposo")]: "rgba(153, 102, 255, 0.6)",
    [psTranslate("Altro")]: "rgba(255, 159, 64, 0.6)",
    [psTranslate("Gara")]: "rgba(100, 100, 100, 0.6)",
    [psTranslate("Riabilitazione")]: "rgba(200, 99, 132, 0.6)",
    [psTranslate("Supplemento")]: "rgba(75, 192, 192, 0.3)",
  };

  const darkenColor = (rgba, factor = 0.8) => {
    // Estrae i valori R, G, B e A dal colore
    const match = rgba.match(/rgba?\((\d+), (\d+), (\d+), ([\d.]+)\)/);
    if (!match) return rgba; // Se il colore non è in formato RGBA, ritorna il colore originale

    const [_, r, g, b, a] = match;
    return `rgba(${Math.floor(r * factor)}, ${Math.floor(
      g * factor
    )}, ${Math.floor(b * factor)}, ${a})`;
  };

  // Raggruppa i dati per creare dataset del grafico
  const generateChartData = () => {
    const athleteNames = reportData.map(
      (professional, index) =>
        titleCase(`${professional.name} ${professional.surname}`) ||
        `Atleta ${index + 1}`
    );

    const activityTypes = Object.keys(activityColors).map((item) =>
      psTranslate(item)
    );

    // Creare un dataset per ogni attività
    const datasets = activityTypes.map((activityType) => {
      const darkenedColor = "rgb(255, 255, 255)";

      return {
        label: activityType,
        data: reportData.map((professional) => {
          const activity = Object.keys(professional.activities).find(
            (a) => psTranslate(a) === activityType
          );
          return activity ? professional.activities[activity] : 0;
        }),
        backgroundColor: activityColors[activityType],
        datalabels: {
          color: darkenedColor,
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: (value, context) => value,
        },
      };
    });

    return {
      labels: athleteNames,
      datasets,
    };
  };

  const chartData = generateChartData();

  const options = {
    indexAxis: "y", // Inverti l'asse da verticale a orizzontale
    plugins: {
      legend: { position: "top" },
      tooltip: { mode: "index", intersect: false },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: labels.patient.graphReport.filtersButton.activity,
        },
      },
      y: { stacked: true },
    },
  };

  return (
    <div>
      <h2>{labels.account.accountReports.activitiesProfessional}</h2>
      <Bar
        data={chartData}
        options={options}
        id="activityDurationProfessional"
      />
    </div>
  );
};

export default ProfessionalReportGenerator;
