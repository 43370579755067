import { call } from "./utils";

export const getOrganization = async (data) =>
  await call({ url: "/get/organization", data: { data } });

export const updateOrganization = async (data) =>
  await call({ url: "/update/organization", data: { data } });

export const isProfessionalInOrganizzation = async (data) =>
  await call({ url: "/professional/in/organization", data: { data } });

export const updateProfessionalSchedule = async (data) =>
  await call({ url: "/update/professional/schedule", data: { data } });

export const getProfessionalSchedule = async (data) =>
  await call({ url: "/get/professional/schedule", data: { data } });

export const updateOrganizationSchedule = async (data) =>
  await call({ url: "/update/organization/schedule", data: { data } });

export const getOrganizationSchedule = async (data) =>
  await call({ url: "/get/organization/schedule", data: { data } });

export const getBookingsList = async (data) =>
  await call({ url: "/get/bookings/list", data: { data } });

export const updateBooking = async (data) =>
  await call({ url: "/update/booking", data: { data } });
