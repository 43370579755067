import React, { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Patients from "./pages/Patients";
import LoginAuth0 from "./pages/LoginAuth0";
// REMINDER in caso serva il login vecchio
import LoginUsernamePsw from "./pages/LoginUsernamePsw";
import Layout from "./pages/shared/layout/Layout";
import LayoutBase from "./pages/shared/layout/LayoutBase";
import Groups from "./pages/Groups";
import GroupsDetail from "./pages/groups/GroupsDetail";
import GroupsAdd from "./pages/groups/GroupsAdd";
import Agenda from "./pages/Agenda";
import Library from "./pages/Library";
import Account from "./pages/Account";
import AccountReports from "./pages/AccountReports";
import Faq from "./pages/Faq";
import PatientsDetail from "./pages/patients/PatientsDetail";
import PatientsLink from "./pages/patients/PatientsLink";
import PatientsAdd from "./pages/patients/PatientsAdd";
import PatientsRegistry from "./pages/patients/PatientsRegistry";
import PatientsPastHistory from "./pages/patients/PatientsPastHistory";
import PatientsMonitoring from "./pages/patients/PatientsMonitoring";
import PatientsAlerts from "./pages/patients/patientAlerts/PatientAlerts";
import PatientsPresentations from "./pages/patients/PatientsPresentations";
import PatientsPlan from "./pages/patients/PatientsPlan";
import PatientsControlRoomInternalMenu from "./pages/patients/PatientsControlRoomInternalMenu";
import PatientsMedicalResourceInternalMenu from "./pages/patients/PatientsMedicalResourceInternalMenu";
import PatientsStatistics from "./pages/patients/PatientsStatistics";
import PatientsReports from "./pages/patients/PatientsReports";
import PatientsTransferPatient from "./pages/patients/PatientsTransferPatient";
import PatientsSettingsInternalMenu from "./pages/patients/PatientsSettingsInternalMenu";
import PatientsPresentationAdd from "./pages/patients/PatientsPresentationAdd";
import PatientsPresentationDetail from "./pages/patients/PatientsPresentationDetail";
import MyLabTemp from "./pages/mylab/MyLabTemp";
import MyLabAddTemp from "./pages/mylab/MyLabAddTemp";
import MyLabGpsDevicesAccount from "./pages/mylab/MyLabGpsDevices/MyLabGpsDevicesAccount";
import MyLabGpsDevicesPatient from "./pages/mylab/MyLabGpsDevices/MyLabGpsDevicesPatient";
import AddExerciseCategory from "./pages/mylab/MyLabAddTemp/AddExerciseCategory";
import MyLab from "./pages/MyLab";
import Proms from "./pages/Proms";
import Agency from "./pages/Agency";
import AgencyLicenses from "./pages/agency/AgencyLicenses";
import AgencyAccount from "./pages/agency/AgencyAccount";
import LayoutAgency from "./pages/shared/layout/LayoutAgency";
import "core-js/stable";
import "regenerator-runtime/runtime";
import PatientsMonitoringAddProms from "./pages/patients/patientsMonitoring/PatientsMonitoringAddProms";
import PatientsPresentationAddProms from "./pages/patients/patientsPresentationDetail/PatientsPresentationAddProms";
import PrivateRoute from "./components/routes/PrivateRoute";
import GroupReports from "./pages/groups/GroupReports";
import GroupActivityPlan from "./pages/groups/GroupActivityPlan";
import GroupAnalytics from "./pages/groups/GroupAnalytics";
import NotFound from "./pages/NotFound";
import Error from "./pages/Error";

import LicenceExpired from "./pages/LicenceExpired";
import ErrorBoundaryClass from "./ErrorBoundaryClass";
import SituationTable from "./pages/groups/situation/SituationTable";
import ReadinessTable from "./pages/groups/readiness/ReadinessTable";
import Disponibility from "./pages/groups/disponibility/Disponibility";
import PatientsGroup from "./pages/groups/PatientsGroup";
import ProfessionalsGroup from "./pages/groups/ProfessionalsGroup";
import PatientsMedicalNotes from "./pages/patients/PatientsMedicalNotes";
import GroupProms from "./pages/groups/GroupProms";
import PatientsStatisticsAnalytics from "./pages/patients/patientsStatisticsAnalytics/PatientsStatisticsAnalyticsDashboard";
import PatientsPresentationResources from "./pages/patients/patientsPresentationDetail/PatientsPresentationResources";
import PatientsInjuryIllness from "./pages/patients/PatientsInjuryIllness";
import GenericRiskFactor from "./pages/mylab/GenericRiskFactor/genericRiskFactor";
import FederationGroups from "./pages/groups/FederationGroups";
import ManageUsers from "./pages/manageUsers/ManageUsers";
import FederationProfessionalsTeams from "./pages/groups/FederationProfessionalsTeams";
import FederationInjuries from "./pages/groups/FederationInjuries";
import PatientsResourcesMedical from "./pages/patients/PatientsResourcesMedical";
import PatientsResourcesPhysio from "./pages/patients/PatientsResourcesPhysio";
import { ampli } from "./ampli";
import { TempChart } from "./pages/patients/patientsStatisticsAnalytics/tempChart";
import { ContRoom } from "./pages/groups/GroupsControlRoom";
import PaymentSuccesfull from "./pages/payment/PaymentSuccesfull";
import { PaymentFailed } from "./pages/payment/PaymentFailed";
import DashboardSituation from "./pages/groups/situation/DashboardSituation";
import PricingPage from "./pages/payment/PricingTable";
import PaymentPage from "./pages/payment/PaymentPage";
import { CssBaseline } from "@material-ui/core";
import Organization from "./pages/mylab/Organization/Organization";
import OrganizationDetails from "./pages/mylab/Organization/OrganizationDetails";
import BookingRequests from "./pages/mylab/Organization/BookingRequests";
import RequestsList from "./pages/mylab/Organization/RequestsList";
import RequestsListOrganization from "./pages/mylab/Organization/RequestsListOrganization";
import GroupMonitoring from "./pages/groups/GroupMonitoring";
import PatientStatusInternalMenu from "./pages/patients/PatientStatusInternalMenu";
import { withLDProvider } from "launchdarkly-react-client-sdk";

const App = (/*props*/) => {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/registration" componentName="Registration">
          <ErrorBoundaryClass>
            <LayoutBase>
              <Registration />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route> */}
        <Route exact path="/login-auth0">
          <ErrorBoundaryClass componentName="LoginAuth0">
            <LayoutBase>
              <LoginAuth0 />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <Route exact path="/registration">
          <ErrorBoundaryClass componentName="Registration">
            <LayoutBase>
              <PricingPage />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <Route exact path="/paymentpage">
          <ErrorBoundaryClass componentName="Registration">
            <LayoutBase>
              <CssBaseline />
              <PaymentPage />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <Route path="/paymeny-successfull/:email">
          <ErrorBoundaryClass componentName="PaymentSuccesfull">
            <LayoutBase>
              <PaymentSuccesfull />
            </LayoutBase>
          </ErrorBoundaryClass>
        </Route>
        <Route path="/paymeny-failed" component={PaymentFailed} />
        <PrivateRoute exact path="/" componentName="Dashboard">
          <ErrorBoundaryClass>
            <Layout>
              <Dashboard />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/not-found" componentName="NotFound">
          <ErrorBoundaryClass>
            <Layout>
              <NotFound />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <Route
          exact
          path="/error"
          render={(props) => {
            ampli.changeComponent({ component: "Error" });
            return (
              <Layout>
                <Error {...props} />
              </Layout>
            );
          }}
        />
        <PrivateRoute
          exact
          path="/licence-expired"
          componentName="LicenceExpired"
        >
          <ErrorBoundaryClass>
            <LayoutBase>
              <LicenceExpired />
            </LayoutBase>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients" componentName="Patients">
          <ErrorBoundaryClass>
            <Layout>
              <Patients />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>

        <PrivateRoute exact path="/proms" componentName="Proms">
          <ErrorBoundaryClass>
            <Layout>
              <Proms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>

        <PrivateRoute
          exact
          path="/patients/edit/:patId"
          componentName="PatientsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientStatusInternalMenu />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/control-room"
          componentName="PatientsChart"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsControlRoomInternalMenu />
                /* <TempChart />*/
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/edit/:groupId/control-room"
          componentName="GoupsChart"
        >
          <ErrorBoundaryClass>
            <Layout>
              <ContRoom />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/medical-notes"
          componentName="PatientsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsMedicalNotes />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/registry"
          componentName="PatientsRegistry"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsRegistry />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/past-history"
          componentName="PatientsPastHistory"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPastHistory />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/monitoring"
          componentName="PatientsMonitoring"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsMonitoring />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/monitoring/:groupId"
          componentName="GroupMonitoring"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupMonitoring />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/alerts"
          componentName="PatientsAlerts"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsControlRoomInternalMenu />
                /* <PatientsAlerts /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/monitoring/add_proms"
          componentName="PatientsMonitoringAddProms"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsMonitoringAddProms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentation/:presentationId/add_proms"
          componentName="PatientsPresentationAddProms"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationAddProms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/patientsInjuryIllness"
          componentName="PatientsInjuryIllness"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsInjuryIllness />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentations"
          componentName="PatientsPresentations"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentations />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentation/:presentationId"
          componentName="PatientsPresentationDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentation/resources"
          componentName="PatientsPresentationResources"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationResources />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/presentations/add"
          componentName="PatientsPresentationAdd"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPresentationAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/plan"
          componentName="PatientsPlan"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsPlan />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/statistics"
          componentName="PatientsStatistics"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsControlRoomInternalMenu />
                /* <PatientsStatistics /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/statistics/:date/:activity"
          componentName="PatientsStatistics"
        >
          <ErrorBoundaryClass>
            <Layout>
              <PatientsStatistics />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/statistics-anlytics"
          componentName="PatientsStatisticsAndAnalytics"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsControlRoomInternalMenu />
                /* <PatientsStatisticsAnalytics /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/edit/:groupId/statistics-anlytics"
          componentName="PatientsStatisticsAndAnalytics"
        >
          <ErrorBoundaryClass>
            <Layout>{<PatientsStatisticsAnalytics />}</Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/report"
          componentName="PatientsReports"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsControlRoomInternalMenu />
                /* <PatientsReports /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/proms/:groupId"
          componentName="GroupProms"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupProms />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/medical-resource"
          componentName="PatientsResourcesMedical"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsMedicalResourceInternalMenu />
                /* <PatientsResourcesMedical /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/physio-resource"
          componentName="PatientsResourcesPhysio"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsMedicalResourceInternalMenu />
                /* <PatientsResourcesPhysio /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/patients/edit/:patId/settings"
          componentName="PatientsTransferPatient"
        >
          <ErrorBoundaryClass>
            <Layout>
              {
                <PatientsSettingsInternalMenu />
                /* <PatientsTransferPatient /> */
              }
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients/create" componentName="PatientsAdd">
          <ErrorBoundaryClass>
            <Layout>
              <PatientsAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/patients/link" componentName="PatientsLink">
          <ErrorBoundaryClass>
            <Layout>
              <PatientsLink />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/federations" componentName="Federations">
          <ErrorBoundaryClass>
            <Layout>
              <Groups />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/groups" componentName="Groups">
          <ErrorBoundaryClass>
            <Layout>
              <Groups />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/federations/details/:groupId"
          componentName="GroupsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupsDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/details/:groupId"
          componentName="GroupsDetail"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupsDetail />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/groups/add" componentName="GroupsAdd">
          <ErrorBoundaryClass>
            <Layout>
              <GroupsAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/federations/add"
          componentName="FederationsAdd"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupsAdd />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/analytics/:groupId"
          componentName="GroupAnalytics"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupAnalytics />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/federations/teams/:groupId"
          componentName="FederationGroups"
        >
          <ErrorBoundaryClass>
            <Layout>
              <FederationGroups />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/federations/professionals/teams/:groupId"
          componentName="FederationGroups"
        >
          <ErrorBoundaryClass>
            <Layout>
              <FederationProfessionalsTeams />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/federations/injuries/:groupId"
          componentName="FederationInjuries"
        >
          <ErrorBoundaryClass>
            <Layout>
              <FederationInjuries />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/statistics/:groupId"
          componentName="GroupReports"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/statistics/:groupId/:date/:activity"
          componentName="GroupReports"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/manageUsers" componentName="ManageUsers">
          <ErrorBoundaryClass>
            <Layout>
              <ManageUsers />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/situation/:groupId"
          componentName="GroupSituation"
        >
          <ErrorBoundaryClass>
            <Layout>
              <DashboardSituation />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/readiness/:groupId"
          componentName="ReadinessTable"
        >
          <ErrorBoundaryClass>
            <Layout>
              <ReadinessTable />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/availability/:groupId"
          componentName="GroupAvailability"
        >
          <ErrorBoundaryClass>
            <Layout>
              <Disponibility />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/groups/activity/:groupId"
          componentName="GroupActivityPlan"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GroupActivityPlan />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/agenda" componentName="Agenda">
          <ErrorBoundaryClass>
            <Layout>
              <Agenda />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/library" componentName="Library">
          <ErrorBoundaryClass>
            <Layout>
              <Library />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/faq" componentName="Faq">
          <ErrorBoundaryClass>
            <Layout>
              <Faq />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/account" componentName="Account">
          <ErrorBoundaryClass>
            <Layout>
              <Account />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/reports" componentName="AccountReports">
          <ErrorBoundaryClass>
            <Layout>
              <AccountReports />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/mylab" componentName="MyLab">
          <ErrorBoundaryClass>
            <Layout>
              <MyLab />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName"
          componentName="MyLabTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabTemp />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/add"
          componentName="MyLabAddTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabAddTemp />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/gpsDevices/account"
          componentName="MyLabGpsDevicesAccount"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabGpsDevicesAccount />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/organization" componentName="Organization">
          <ErrorBoundaryClass>
            <Layout>
              <Organization />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/schedule/manager"
          componentName="OrganizationDetails"
        >
          <ErrorBoundaryClass>
            <Layout>
              <OrganizationDetails />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/requests/list"
          componentName="OrganizationDetails"
        >
          <ErrorBoundaryClass>
            <Layout>
              <RequestsList />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/requests/list/organizer"
          componentName="OrganizationDetails"
        >
          <ErrorBoundaryClass>
            <Layout>
              <RequestsListOrganization />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/booking/requests"
          componentName="RequestList"
        >
          <ErrorBoundaryClass>
            <Layout>
              <BookingRequests />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/genericRiskFactor/list"
          componentName="GenricRiskFactor"
        >
          <ErrorBoundaryClass>
            <Layout>
              <GenericRiskFactor />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/gpsDevices/view"
          componentName="MyLabGpsDevicesPatient"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabGpsDevicesPatient />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/add-category"
          componentName="MyLabAddCategory"
        >
          <ErrorBoundaryClass>
            <Layout>
              <AddExerciseCategory />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/add/:idElement"
          componentName="MyLabAddTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabAddTemp isClone={false} />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/mylab/:sectionName/clone/:idElement"
          componentName="MyLabAddTemp"
        >
          <ErrorBoundaryClass>
            <Layout>
              <MyLabAddTemp isClone={true} />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute exact path="/agency" componentName="Agency">
          <ErrorBoundaryClass>
            <Layout>
              <Agency />
            </Layout>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/agency/licenses"
          componentName="AgencyLicenses"
        >
          <ErrorBoundaryClass>
            <LayoutAgency>
              <AgencyLicenses />
            </LayoutAgency>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/agency/account"
          componentName="AgencyAccount"
        >
          <ErrorBoundaryClass>
            <LayoutAgency>
              <AgencyAccount />
            </LayoutAgency>
          </ErrorBoundaryClass>
        </PrivateRoute>
        <PrivateRoute path="*" componentName="Redirect">
          <ErrorBoundaryClass>
            <LayoutBase>
              <LoginAuth0 />
            </LayoutBase>
          </ErrorBoundaryClass>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY, // Usa l'ID dell'ambiente
})(App);
