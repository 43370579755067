import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import Sp3DModel from "../../components/atoms/Sp3DModel";
import SpText from "../../components/atoms/SpText";
import Avatar from "@material-ui/core/Avatar";
import { CloudUpload as MuiCloudUpload } from "@material-ui/icons";
import PatientsEditSidebar from "../patients/shared/PatientsEditSidebar";
import moment from "moment";
import {
  findPatientByPk,
  getUserProfileImage,
  setUserProfileImage,
} from "../../models/actions/Patients";
import { getAreasByRegion, getRegions } from "../../models/actions/Pathologies";
import { Button as MuiButton, Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { getResourceFromServer } from "../../models/actions/Professionals";
import { spacing } from "@material-ui/system";
import { uploadFile } from "../../models/actions/awsUtils";
import {
  getColorFromPatientStatus,
  getLabelFromPatientStatus,
  getUserPermission,
  rollbar,
} from "../../utils/common";
import SpIconButton from "../../components/atoms/SpIconButton";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SpTooltip from "../../components/atoms/SpTooltip";
import { useForm } from "react-hook-form";
import PatientsMonitoringStep1 from "./patientsMonitoring/PatientsMonitoringStep1";
import { StepTitle } from "../../components/bundles";
import { useAuth0 } from "@auth0/auth0-react";

const Button = styled(MuiButton)(spacing);
const UploadIcon = styled(MuiCloudUpload)(spacing);

const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "start",
    flex: 1,
    flexGrow: 2,
  },
  rootCenter: {
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
    flex: 1,
    flexGrow: 1,
  },
  gridRow: {
    alignSelf: "center",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flex: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  largeAvatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PatientsDetail = (props) => {
  const [currentPatient, setCurrentPatient] = useState();
  const [regions, setRegions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [profileImage, setProfileImg] = useState(null);
  const { patId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const [isShowingUpdateSymbol, setIsShowingUpdateSymbol] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { setLoading } = props;

  let patientId = null;
  if (props.id) {
    patientId = props.id;
  } else {
    patientId = patId;
  }

  const uploadProfileImage = async (file) => {
    try {
      //setting up the file to be uploaded
      if (file) {
        await uploadFile(file);

        const result = await setUserProfileImage({
          id_patient: patientId,
          fileName: file.name,
          fileSize: file.size,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
        });
        if (result) {
          props.snackbarShowMessage(result.message);
          fetchData();
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
      rollbar.error("PatientsDetail - uploadProfileImage", error);
    }
  };

  const onFileChange = (e) => {
    uploadProfileImage(e.target.files[0]);
    fetchData();
  };

  const handleClick = (event) => {
    if (profileImage) {
      event.preventDefault();
      setProfileImg(null);
    }
  };

  const fetchData = async () => {
    try {
      const response = await findPatientByPk({ userId: patientId });
      setCurrentPatient(response);
      const resultImageProfile = await getUserProfileImage({
        id_patient: patientId,
      });
      if (resultImageProfile) {
        const profileBase64 = await getResourceFromServer({
          fileName: resultImageProfile.name,
        });
        if (profileBase64) {
          setProfileImg(profileBase64);
        }
      }

      const regionsResult = await getRegions();
      regionsResult.length > 0 && setRegions(regionsResult);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchAreasByRegion = async () => {
    try {
      if (selectedRegion) {
        const currentAreas = await getAreasByRegion({
          id_region: regions.find((elem) => elem.key === selectedRegion).id,
        });
        setAreas(currentAreas);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const titles = labels.patient.patientRegistry.steps.map((t) => (
    <StepTitle>{t}</StepTitle>
  ));

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );

    setIsPatientLabel(patientLabelTemp);
  }, []);

  useEffect(() => {
    fetchAreasByRegion(selectedRegion);
  }, [selectedRegion]);

  const LabelValue = ({ label, value }) => (
    <Grid
      direction="column"
      style={{ marginBottom: "5px", alignContent: "center" }}
      container
    >
      <SpText variant="text" style={{ alignSelf: "center" }}>
        {label}
      </SpText>
      <SpText variant="h4ComponentLabel" style={{ alignSelf: "center" }}>
        {value}
      </SpText>
    </Grid>
  );
  const { register, errors, control, getValues } = useForm({
    defaultValues: {
      patientName: "",
      birthDate: "",
      phone: "",
      job: "",
    },
    shouldUnregister: false,
  });

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container spacing={2} direction="row">
        <Grid item xs={12} container spacing={1} direction="row">
          <Grid
            item
            xs={2}
            style={{
              border: "1px solid #31caad",
              textAlign: "center",
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Avatar
                imgProps={{
                  style: {
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "cover",
                  },
                }}
                className={classes.largeAvatar}
                src={profileImage ? profileImage : ""}
              />
              <input
                accept="image/*"
                hidden
                id="avatar-image-upload"
                type="file"
                name="file"
                onChange={onFileChange}
              />
              <label
                htmlFor="avatar-image-upload"
                style={{
                  color: "white",
                  textAlign: "flex-end",
                  margin: "0 auto",
                }}
              >
                <Button
                  variant="contained"
                  color="white"
                  component="span"
                  mb={2}
                  onClick={handleClick}
                >
                  <UploadIcon />
                </Button>
              </label>
            </div>
            <Grid
              direction="column"
              style={{ marginBottom: "5px", alignContent: "center" }}
              container
            >
              <SpText variant="text" style={{ alignSelf: "center" }}>
                {labels.patient.patientEditInput.name.toUpperCase()}
              </SpText>
              <SpText
                variant="h4ComponentLabel"
                style={{ alignSelf: "center" }}
              >
                <SpTooltip
                  title={getLabelFromPatientStatus({
                    status: currentPatient?.status,
                  })}
                >
                  <SpIconButton variant="lightBlue" small="true">
                    <FiberManualRecordIcon
                      style={{
                        cursor: "default",
                        color: getColorFromPatientStatus({
                          status: currentPatient?.status,
                        }),
                      }}
                    />
                  </SpIconButton>
                </SpTooltip>
                {`${currentPatient?.givenName} ${currentPatient?.familyName}`}
              </SpText>
            </Grid>

            {currentPatient?.birth_date && (
              <LabelValue
                label={labels.patient.patientEditInput.birthDate.toUpperCase()}
                value={moment(currentPatient?.birth_date).format("DD/MM/yyyy")}
              />
            )}
            {currentPatient?.gender && (
              <LabelValue
                label={labels.patient.patientRegistry.sex.toUpperCase()}
                value={
                  currentPatient?.gender === "F"
                    ? labels.patient.patientRegistry.sexWoman.toUpperCase()
                    : labels.patient.patientRegistry.sexMan.toUpperCase()
                }
              />
            )}
            {currentPatient?.height && (
              <LabelValue
                label={labels.patient.patientRegistry.height.toUpperCase()}
                value={currentPatient?.height}
              />
            )}
            {currentPatient?.weight && (
              <LabelValue
                label={labels.patient.patientRegistry.weight.toUpperCase()}
                value={currentPatient?.weight}
              />
            )}
            {currentPatient?.BMI && (
              <LabelValue
                label={labels.patient.patientRegistry.bmi.toUpperCase()}
                value={currentPatient?.BMI}
              />
            )}
            {currentPatient?.main_role && (
              <LabelValue
                label={labels.patient.patientRegistry.mainRole.toUpperCase()}
                value={currentPatient?.main_role}
              />
            )}
            {currentPatient?.phone && (
              <LabelValue
                label={labels.patient.patientEditInput.phone.toUpperCase()}
                value={currentPatient?.phone}
              />
            )}
            {currentPatient?.email && (
              <LabelValue
                label={labels.patient.patientAdd.email.toUpperCase()}
                value={currentPatient?.email}
              />
            )}
            {currentPatient?.coupon_code && (
              <LabelValue
                label={labels.patient.patientAdd.coupon.toUpperCase()}
                value={currentPatient?.coupon_code}
              />
            )}
          </Grid>
          <Grid item xs={10} style={{ margin: "auto 0", padding: 0 }}>
            <PatientsMonitoringStep1
              register={register}
              control={control}
              getValues={getValues}
              patient={currentPatient}
              setLoading={setLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsDetail);
