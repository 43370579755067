import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../App.css";
import { dateFormat } from "../../../utils/common";
import SpIconButton from "../../../components/atoms/SpIconButton";
import { DeleteForever } from "@material-ui/icons";
import { useParams } from "react-router-dom";
import SpTextArea from "../../../components/atoms/SpTextArea";
import Moment from "moment";
import { extendMoment } from "moment-range";
import SpButton from "../../../components/atoms/SpButton";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { labels, psTranslate } from "../../shared/translations";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpDialog from "../../../components/atoms/SpDialog";
import InfoIcon from "@material-ui/icons/Info";

const moment = extendMoment(Moment);
import {
  getMedicalNotes,
  addMedicalNotes,
  removeMedicalNotes,
} from "../../../models/actions/MedicalNotes";

const MEDICAL_NOTE_TYPE = "visits";

const DEFAULT_RANGE = moment.rangeFromInterval("month", -6, moment());
const constPadding = { padding: "5px" };
const userDateFormat = "DD/MM/yyyy";

const PatientsMedicalNotesCompetitiveVisits = (props) => {
  const { patId } = useParams();

  const [displayReports, setDisplayReports] = useState();
  const [dateSelected, setDateSelected] = useState(moment());
  const [noteSelected, setNoteSelected] = useState("");
  const [visitType, setVisitType] = useState("");
  const [visitReport, setVisitReport] = useState("");
  const [visitDetailModal, setvisitDetailModal] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);

  const { register } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    const res = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date: dateRange.start,
        end_date: dateRange.end,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDisplayReports(res.answer);
  }, []);

  const headCellsFirst = [
    {
      type: "string",
      field: "visitType",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.visit.visitType),
      flex: 1,
      valueGetter: (params) => params.row.visit_type,
      renderCell: (params) => (
        <SpText variant={"tableText"}>{params.row.visit_type}</SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "string",
      field: "note",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.monitoring.pathologiesStep.note),
      flex: 1.5,
      valueGetter: (params) => params.row.note,
      renderCell: (params) => (
        <SpText variant={"tableText"}>{params.row.note}</SpText>
      ),
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      type: "date",
      field: "date",
      numeric: false,
      editable: false,
      sortable: true,
      headerName: psTranslate(labels.patient.medicalNotes.report.date),
      flex: 0.5,
      valueGetter: (params) =>
        moment(params.row.endDate).format(userDateFormat),
      renderCell: (params) => (
        <SpText variant={"tableText"}>
          {moment(params.row.date).format(userDateFormat)}
        </SpText>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
    {
      type: "action",
      field: "action",
      numeric: false,
      editable: false,
      sortable: false,
      headerName: " ",
      flex: 0.5,
      renderCell: (params) => (
        <>
          <SpIconButton
            id="infoButton"
            variant="lightBlueFill"
            onClick={async () => {
              setvisitDetailModal(params?.row);
              setDialog(true);
            }}
          >
            <InfoIcon />
          </SpIconButton>
          <SpIconButton
            variant="lightBlueFill"
            id="deleteButton"
            onClick={async () => {
              const resRemove = await removeMedicalNotes({ id: params.id });
              if (resRemove.error) {
                props.snackbarShowErrorMessage(resRemove.message);
              } else {
                props.snackbarShowMessage(resRemove.message);
              }
              props.snackbarShowMessage(resRemove.message);
              setDisplayReports(
                displayReports.filter(({ id }) => id != params.id)
              );
            }}
          >
            <DeleteForever />
          </SpIconButton>
        </>
      ),
      sortComparator: (v1, v2) => moment(v1).isBefore(moment(v2)),
    },
  ];

  const searchReport = async ({ start_date, end_date }) => {
    const resultSearch = await getMedicalNotes({
      data: {
        id_patient: patId,
        start_date,
        end_date,
        type: MEDICAL_NOTE_TYPE,
      },
    });
    setDisplayReports(resultSearch.answer);
  };

  const addReport = async () => {
    const resAdd = await addMedicalNotes({
      data: {
        id_patient: patId,
        visit_type: visitType,
        visit_report: visitReport,
        note: noteSelected,
        date: dateSelected,
        type: MEDICAL_NOTE_TYPE,
      },
    });

    if (resAdd.message) {
      setDisplayReports([...displayReports, resAdd.body]);
      props.snackbarShowMessage(resAdd.message);
    } else {
      props.snackbarShowErrorMessage(resAdd.error);
    }

    setNoteSelected("");
    setDateSelected(moment());
    setVisitType("");
    setVisitReport("");
  };

  const gridReport = (
    <>
      {/* Add report section */}
      <Grid container spacing={3}>
        <Grid container>
          <Grid item xs={8}>
            <SpText variant="h1PageTitle">
              {psTranslate(labels.patient.medicalNotes.steps[3].toUpperCase())}
            </SpText>
          </Grid>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.visit.add)}
            </SpText>
          </Grid>
          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              name="visitType"
              id="visitType"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setVisitType(evnt.target.value);
              }}
              value={visitType}
              label={psTranslate(
                labels.patient.medicalNotes.visit.visitType.toUpperCase()
              )}
            />
          </Grid>
          <Grid item xs={6} style={constPadding}>
            <SpTextInput
              name="visitReport"
              id="visitReport"
              inputRef={register({ required: true })}
              style={{ width: "100%" }}
              onChange={(evnt) => {
                setVisitReport(evnt.target.value);
              }}
              value={visitReport}
              label={psTranslate(
                labels.patient.medicalNotes.visit.visitReport.toUpperCase()
              )}
            />
          </Grid>

          {/* Dates input*/}
          <Grid item xs={2} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.medicalNotes.report.date.toUpperCase()
              )}
              value={moment(dateSelected).format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) =>
                setDateSelected(moment(evnt.target.value).format(dateFormat))
              }
            />
          </Grid>
          <Grid item xs={12} style={constPadding}>
            <SpTextArea
              value={noteSelected}
              name="notes"
              id="notes"
              label={psTranslate(
                labels.patient.monitoring.pathologiesStep.note.toUpperCase()
              )}
              onChange={(event) => setNoteSelected(event.target.value)}
            />
          </Grid>
          <Grid item xs={8}>
            <SpButton
              id="addFormButton"
              style={{ marginTop: "1em" }}
              text={psTranslate(labels.patient.medicalNotes.report.addButton)}
              buttonType={"accept"}
              variant="none"
              onClick={addReport}
            />
          </Grid>
        </Grid>

        {/* Search report section */}
        <Grid container style={{ paddingTop: "4em" }}>
          <Grid container>
            <SpText variant={"h1PageSubtitle"}>
              {psTranslate(labels.patient.medicalNotes.visit.search)}
            </SpText>
          </Grid>

          {/* Dates */}
          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.start_date
              )}
              value={dateRange.start.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                moment(evnt.target.value).isBefore(dateRange.end) &&
                  setDateRange(moment.range(evnt.target.value, dateRange.end));
                searchReport({
                  start_date: evnt.target.value,
                  end_date: dateRange.end,
                });
              }}
            />
          </Grid>

          <Grid item lg={2} xs={4} style={constPadding}>
            <SpTextInput
              label={psTranslate(
                labels.patient.graphReport.section.feedback.filters.end_date
              )}
              value={dateRange.end.format(dateFormat)}
              style={{ width: "100%" }}
              type={"date"}
              disableKeyboardInput={true}
              onChange={(evnt) => {
                dateRange.start.isBefore(moment(evnt.target.value)) &&
                  setDateRange(
                    moment.range(dateRange.start, evnt.target.value)
                  );
                searchReport({
                  start_date: dateRange.start,
                  end_date: evnt.target.value,
                });
              }}
            />
          </Grid>
        </Grid>

        {/* Table report section */}
        {displayReports?.length > 0 && (
          <Grid container style={{ marginLeft: "4px" }} id="visitGrid">
            <SpDataGrid
              rows={displayReports}
              headers={headCellsFirst}
              emptyText={psTranslate(labels.groups.groupReports.no_patients)}
            />
          </Grid>
        )}
      </Grid>

      <SpDialog
        style={{ padding: "1%" }}
        open={dialog}
        setOpen={setDialog}
        title={psTranslate(labels.patient.medicalNotes.visit.modalNote)}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.visit.visitType)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{visitDetailModal?.visit_type}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.medicalNotes.visit.visitReport)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{visitDetailModal?.visit_report}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpText variant={"nameSurnameHeader"}>
              {psTranslate(labels.patient.monitoring.pathologiesStep.note)}
            </SpText>
          </Grid>
          <Grid item xs={4}>
            <SpText>{visitDetailModal?.note}</SpText>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );

  return gridReport;
};

export default withSnackbar(PatientsMedicalNotesCompetitiveVisits);
