import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import { styled } from "../../../components/styled";
import { getPatientRegistry } from "../../../models/actions/Patients";
import SpDialog from "../../../components/atoms/SpDialog";

import {
  deletePresentationById,
  getPresentationDetailById,
  updatePresentation,
} from "../../../models/actions/Presentation";
import { labels, psTranslate } from "../../shared/translations";
import { getSelectmenu, formatDateTimeToLocal } from "../../../utils/common";
import { getAllCalendarActivityByMonitoring } from "../../../models/actions/CalendarActivity";
import { getList } from "./PatientsPresentationDetailSummaryFunction";
import PatientsPresentationUpdate from "../patientsPresentationAdd/PatientsPresentationUpdate";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  width: "100%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "45%",
  flexDirection: "column",
});

const PatientsPresentationDetailSummary = (props) => {
  const [presentation, setPresentation] = useState();
  const [activityinjury, setActivityinjury] = useState();
  const [mechanism, setMechanism] = useState();
  const [intervention, setIntervention] = useState();
  const [clubnational, setClubnational] = useState();
  const [relationship, setRelationship] = useState();
  const [part, setPart] = useState();
  const [specifiAction, setSpecificAction] = useState();
  const [onset, setOnset] = useState();
  const [reinjury, setReinjury] = useState();
  const [patientRegistry, setPatientRegistry] = useState();
  const [groups, setGroups] = useState([]);
  const [sports, setSports] = useState([]);
  const [timeLoss, setTimeLoss] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [openDeletePres, setOpenDeletePres] = useState(false);
  const [openUpdatePres, setOpenUpdatePres] = useState(false);
  const [openClosePres, setOpenClosePres] = useState(false);
  const [dataPresentation, setDataPresentation] = useState({});

  const [listColumn, setListColumn] = useState([]);
  const [mapSelectInjuryCategory, setMapSelectInjuryCategory] = useState([]);
  const { patId, presentationId } = useParams();

  const history = useHistory();

  const { control, register, handleSubmit, getValues, setValue, watch } =
    useForm({
      shouldUnregister: false,
    });

  const watchInjuryActivity = watch("selectinjuryactivity");

  const fetchData = async () => {
    try {
      // recupero la presentazione detail
      const result = await getPresentationDetailById({
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (result) {
        setPresentation(result?.presentation);
        // setto la estimated end date se presente
        if (result?.presentation?.start_date) {
          setValue(
            "start_date",
            moment(result?.presentation?.start_date).format("YYYY-MM-DD")
          );
        }
        if (result?.presentation?.end_date) {
          setValue(
            "end_date",
            moment(result?.presentation?.end_date).format("YYYY-MM-DD")
          );
        }
        if (result?.presentation?.estimated_end_date) {
          setValue(
            "estimated_end_date",
            moment(String(result?.presentation?.estimated_end_date)).format(
              "YYYY-MM-DD"
            )
          );
        }
        setValue("note", result?.presentation?.note);
        setValue(
          "selectinjuryactivity",
          result?.presentation?.activity_during_injury
        );
        setValue("selectmechanism", result?.presentation?.mechanism);
        setValue("intervention", result?.presentation?.intervention);
        setValue("selectclubnational", result?.presentation?.club_national);
        setValue("selectrelationship", result?.presentation?.relationship);
        setValue("selectpart", result?.presentation?.part);
        setValue("selectreinjury", result?.presentation?.reinjury);
        setValue("selectspecificaction", result?.presentation?.specific_action);
        setValue("selectonset", result?.presentation?.onset);
        setValue(
          "selecttimeloss",
          result?.presentation?.time_loss ? result?.presentation?.time_loss : 1
        );
        setValue("selectactivity", result?.presentation?.activity);

        const listItems = result?.presentation?.patient?.groups?.map((d) => (
          <ul key={d.name}>{d.name}</ul>
        ));
        setGroups(listItems);
        // recupero injury detail
        const injuryDetail = result?.injuryDetail.reduce((group, product) => {
          const { column_name } = product;
          group[column_name] = group[column_name] ?? [];
          group[column_name].push(product);
          return group;
        }, {});

        for (const [key, value] of Object.entries(injuryDetail)) {
          injuryDetail[key] = value.reduce((group, product) => {
            const { category_name } = product;
            group[category_name] = group[category_name] ?? [];
            group[category_name].push(product);
            return group;
          }, {});
        }

        const patientR = await getPatientRegistry({ userId: patId });
        const mapPatientSport = patientR
          ?.find((question) => question.type == "sport")
          ?.questions.filter(
            ({ type }) => type === "sport-estivo" || type === "sport-invernale"
          )
          .map(({ answers }) => answers.filter(({ value }) => value === true))
          .flat();
        setSports(mapPatientSport);
        if (
          mapPatientSport?.length > 0 &&
          labels.patient.presentation.add.stepper.step0.detail.part[
            mapPatientSport[0]?.code
          ]?.choise
        ) {
          setPart(
            getSelectmenu(
              labels.patient.presentation.add.stepper.step0.detail.part.name,
              labels.patient.presentation.add.stepper.step0.detail.part[
                mapPatientSport[0]?.code
              ]?.choise,
              `selectpart`,
              labels.patient.presentation.add.stepper.step0.detail.part.name,
              control
            )
          );
        }

        const sport = patientR.find(({ type }) => type === "sport");
        const individual = sport?.questions.find(
          ({ type }) => type === "sport-estivo"
        );
        let sports = [];
        individual?.answers?.forEach((answer) => {
          if (answer.value == true) {
            sports.push(answer);
          }
        });
        setPatientRegistry(sports);
        const listColumnTemp = getList({
          injuryDetail,
          mapSelectInjuryCategory,
          setMapSelectInjuryCategory,
          result,
          control,
          presentation,
        });
        setListColumn(listColumnTemp);

        const responseEvents = await getAllCalendarActivityByMonitoring({
          id_patient: patId,
          start_date: moment(result?.presentation?.start_date).subtract(
            1,
            "day"
          ),
          end_date: moment(result?.presentation?.start_date).add(1, "day"),
        });
        const eventsFiltered = responseEvents.map((item) => {
          return {
            value: `${psTranslate(
              item?.activity_datum?.activity_type?.name
            )} ${formatDateTimeToLocal({
              date: item.start_date,
              time: item.start_time,
            })?.format("DD/MM/YYYY HH:mm")}`,
            key: item.id,
          };
        });

        setActivityinjury(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.activity.name,
            labels.patient.presentation.add.stepper.step0.detail.activity
              .choise,
            `selectinjuryactivity`,
            labels.patient.presentation.add.stepper.step0.detail.activity.name,
            control
          )
        );

        setMechanism(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.mechanism.name,
            labels.patient.presentation.add.stepper.step0.detail.mechanism
              .choise,
            `selectmechanism`,
            labels.patient.presentation.add.stepper.step0.detail.mechanism.name,
            control
          )
        );
        setIntervention(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.intervention
              .name,
            labels.patient.presentation.add.stepper.step0.detail.intervention
              .choise,
            `intervention`,
            labels.patient.presentation.add.stepper.step0.detail.intervention
              .name,
            control
          )
        );
        setClubnational(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.club_national
              .name,
            labels.patient.presentation.add.stepper.step0.detail.club_national
              .choise,
            `selectclubnational`,
            labels.patient.presentation.add.stepper.step0.detail.club_national
              .name,
            control
          )
        );

        setRelationship(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.relationship
              .name,
            labels.patient.presentation.add.stepper.step0.detail.relationship
              .choise,
            `selectrelationship`,
            labels.patient.presentation.add.stepper.step0.detail.relationship
              .name,
            control
          )
        );

        setSpecificAction(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.specificAction
              .name,
            labels.patient.presentation.add.stepper.step0.detail.specificAction
              .choise,
            `selectspecificaction`,
            labels.patient.presentation.add.stepper.step0.detail.specificAction
              .name,
            control
          )
        );

        setOnset(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.onset.name,
            labels.patient.presentation.add.stepper.step0.detail.onset.choise,
            `selectonset`,
            labels.patient.presentation.add.stepper.step0.detail.onset.name,
            control
          )
        );

        setTimeLoss(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.timeLoss.name,
            labels.patient.presentation.add.stepper.step0.detail.timeLoss
              .choise,
            `selecttimeloss`,
            labels.patient.presentation.add.stepper.step0.detail.timeLoss.name,
            control
          )
        );

        setReinjury(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail.reinjury.name,
            labels.patient.presentation.add.stepper.step0.detail.reinjury
              .choise,
            `selectreinjury`,
            labels.patient.presentation.add.stepper.step0.detail.reinjury.name,
            control
          )
        );

        setSelectedActivity(
          getSelectmenu(
            labels.patient.presentation.add.stepper.step0.detail
              .activitySelected.name,
            eventsFiltered,
            `selectactivity`,
            labels.patient.presentation.add.stepper.step0.detail
              .activitySelected.name,
            control
          )
        );
      }

      result?.detail_answer.forEach((answer) => {
        const itemCateg = mapSelectInjuryCategory?.filter(
          (item) =>
            item?.injury_category == answer?.injury_detail?.category_name
        );
        if (itemCateg?.length > 0)
          setValue("select" + itemCateg[0]?.index, answer?.id_injury_detail);
      });
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };
  useEffect(() => fetchData(), []);

  const closePresentationFunction = async (data) => {
    data.id_presentation = presentationId;
    if (data.end_date) {
      await updatePresentationFunction(data);
      history.push(`/patients/edit/${patId}/patientsInjuryIllness`);
    } else {
      setOpenClosePres(true);
    }
  };
  const updatePresentationFunction = async (data) => {
    data.id_presentation = presentationId;
    try {
      const request = [];
      mapSelectInjuryCategory.forEach((item, index) => {
        request.push({
          id_injury_detail: getValues("select" + (index + 1)),
        });
      });
      const newData = {
        id_presentation: presentationId,
        estimated_end_date: data?.estimated_end_date,
        start_date: data?.start_date,
        end_date: data?.end_date,
        note: getValues("note"),
        data: request,
        activity_during_injury: getValues("selectinjuryactivity"),
        mechanism: getValues("selectmechanism"),
        intervention: getValues("intervention"),
        specific_action: getValues("selectspecificaction"),
        onset: getValues("selectonset"),
        time_loss: getValues("selecttimeloss"),
        reinjury: getValues("selectreinjury"),
        activity: getValues("selectactivity"),
        club_national: getValues("selectclubnational"),
        relationship: getValues("selectrelationship"),
        part: watchInjuryActivity == "2" ? getValues("selectpart") : null,
        id_patient: patId,
      };
      Object.keys(request).map((key) => {
        if (!data[key]) {
          newData[key] = null;
        }
      });

      setDataPresentation(newData);

      const response = await updatePresentation(newData);
      props.snackbarShowMessage(response.message);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const deletePresentation = async () => {
    try {
      const result = await deletePresentationById({
        id_presentation: presentationId,
        id_patient: patId,
      });
      if (result.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result.message);
        history.push(`/patients/edit/${patId}/patientsInjuryIllness`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const LabelValue = (props) => {
    return (
      <Grid
        container
        direction="column"
        key={"grid_props_label" + props.label}
        style={{ marginBottom: "5px" }}
      >
        {props?.label && (
          <SpText
            variant="text"
            value={props.label}
            className={props?.className}
          >
            {props?.label}
          </SpText>
        )}
        {props?.value && (
          <SpText
            variant="h4ComponentLabel"
            value={props.label}
            className={props?.className}
          >
            {props.value}
          </SpText>
        )}
      </Grid>
    );
  };

  return (
    <>
      <StyledBodyColumn>
        <StyledRow>
          <StyledBodyHalfColumnToolbar>
            <SpText className="prova" variant="h1PageTitle">
              {labels.patient.presentation.add.stepper.step0.title.toUpperCase()}
            </SpText>
          </StyledBodyHalfColumnToolbar>
        </StyledRow>
        <StyledRow>
          {
            <Grid container spacing={1}>
              {
                <Grid item xs={3}>
                  <SpButton
                    style={{ width: "100%" }}
                    variant="none"
                    onClick={() => handleSubmit(updatePresentationFunction)()}
                    className="buttonClass"
                    buttonType="accept"
                    text={labels.patient.presentation.add.save}
                  />
                </Grid>
              }
              {
                <Grid item xs={3}>
                  <SpButton
                    style={{ width: "100%" }}
                    variant="none"
                    onClick={() => handleSubmit(closePresentationFunction)()}
                    className="buttonClass"
                    buttonType="accept"
                    text={labels.general.saveAndClose}
                  />
                </Grid>
              }

              {
                <Grid item xs={3}>
                  <SpButton
                    id="editPresentations"
                    className="buttonClass"
                    buttonType="accept"
                    style={{ width: "100%" }}
                    text={labels.patient.presentation.detail.editInjury}
                    variant="none"
                    onClick={() => setOpenUpdatePres(true)}
                  />
                </Grid>
              }
              {
                <Grid item xs={3}>
                  <SpButton
                    id="deletePresentation"
                    buttonType="cancel"
                    style={{ width: "100%" }}
                    text={labels.patient.presentation.detail.deletePresentation}
                    variant="h1PageTitle"
                    onClick={() => setOpenDeletePres(true)}
                  />
                </Grid>
              }
            </Grid>
          }
        </StyledRow>

        <form onSubmit={handleSubmit(updatePresentationFunction)}>
          <StyledRow>
            <Grid
              alignContent="start"
              justifyContent="start"
              container
              spacing={2}
              style={{ marginBottom: "1%", marginTop: "5px" }}
            >
              <Grid
                item
                xs={4}
                style={{ marginBottom: "1%" }}
                className="startDate"
              >
                {
                  <SpTextInput
                    name="start_date"
                    type="date"
                    id="startDate"
                    style={{ width: "100%" }}
                    maxValue={"2100-12-31"}
                    defaultValue={
                      getValues("start_date")
                        ? getValues("start_date")
                        : moment(new Date()).format("YYYY-MM-DD")
                    }
                    inputRef={register}
                    // disabled={disableControl}
                    label={labels.patient.presentation.add.startDate}
                  />
                }
              </Grid>
              <Grid
                item
                xs={4}
                style={{ marginBottom: "1%" }}
                className="endDate"
              >
                {
                  <SpTextInput
                    name="end_date"
                    type="date"
                    id="endDate"
                    maxValue={"2100-12-31"}
                    defaultValue={
                      getValues("end_date")
                        ? moment(getValues("end_date")).format("DD/MM/YYYY")
                        : null
                    }
                    inputRef={register}
                    style={{ width: "100%" }}
                    label={labels.patient.presentation.add.endDate}
                  />
                }
              </Grid>
              <Grid
                item
                xs={4}
                style={{ marginBottom: "1%" }}
                className="estimated_end_date"
              >
                {
                  <SpTextInput
                    name="estimated_end_date"
                    type="date"
                    id="estimated_end_date"
                    maxValue={"2100-12-31"}
                    defaultValue={
                      getValues("estimated_end_date")
                        ? moment(getValues("estimated_end_date")).format(
                            "DD/MM/YYYY"
                          )
                        : null
                    }
                    inputRef={register}
                    style={{ width: "100%" }}
                    label={labels.patient.presentation.add.estimatedEndDate}
                  />
                }
              </Grid>
              <Grid item xs={4} style={{ marginBottom: "1%" }}>
                <LabelValue
                  label={labels.patient.proms.sport}
                  value={patientRegistry?.map(({ answer }) => answer)}
                />
              </Grid>

              <Grid
                container
                item
                spacing={2}
                alignContent="start"
                justifyContent="space-between"
              >
                <Grid item>
                  <SpText variant="text">
                    {labels.patient.presentation.add.stepper.step0.region}
                  </SpText>
                  <SpText
                    variant="h4ComponentLabel"
                    className="region"
                    id="region"
                  >
                    {psTranslate(presentation?.region?.name)}
                  </SpText>
                </Grid>

                <Grid item>
                  <SpText variant="text">
                    {psTranslate(
                      labels.patient.presentation.add.stepper.step0.tissueType
                    )}
                  </SpText>
                  <SpText variant="h4ComponentLabel" className="tissueType">
                    {psTranslate(presentation?.pathology?.tissue_type?.name)}
                  </SpText>
                </Grid>

                <Grid item>
                  <SpText variant="text">
                    {psTranslate(
                      labels.patient.presentation.add.stepper.step0
                        .pathologyType
                    )}
                  </SpText>
                  <SpText
                    variant="h4ComponentLabel"
                    className="pathologyType"
                    id="pathologyType"
                  >
                    {psTranslate(presentation?.pathology?.pathology_type?.name)}
                  </SpText>
                </Grid>

                <Grid item>
                  <SpText variant="text">
                    {psTranslate(
                      labels.patient.presentation.add.stepper.step0
                        .medicalDiagnose
                    )}
                  </SpText>
                  <SpText
                    variant="h4ComponentLabel"
                    className="pathology"
                    id="pathology"
                  >
                    {psTranslate(presentation?.pathology?.name)}
                  </SpText>
                </Grid>

                <Grid item>
                  <SpText variant="text">
                    {psTranslate(
                      labels.patient.presentation.add.stepper.step0.osiicsCode
                    )}
                  </SpText>
                  <SpText variant="h4ComponentLabel" className="osiics">
                    {presentation?.pathology?.osiics_code}
                  </SpText>
                </Grid>
              </Grid>
            </Grid>
          </StyledRow>
          <Grid
            container
            spacing={1}
            alignContent="start"
            justifyContent="start"
          >
            <Grid item style={{ marginBottom: "1%" }} className="activity">
              {activityinjury}
            </Grid>

            {watchInjuryActivity == "2" && (
              <Grid item style={{ marginBottom: "1%" }} className="part">
                {part}
              </Grid>
            )}

            <Grid item style={{ marginBottom: "1%" }} className="mechanism">
              {relationship}
            </Grid>
            <Grid
              item
              style={{ marginBottom: "1%" }}
              className="reletedActivity"
            >
              {selectedActivity}
            </Grid>

            <Grid item style={{ marginBottom: "1%" }} className="mechanism">
              {clubnational}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            alignContent="start"
            justifyContent="start"
          >
            <Grid item style={{ marginBottom: "1%" }} className="onset">
              {onset}
            </Grid>
            <Grid item style={{ marginBottom: "1%" }} className="mechanism">
              {mechanism}
            </Grid>
            <Grid item style={{ marginBottom: "1%" }} className="intervention">
              {intervention}
            </Grid>
            {sports.find(({ code }) => code === "FBL") && (
              <Grid
                item
                style={{ marginBottom: "1%" }}
                className="specificAction"
              >
                {specifiAction}
              </Grid>
            )}
          </Grid>
          <Grid
            container
            spacing={1}
            alignContent="start"
            justifyContent="start"
          >
            <Grid item style={{ marginBottom: "1%" }} className="timeLoss">
              {timeLoss}
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            alignContent="start"
            justifyContent="start"
          >
            <Grid item style={{ marginBottom: "1%" }} className="reinjury">
              {reinjury}
            </Grid>
          </Grid>
          <StyledRow style={{ marginTop: "2%" }}>
            <Grid
              item
              xs={4}
              style={{ marginBottom: "1%" }}
              className="injuryColumn"
            >
              {listColumn}
            </Grid>
          </StyledRow>
          <StyledRow style={{ marginTop: "2%" }}>
            <Grid container item direction="column" xs={12}>
              {
                <SpTextInput
                  variant={"row"}
                  name={"note"}
                  inputRef={register}
                  // disabled={disableControl}
                  id="note"
                  label={
                    labels.patient.presentation.add.stepper.step0
                      .noteDescription
                  }
                />
              }
            </Grid>
          </StyledRow>
        </form>
      </StyledBodyColumn>
      <SpDialog
        open={openDeletePres}
        setOpen={setOpenDeletePres}
        title={labels.patient.presentation.detail.presentationDelete.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {labels.patient.presentation.detail.presentationDelete.content}
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                id="deleteTrue"
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={() => deletePresentation()}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenDeletePres(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
      <SpDialog
        open={openClosePres}
        setOpen={setOpenClosePres}
        title={labels.patient.presentation.detail.closePresentationDialog.title}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <SpText variant="h4ComponentLabel">
              {
                labels.patient.presentation.detail.closePresentationDialog
                  .content
              }
            </SpText>
          </Grid>
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={6}>
              <SpButton
                id="deleteTrue"
                buttonType="cancel"
                text={labels.patient.presentation.detail.deleteConfirm}
                variant="h1PageTitle"
                onClick={async () => {
                  const newPres = {
                    ...presentation,
                    end_date: moment().format("YYYY-MM-DD"),
                  };
                  setPresentation(newPres);
                  setValue("end_date", moment().format("YYYY-MM-DD"));

                  await updatePresentationFunction(newPres);
                  setOpenClosePres(false);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <SpButton
                buttonType="accept"
                text={labels.patient.presentation.detail.deleteDeny}
                variant="h1PageTitle"
                onClick={() => setOpenClosePres(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
      <SpDialog
        open={openUpdatePres}
        setOpen={setOpenUpdatePres}
        title={labels.patient.presentation.detail.editInjury}
      >
        <Grid container spacing={2} direction="column">
          {presentation && (
            <Grid item xs={12}>
              <PatientsPresentationUpdate
                setLoading={() => {}}
                presentation={presentation}
                closeUpdate={async () => {
                  setOpenUpdatePres(false);
                  await fetchData();
                }}
              />
            </Grid>
          )}
        </Grid>
      </SpDialog>
    </>
  );
};

export default withSnackbar(PatientsPresentationDetailSummary);
