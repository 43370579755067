import "../../../App.css";
import { Grid } from "@material-ui/core";
import Moment from "moment";
import { extendMoment } from "moment-range";
import React, { useEffect, useState } from "react";
import SpCheckbox from "../../../components/atoms/SpCheckbox";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  getStatisticsConfigurationBy,
  setStatisticsConfigurationBy,
} from "../../../models/actions/Patients";
import { dateFormat, getUserPermission } from "../../../utils/common";
import withSaveConfig from "../../shared/SaveWrapper";
import { labels } from "../../shared/translations";
import StatisticsActivities from "./StatisticsActivities";
import StatisticsFeedbacks from "./StatisticsFeedbacks";
import { categoryFunctions, extraFunctionKeys } from "./StatisticsHelpers";
import StatisticsTable from "./StatisticsTable";
import { useAuth0 } from "@auth0/auth0-react";
import { getAllActivitiesTypes } from "../../../models/actions/Activity";

const moment = extendMoment(Moment);

const DEFAULT_RANGE = moment.rangeFromInterval("month", -6, moment());

const constPadding = { padding: "5px" };
const SECOND_TABLE_DATA_CATEGORIES = Object.keys(categoryFunctions);

const Statistics = ({
  requestSaveFlag,
  setConfig,
  loadConfiguration,
  startSave,
  isPrint,

  date,
  activity,
}) => {
  const [dateRange, setDateRange] = useState(DEFAULT_RANGE);
  const [tableData, setTableData] = useState([]);
  const [activities, setActivities] = useState(null);
  const [dateLoaded, setDateLoaded] = useState(false);
  const [currActivities, setCurrActivities] = useState([]);
  const [studyParameters, setStudyParameters] = useState(null);
  const [activeStudyParameters, setActiveStudyParameters] = useState([]);
  const [currStudyParameters, setCurrStudyParameters] = useState([]);
  const [isPatientLabel, setIsPatientLabel] = useState(false);

  const [currSecondTableCategories, setCurrSecondTableCategories] = useState(
    new Set([SECOND_TABLE_DATA_CATEGORIES[0]])
  );
  const [currSecondTableExtras, setCurrSecondTableExtras] = useState(new Set());

  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    try {
      //Fetch data
      const activs = await getAllActivitiesTypes(getAccessTokenSilently);
      setActivities(activs);
      const patientLabelTemp = await getUserPermission(
        "patient-label",
        getAccessTokenSilently
      );
      setIsPatientLabel(patientLabelTemp);

      if (date) {
        setDateRange(
          moment.range(moment(date, "YYYY-MM-DD"), moment(date, "YYYY-MM-DD"))
        );
      }
      if (activity)
        setCurrActivities([activs.find(({ key }) => key == activity)?.id]);
    } catch (error) {
      rollbar.error("StatisticsActivities - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  }, []);

  //---- Config Section
  useEffect(() => {
    //Load configs
    if (loadConfiguration) {
      const config = loadConfiguration.statistics ?? {};
      if (config.dateRange) {
        const nowStart = moment()
          .startOf("day")
          .add(config.dateRange[0], "days");
        const nowEnd = moment().startOf("day").add(config.dateRange[1], "days");
        setDateRange(moment.range(nowStart, nowEnd));
      }
      if (config.currSecondTableCategories)
        setCurrSecondTableCategories(new Set(config.currSecondTableCategories));
      if (config.currSecondTableExtras)
        setCurrSecondTableExtras(new Set(config.currSecondTableExtras));
      setDateLoaded(true);
    }
  }, [loadConfiguration]);
  useEffect(() => {
    if (requestSaveFlag) {
      //Save config
      const now = moment().startOf("day");
      setConfig({
        statistics: {
          dateRange: [
            dateRange.start.diff(now, "days"),
            dateRange.end.diff(now, "days"),
          ],
          currSecondTableCategories: [...currSecondTableCategories],
          currSecondTableExtras: [...currSecondTableExtras],
        },
      });
    }
  }, [requestSaveFlag]);
  //---- End config Section

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <SpText variant={isPrint ? "h4ComponentLabelPrint" : "h1PageTitle"}>
          {labels.group.controlRoomInternalMenu.titles[1].value.toUpperCase()}
        </SpText>
      </Grid>

      {/* Dates */}
      <Grid item lg={2} xs={4} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.start_date}
          value={dateRange.start.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            moment(evnt.target.value).isBefore(dateRange.end) &&
            setDateRange(moment.range(evnt.target.value, dateRange.end))
          }
        />
      </Grid>

      <Grid item lg={2} xs={4} style={constPadding}>
        <SpTextInput
          label={labels.patient.graphReport.section.feedback.filters.end_date}
          value={dateRange.end.format(dateFormat)}
          style={{ width: "100%" }}
          type={"date"}
          disableKeyboardInput={true}
          onChange={(evnt) =>
            dateRange.start.isBefore(moment(evnt.target.value)) &&
            setDateRange(moment.range(dateRange.start, evnt.target.value))
          }
        />
      </Grid>

      {/* First table */}
      <Grid container item xs={12}>
        {/* Activities dropdown */}
        <Grid item xs={12} style={constPadding}>
          <StatisticsActivities
            dateRange={dateRange}
            currActivities={currActivities}
            setCurrActivities={setCurrActivities}
            activities={activities}
            loadConfiguration={loadConfiguration?.activities}
            setConfig={(data) => setConfig({ activities: data })}
            requestSaveFlag={requestSaveFlag}
            currStudyParameters={currStudyParameters}
            tableData={tableData}
            setTableData={setTableData}
            setActiveStudyParameters={setActiveStudyParameters}
            setStudyParameters={setStudyParameters}
            isPatientLabel={isPatientLabel}
          />
        </Grid>
      </Grid>

      {/* Second table */}
      <Grid container item xs={12}>
        {/* Category checkboxes */}
        <Grid container item xs={1}>
          {SECOND_TABLE_DATA_CATEGORIES.map((tableCateg) => (
            <Grid item xs={12} key={tableCateg} style={{ margin: "auto" }}>
              <SpCheckbox
                label={
                  labels.groups.groupReports.secondTable.categories[tableCateg]
                }
                rightlabel={true}
                formControlStyle={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
                checked={currSecondTableCategories.has(tableCateg)}
                onChange={(_, value) => {
                  const newData = new Set(currSecondTableCategories);
                  if (value) newData.add(tableCateg);
                  else newData.delete(tableCateg);
                  setCurrSecondTableCategories(newData);
                }}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container item xs={11}>
          {/* Feedbacks dropdown */}
          <Grid item xs={12} style={constPadding}>
            <StatisticsFeedbacks
              loadConfiguration={loadConfiguration?.feedbacks}
              activeStudyParameters={activeStudyParameters}
              setConfig={(data) => setConfig({ feedbacks: data })}
              requestSaveFlag={requestSaveFlag}
              currStudyParameters={currStudyParameters}
              setCurrStudyParameters={setCurrStudyParameters}
              studyParameters={studyParameters}
            />
          </Grid>
          {/* Measurements dropdown */}
          {/* {currActivities.includes(ASSESSMENT_ID) && (
            <Grid item xs={12} style={constPadding}>
              <StatisticsMeasurements
                dateRange={dateRange}
                currActivities={currActivities}
                setData={(data) => updateData(data, MEASUREMENTS_KEY, false)}
                assessmentsIds={assessmentsIds}
                dateLoaded={dateLoaded}
                loadConfiguration={loadConfiguration?.measurements}
                setConfig={(data) => setConfig({ measurements: data })}
                requestSaveFlag={requestSaveFlag}
              />
            </Grid>
          )} */}
        </Grid>

        {/* Study checkboxes */}
        <Grid container item xs={12} style={{ paddingTop: 10 }}>
          {extraFunctionKeys.map((extraCateg) => (
            <Grid item xs={1} style={{ display: "flex" }} key={extraCateg}>
              <SpCheckbox
                label={labels.groups.groupReports.secondTable.extra[extraCateg]}
                rightlabel={true}
                formControlStyle={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  margin: "auto",
                }}
                checked={currSecondTableExtras.has(extraCateg)}
                onChange={(_, value) => {
                  const newData = new Set(currSecondTableExtras);
                  if (value) newData.add(extraCateg);
                  else newData.delete(extraCateg);
                  setCurrSecondTableExtras(newData);
                }}
              />
            </Grid>
          ))}
        </Grid>

        {/* Data table */}
        <StatisticsTable
          currStudyParameters={currStudyParameters}
          tableData={tableData}
          studyParameters={studyParameters}
          currSecondTableCategories={currSecondTableCategories}
          isPatientLabel={isPatientLabel}
        />
      </Grid>

      {/* <Grid container item xs={12} justifyContent="flex-end">
        <SpButton
          text={labels.analytics.save}
          buttonType="accept"
          onClick={() => {
            startSave();
          }}
        />
      </Grid> */}
    </Grid>
  );
};

export default withSaveConfig(
  withSnackbar(Statistics),
  getStatisticsConfigurationBy,
  setStatisticsConfigurationBy
);
