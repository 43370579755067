import React, { useState, useEffect } from "react";
import SpIconButton from "../SpIconButton";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import moment from "moment";
import { theme } from "../../theme";

// Funzione per ottenere solo le date significative (inizio e fine)
const generateSignificantDates = (start, end) => {
  return [
    new Date(start).toISOString().split("T")[0],
    new Date(end).toISOString().split("T")[0],
  ];
};

const InjuryCalendar = ({ injuries, setActiveInjuries }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [dateList, setDateList] = useState([]);
  const [visibleRange, setVisibleRange] = useState([0, 6]);
  const [injuryDates, setInjuryDates] = useState(new Set());

  useEffect(async () => {
    // Estrai solo le date di inizio e fine da ogni infortunio
    const allSignificantDates = injuries.flatMap((injury) =>
      generateSignificantDates(injury.start_date, injury.end_date)
    );

    // Aggiungi la data odierna se non è presente
    const today = moment().format("YYYY-MM-DD");
    if (!allSignificantDates.includes(today)) {
      allSignificantDates.push(today);
    }

    // Rimuovi duplicati e ordina le date
    const uniqueDates = [...new Set(allSignificantDates)].sort();
    await handleDateClick(today);
    setDateList(uniqueDates);

    // Crea un Set per tenere traccia delle date con infortuni
    setInjuryDates(new Set(allSignificantDates));
  }, [injuries]);

  const handleDateClick = async (date) => {
    setSelectedDate(date);
    const filtered = injuries
      .filter((injury) =>
        moment(date).isBetween(
          moment(injury.start_date, "YYYY-MM-DD"),
          moment(injury.end_date, "YYYY-MM-DD"),
          null,
          "[]"
        )
      )
      .map((item) => {
        const targetDate = moment(item.start_date);
        const differenceInDays = moment(moment(date, "YYYY-MM-DD")).diff(
          targetDate,
          "days"
        );
        let severity = 1;
        if (differenceInDays > 7 && differenceInDays < 28) severity = 2;
        if (differenceInDays > 28) severity = 3;
        return { ...item, severity };
      });
    setActiveInjuries(filtered);
  };

  useEffect(() => {
    if (dateList.length > 0) {
      const lastIndex = dateList.length - 1;
      const start = Math.max(0, lastIndex - 5); // Mostra gli ultimi 6 giorni
      setVisibleRange([start, lastIndex + 1]);
    }
  }, [dateList]);

  const handleScroll = (direction) => {
    setVisibleRange((prev) => {
      let [start, end] = prev;
      if (direction === "left" && start > 0) {
        return [start - 1, end - 1];
      }
      if (direction === "right" && end < dateList.length) {
        return [start + 1, end + 1];
      }
      return prev;
    });
  };

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <SpIconButton
          buttonType={"accept"}
          disabled={visibleRange[0] === 0}
          onClick={() => handleScroll("left")}
        >
          <NavigateBeforeIcon />
        </SpIconButton>

        {/* Lista delle date visibili */}
        <div
          style={{
            display: "flex",
            gap: "8px",
            padding: "10px",
          }}
        >
          {dateList?.slice(visibleRange[0], visibleRange[1]).map((date) => (
            <div
              key={date}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <button
                key={date}
                onClick={async () => {
                  await handleDateClick(date);
                }}
                style={{
                  padding: "8px 12px",
                  borderRadius: "8px",
                  border: "none",
                  background:
                    selectedDate === date
                      ? theme.colors.primary.lightBlue
                      : theme.colors.primary.lightBlue + "33",
                  color:
                    selectedDate === date
                      ? "#fff"
                      : injuryDates.has(date)
                      ? "#fff"
                      : "#333",
                  cursor: "pointer",
                  minWidth: "60px",
                  textAlign: "center",
                }}
              >
                {moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")}
              </button>
            </div>
          ))}
        </div>

        <SpIconButton
          buttonType={"accept"}
          disabled={visibleRange[1] >= dateList?.length}
          onClick={() => handleScroll("right")}
        >
          <NavigateNextIcon />
        </SpIconButton>
      </div>
    </div>
  );
};

export default InjuryCalendar;
