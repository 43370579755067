import { Grid } from "@material-ui/core";
import React, { createRef, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import SpButton from "../../components/atoms/SpButton";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { downloadScreenshot, isFeatureFlagEnabled } from "../../utils/common";
import printContext from "../../utils/printContext";
import PatientsReportsPDF from "../patients/patientsReports/PatientsReportsPDF";
import { labels } from "../shared/translations";
import Statistics from "./statistics/Statistics";
import GroupEditSidebar from "./shared/GroupEditSidebar";
import { getGroupsById } from "../../models/actions/Groups";
const reference = createRef(null);

const GroupReports = ({ ...props }) => {
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [fetchDataBe, setFetchDataBe] = useState(false);
  const [currentGroup, setCurrentGroup] = useState([]);

  const { groupId } = useParams();
  const history = useHistory();

  useEffect(async () => {
    const groupResults = await getGroupsById({ id_group: groupId });
    setCurrentGroup(groupResults);
    localStorage.removeItem("pdfScreenshot");

    const tempPdfFeatureFlag = await isFeatureFlagEnabled(
      "analytics_pdf_export"
    );
    setPdfFeatureFlag(tempPdfFeatureFlag);
  }, []);

  useEffect(() => {
    // quando fetchData � a true significa che le modifiche sono terminate
    // quindi posso procedere con settare a true isPrint in modo da far partire
    // il trigger per la stampa del PDF
    if (fetchDataBe) {
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div ref={reference}>
        <Grid item xs={12} container spacing={2} direction="row">
          <Grid
            style={{ paddingLeft: "1%" }}
            direction="column"
            container
            spacing={2}
          >
            {!isPrint && (
              <Grid item xs={12} container alignItems={"center"}>
                <Grid item xs={12}>
                  <GroupEditSidebar
                    props={props}
                    groupId={groupId}
                    history={history}
                    currentGroup={currentGroup}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  container
                  spacing={1}
                  direction="row"
                  style={{ paddingLeft: "1%" }}
                >
                  <Grid item xs={12} container spacing={2} direction="row">
                    <Statistics isPrint={isPrint} />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>

      {/* <Grid container style={{ paddingLeft: "1%", paddingTop: "1%" }}>
        <Grid item xs={10}>
          <SpButton
            className="selectGraph"
            text={labels.analytics.selectPDFGraph}
            onClick={() => {
              // attraverso fetchDataBe si attende che tutte le risorse grafiche siano aggiorate
              // prima di far partire l'evento della stampa PDF
              setFetchDataBe(true);
            }}
          />
          {pdfFeatureFlag && (
            <PatientsReportsPDF
              hide={true}
              reference={reference}
              label={"reference"}
              selectedPDF={selectedPDF}
              setSelectedPDF={setSelectedPDF}
              snackbarShowErrorMessage={props.snackbarShowErrorMessage}
            />
          )}{" "}
        </Grid>
        <Grid container xs={2} justifyContent="flex-end">
          {pdfFeatureFlag && selectedPDF.length > 0 && (
            <SpButton
              text={labels.analytics.injuryReport.createPdf}
              buttonType="accept"
              onClick={() => {
                downloadScreenshot(props, setSelectedPDF, "GroupReport");
              }}
            />
          )}{" "}
        </Grid>
      </Grid> */}
    </printContext.Provider>
  );
};

export default withSnackbar(GroupReports);
