import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import SpText from "../components/atoms/SpText";
import { labels } from "./shared/translations";
import SpButton from "../components/atoms/SpButton";
import { styled } from "../components/styled";
import { theme } from "../components/theme";

const StyledRowTemplateItem = styled(Grid)({
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "1%",
  marginBottom: "1%",
});

export const getGpsDevice = (gpsDevicePermission, history) => {
  return (
    <StyledRowTemplateItem item xs={12} container>
      <Grid item xs={8}>
        <SpText variant="h4">{labels.mylab.tempList.gpsDevices}</SpText>
      </Grid>
      <Grid item xs={2}>
        <SpButton
          id={"gpsDeviceAccountButton"}
          disabled={!gpsDevicePermission}
          buttonType="accept"
          text={labels.mylab.tempList.action.manage}
          onClick={() => {
            history.push("/mylab/gpsDevices/account");
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <SpButton
          id={"gpsDevicePatientsButton"}
          disabled={!gpsDevicePermission}
          buttonType="accept"
          text={labels.mylab.tempList.action.view}
          onClick={() => {
            history.push("/mylab/gpsDevices/view");
          }}
        />
      </Grid>
    </StyledRowTemplateItem>
  );
};

export const getManageOrganization = (
  permission,
  history,
  newRequestNotification
) => {
  if (permission) {
    return (
      <StyledRowTemplateItem item xs={12} container>
        <Grid item xs={8}>
          <SpText variant="h4">{labels.mylab.tempList.organization}</SpText>
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id={"organization"}
            disabled={!permission}
            buttonType="accept"
            text={labels.mylab.tempList.action.manage}
            onClick={() => {
              history.push("/organization");
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id={"organization"}
            disabled={!permission}
            buttonType="accept"
            text={labels.mylab.tempList.action.view}
            isNotification={newRequestNotification}
            onClick={() => {
              history.push("/requests/list/organizer");
            }}
          />
        </Grid>
      </StyledRowTemplateItem>
    );
  } else {
    return <></>;
  }
};

export const getManageSchedule = (
  gpsDevicePermission,
  history,
  newRequestNotification
) => {
  if (gpsDevicePermission) {
    return (
      <StyledRowTemplateItem item xs={12} container>
        <Grid item xs={8}>
          <SpText variant="h4">{"Schedule Manager"}</SpText>
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id={"organizationDetails"}
            disabled={!gpsDevicePermission}
            buttonType="accept"
            text={labels.mylab.tempList.action.manage}
            onClick={() => {
              history.push("/schedule/manager");
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            id={"organizationDetails"}
            disabled={!gpsDevicePermission}
            buttonType="accept"
            text={labels.mylab.tempList.action.view}
            isNotification={newRequestNotification}
            onClick={() => {
              history.push("/requests/list");
            }}
          />
        </Grid>
      </StyledRowTemplateItem>
    );
  } else {
    return <></>;
  }
};

export const getGenericRiskFactor = (roleGeneralRiskFactor, history) => {
  if (roleGeneralRiskFactor) {
    return (
      <StyledRowTemplateItem item xs={12} container>
        <Grid item xs={8}>
          <SpText
            variant="h4"
            className={"generalRiskFactorButtonTitle"}
            id="genericRiskFactorMenuItem"
          >
            {labels.mylab.tempList.generalRiskFactor}
          </SpText>
        </Grid>
        <Grid item xs={2}>
          <SpButton
            className={"generalRiskFactorButtonAdd"}
            buttonType="accept"
            text={labels.mylab.tempList.action.view}
            onClick={() => {
              history.push("/mylab/genericRiskFactor/list");
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <SpButton
            className={"generalRiskFactorButtonList"}
            id="genericRiskFactorManage"
            buttonType="accept"
            text={labels.mylab.tempList.action.manage}
            onClick={() => {
              history.push("/mylab/genericRiskFactor/list");
            }}
          />
        </Grid>
      </StyledRowTemplateItem>
    );
  } else {
    return <></>;
  }
};
