/* eslint-disable import/order */
import React, { useState, useEffect } from "react";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import { styled } from "../../../components/styled";
import { labels } from "../../shared/translations";
import { useLocation } from "react-router-dom";
import SpDialog from "../../../components/atoms/SpDialog";
import { Drawer, Grid } from "@material-ui/core";
import SpText from "../../../components/atoms/SpText";
import SpButton from "../../../components/atoms/SpButton";
import { deletePatient } from "../../../models/actions/Patients";
import LogoUrl from "../../../assets/icon/iconSymbol.png";
import { makeStyles } from "@material-ui/core/styles";
import { LogoMenu } from "../../shared/logo/style";
import { ArrowBack } from "@material-ui/icons";
import { useFlags } from "launchdarkly-react-client-sdk";
import MenuIcon from "@material-ui/icons/Menu";

const SidebarNavColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

import { getGroupSidebarRoute, routesGroup } from "./GroupEditSidebarRoutes";
import { SpIconButtonMenu } from "../../../components/atoms/SpIconButton";
import { getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { theme } from "../../../components/theme";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  marginBottom: "4%",
  alignItems: "center",
  justifyContent: "flex-start",
});
const drawerWidth = "15em";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "#20262E",
    width: drawerWidth,
  },
}));

let notFederationRoutes = [
  {
    routeSelected: routesGroup.activity,
    label: labels.group.sideBarGroup.activity,
    id: "activity",
  },
];

const federationRoutes = [
  {
    routeSelected: routesGroup.federationsTeams,
    label: labels.group.sideBarGroup.federationsTeams,
    id: "federationTeams",
  },
  {
    routeSelected: routesGroup.professionalsTeams,
    label: labels.group.sideBarGroup.professionalsTeams,
    id: "federationTeamsAndProfessionals",
  },
  {
    routeSelected: routesGroup.federationInjuries,
    label: labels.group.sideBarGroup.federationInjuries,
    id: "federationInjuries",
  },
];

const GroupEditSidebar = ({ groupId, history, props, currentGroup }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [openDeletePatient, setOpenDeletePatient] = useState();
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isFederation, setIsFederation] = useState(false);
  const [groupSituation, setGroupSituation] = useState(false);
  const [readinessIsEnabled, setReadinessIsEnabled] = useState(false);
  const [permissionProms, setPermissionProms] = useState(false);
  const [flagStatistic, setFlagStatistic] = useState(false);
  const [flagAnalytic, setFlagAnalytic] = useState(false);
  const {
    groupStatistic,
    groupAnalytics,
    readinessTable,
    groupStatisticAnalytics,
  } = useFlags();
  const [groupStatisticPermission, setGroupStatisticPermission] =
    useState(false);

  const [permissionInjurySurveillance, setPermissionInjurySurveillance] =
    useState(false);

  const location = useLocation();
  //const [readinessIsEnabled, setReadinessIsEnabled] = useState(false);
  //const [injurySurveillancePermission, setInjurySuerveillancePermission] =
  //  useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const fetchData = async () => {
    const tempGroupStatisticPermission = await getUserPermission(
      "statistics-group",
      getAccessTokenSilently
    );
    const permissionFederationGroups = await getUserPermission(
      "manage:federation-groups",
      getAccessTokenSilently
    );
    const permissionGroupSituation = await getUserPermission(
      "group-situation",
      getAccessTokenSilently
    );
    const permissionSurveillance = await getUserPermission(
      "injury-surveillance",
      getAccessTokenSilently
    );

    const permissionControlRoom = await getUserPermission(
      "manage:group-control-room",
      getAccessTokenSilently
    );
    const permissionProms = await getUserPermission(
      "proms-group",
      getAccessTokenSilently
    );
    const permissionReadiness = await getUserPermission(
      "readiness-table-group",
      getAccessTokenSilently
    );

    setReadinessIsEnabled(readinessTable && permissionReadiness);
    setIsFederation(permissionFederationGroups);
    setPermissionProms(permissionProms);
    setPermissionInjurySurveillance(permissionSurveillance);
    setGroupSituation(permissionGroupSituation);
    setIsFederation(permissionFederationGroups);
    setGroupStatisticPermission(tempGroupStatisticPermission);

    if (
      (permissionProms ||
        (readinessIsEnabled && !isFederation) ||
        permissionInjurySurveillance) &&
      !notFederationRoutes.find(({ id }) => id === "monitoring")
    ) {
      notFederationRoutes.push({
        routeSelected: routesGroup.monitoring,
        label: labels.group.sideBarGroup.monitoring,
        id: "monitoring",
      });
    }

    if (
      (permissionControlRoom ||
        (groupStatistic && tempGroupStatisticPermission) ||
        groupAnalytics ||
        groupStatisticAnalytics ||
        permissionSurveillance) &&
      !notFederationRoutes.find(({ id }) => id === "controlRoom")
    ) {
      notFederationRoutes.push({
        routeSelected: routesGroup.controlRoom,
        label: "Control Room",
        id: "controlRoom",
      });
    }

    if (
      permissionGroupSituation &&
      !notFederationRoutes.find(({ id }) => id === "situation")
    ) {
      notFederationRoutes.unshift({
        routeSelected: routesGroup.situation,
        label: labels.group.sideBarGroup.status,
        id: "situation",
      });
    }

    /* if (
      permissionProms &&
      !notFederationRoutes.find(({ id }) => id === "proms")
    ) {
      notFederationRoutes.push({
        routeSelected: routesGroup.proms,
        label: labels.group.sideBarGroup.proms,
        id: "proms",
      });
    } */

    //setInjurySuerveillancePermission(permissionSurveillance);
  };

  useEffect(() => {
    fetchData();
  }, [readinessTable, groupStatistic, groupAnalytics, groupStatisticAnalytics]);

  const getTextVariant = (permission, path) => {
    if (!permission) return "disable";
    if (location.pathname !== path) return `sidebarItem`;
  };

  const removePatient = async () => {
    try {
      const response = await deletePatient({ id_patient: parseInt(groupId) });
      setOpenDeletePatient(false);
      if (response?.error) {
        props.snackbarShowErrorMessage(response?.error);
      } else {
        props.snackbarShowMessage(response?.message);
        history.push(`/patients`);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  const RouteLabel = ({ routeSelected, label, id }) => (
    <Grid item xs={12}>
      <SpIconAndTextButton
        id={id}
        textVariant={getTextVariant(
          true,
          getGroupSidebarRoute(routeSelected, groupId)
        )}
        onClick={() =>
          history.push(getGroupSidebarRoute(routeSelected, groupId))
        }
        text={label.toUpperCase()}
      />
    </Grid>
  );

  return (
    <>
      <Grid item xs={12}>
        <TitleToolbarSection>
          <ArrowBack
            id="arrowBack"
            onClick={() => history.push("/groups")}
            style={{
              width: 15,
              color: theme.colors.primary.lightBlue,
              height: 15,
              cursor: "pointer",
            }}
          />
          <SpText
            variant="h4ComponentLabelColored"
            style={{ marginRight: "5px" }}
          >{`${
            isFederation
              ? labels.groups.toolbar.backFederation
              : labels.groups.toolbar.back
          } /`}</SpText>

          <SpText
            onClick={() => history.push("/groups")}
            variant="h4ComponentLabel"
          >{` ${currentGroup?.name}`}</SpText>
          <SpIconButtonMenu onMouseEnter={() => setIsDrawerOpen(true)}>
            <MenuIcon style={{ color: "#31caad" }} id="groupMenu" />
          </SpIconButtonMenu>
        </TitleToolbarSection>
      </Grid>

      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        SlideProps={{
          timeout: { enter: 300, exit: 200 },
        }}
      >
        <SidebarNavColumn>
          <Grid
            container
            xs={12}
            style={{ alignContent: "center" }}
            direction="row"
          >
            <Grid
              item
              xs={12}
              style={{ paddingLeft: "4em", paddingTop: "1em" }}
            >
              <LogoMenu id={"logoImage"} src={LogoUrl} />
            </Grid>
          </Grid>
          {!isFederation &&
            notFederationRoutes.map(({ routeSelected, label, id }) => (
              <RouteLabel routeSelected={routeSelected} label={label} id={id} />
            ))}

          {isFederation &&
            federationRoutes.map(({ routeSelected, label, id }) => (
              <RouteLabel routeSelected={routeSelected} label={label} id={id} />
            ))}

          {/* {readinessIsEnabled && !isFederation && (
            <RouteLabel
              routeSelected={routesGroup.readiness}
              label={labels.group.sideBarGroup.readiness}
              id={"navReadiness"}
            />
          )}

          {injurySurveillancePermission && (
            <RouteLabel
              routeSelected={routesGroup.availability}
              label={labels.group.sideBarGroup.availability}
              id={"navAvailability"}
            />
          )} */}

          {!isFederation && (
            <RouteLabel
              routeSelected={routesGroup.general}
              label={labels.group.sideBarGroup.general}
              id={"general"}
            />
          )}

          {isFederation && (
            <RouteLabel
              routeSelected={routesGroup.federationsGeneral}
              label={labels.group.sideBarGroup.general}
              id={"general"}
            />
          )}
        </SidebarNavColumn>
      </Drawer>

      <SpDialog
        style={{ padding: "1%" }}
        open={openDeletePatient}
        setOpen={setOpenDeletePatient}
        title={labels.patient.sideBarPatEdit.deletePatientDialogTitle}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <SpText id={"dialogContent"} variant="text">
              {labels.patient.sideBarPatEdit.deletePatientDialogContent}
            </SpText>
          </Grid>
          <Grid item container xs={12} alignItems={"flex-start"}>
            <Grid item xs={4}>
              <SpButton
                id={"dialogButtonYes"}
                text={labels.groups.groupDetail.deletePatientDialog.yes}
                buttonType="accept"
                onClick={removePatient}
              />
            </Grid>
            <Grid item xs={4} style={{ marginBottom: "1%" }}>
              <SpButton
                id={"dialogButtonNo"}
                text={labels.groups.groupDetail.deletePatientDialog.no}
                buttonType="accept"
                onClick={() => setOpenDeletePatient(false)}
              />
            </Grid>
          </Grid>
        </Grid>
      </SpDialog>
    </>
  );
};

export default GroupEditSidebar;
