import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SpDataGrid from "../../../components/atoms/SpDataGrid";
import { theme } from "../../../components/theme";
import { labels, psTranslate } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { downloadExcel, getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";

const PATIENT_KEY = "patient";
const MAX_DECIMALS = 2;

const StatisticsTable = ({
  data,
  currStudyParameters,
  tableData,
  studyParameters,
  currSecondTableExtras,
  extraFunctionKeys,
  currSecondTableCategories,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [isPatientLabel, setIsPatientLabel] = useState(false);

  const fetchData = async () => {
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );
    setIsPatientLabel(patientLabelTemp);
  };

  const TableRow = ({ data, maxValues }) => {
    let fillPercentage = 100;
    if (maxValues) {
      fillPercentage = Math.floor((data.value / maxValues[data.field]) * 100);
      fillPercentage = isNaN(fillPercentage) ? 0 : fillPercentage;
    }
    //A simple antialiasing trick
    const antialisingIncrease = fillPercentage
      ? Math.min(2, 0.1 * (Object.keys(maxValues ?? {}).length + 1))
      : 0;

    //Create data row
    const value = data.value;
    return (
      <div
        style={{
          border: `1px solid ${theme.colors.primary.lightBlue}`,
          height: 32,
          minWidth: "100%",
        }}
      >
        <div
          style={{
            background: `linear-gradient(70deg, ${
              theme.colors.primary.lightBlue
            } ${fillPercentage}%, ${theme.colors.primary.black} ${
              fillPercentage + antialisingIncrease
            }%)`,
            height: 30,
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: theme.colors.primary.white,
              fontWeight: 800,
              fontSize: "1.2rem",
              display: "flex",
            }}
          >
            {value}
          </span>
        </div>
      </div>
    );
  };

  const createColumnHeaders = () => {
    //Prepare headers
    const headers = currStudyParameters.map(
      (item) =>
        `${psTranslate(studyParameters.find(({ id }) => id == item)?.name)}`
    );

    const newData = [];
    newData.push({
      id: PATIENT_KEY,
      field: PATIENT_KEY,
      headerName: labels.groups.groupReports.firstTable.header.athlete,
      editable: false,
      width: 200,
      renderCell: (params) => <TableRow data={params} />,
    });
    newData.push(
      ...headers.flatMap((header) => {
        return [...currSecondTableCategories]
          .map((categ) => {
            return {
              id: `${header} ${categ}`,
              field: `${header} ${categ}`,
              headerName: `${header} ${categ}`,
              editable: false,
              minWidth: 100,
              maxWidth: 250,
              flex: 1,
              renderCell: (params) => <TableRow data={params} />,
            };
          })
          .flat();
      })
    );
    return newData;
  };

  function aggregateFeedbacks(data) {
    try {
      const aggr = data.map((entry) => {
        let feedbackSummary = {};

        // Prendiamo tutti i parametri distinti presenti nei feedback
        const parameters = [
          ...new Set(entry.feedbacks.map((f) => f.parameter)),
        ];

        parameters.forEach((parameter) => {
          [...currSecondTableCategories].forEach((categ) => {
            if (entry.feedbacks?.length > 0) {
              let feedbackItem = null;
              const filteredFeedbacks = entry.feedbacks.filter(
                (f) => f.parameter === parameter
              );

              if (categ === "latest") {
                feedbackItem = filteredFeedbacks
                  .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                  .find(
                    (f) => f.value !== null && f.value !== "" && !isNaN(f.value)
                  );
              } else if (categ === "biggest") {
                const validFeedbacks = filteredFeedbacks.filter(
                  (f) => f.value !== null
                );
                if (validFeedbacks.length > 0) {
                  feedbackItem = validFeedbacks.reduce((max, current) =>
                    parseFloat(current.value) > parseFloat(max.value)
                      ? current
                      : max
                  );
                }
              } else if (categ === "smallest") {
                const validFeedbacks = filteredFeedbacks.filter(
                  (f) => f.value !== null
                );
                if (validFeedbacks.length > 0) {
                  feedbackItem = validFeedbacks.reduce((min, current) =>
                    parseFloat(current.value) < parseFloat(min.value)
                      ? current
                      : min
                  );
                }
              } else if (categ === "average") {
                const validFeedbacks = filteredFeedbacks.filter(
                  (f) => f.value !== null && f.value !== "" && !isNaN(f.value)
                );
                if (validFeedbacks.length > 0) {
                  const avgValue =
                    validFeedbacks.reduce(
                      (sum, current) => sum + parseFloat(current.value),
                      0
                    ) / validFeedbacks.length;
                  feedbackItem = {
                    parameter: parameter,
                    value: avgValue.toFixed(2),
                    startDate: moment(),
                  };
                }
              } else {
                feedbackItem = filteredFeedbacks[filteredFeedbacks.length - 1];
              }

              if (feedbackItem) {
                const key = `${parameter} ${categ}`;
                const valueWithDate = `${feedbackItem.value} (${moment(
                  feedbackItem.startDate,
                  "YYYY-MM-DD"
                ).format("DD/MM/YYYY")})`;
                feedbackSummary[key] = valueWithDate;
              }
            }
          });
        });

        return {
          id: entry.athlete,
          patient: entry.athlete,
          ...feedbackSummary,
        };
      });
      return aggr;
    } catch (error) {
      console.log("error");
    }
  }

  const extractFeedbackByParameter = (data, id_feedback_parameters) => {
    return data
      .map((calendarActivity) => {
        const patientName = `${calendarActivity.patient.givenName} ${calendarActivity.patient.familyName}`;
        let feedbacks = [];

        // Scansiona tutti i tipi di attività
        calendarActivity?.activity_types?.forEach((activityType) => {
          activityType?.activities?.forEach((activity) => {
            const startDate = activity.start_date;
            const startTime = activity.start_time;
            const endTime = activity.end_time;
            activity?.activity_feedbacks?.forEach((feedback) => {
              feedback?.activity_resp_parameter_feedbacks?.forEach(
                (paramFeedback) => {
                  if (
                    id_feedback_parameters?.includes(
                      paramFeedback?.feedback_parameter?.id
                    )
                  ) {
                    feedbacks.push({
                      parameter: paramFeedback.feedback_parameter.name,
                      value: paramFeedback.answer,
                      startDate,
                      startTime,
                      endTime,
                    });
                  }
                }
              );
            });
          });
        });

        return {
          athlete: patientName,
          feedbacks,
        };
      })
      .filter((entry) => entry.feedbacks.length > 0); // Rimuove gli atleti senza feedback rilevanti
  };

  const createRowsData = () => {
    let prova = extractFeedbackByParameter(tableData, currStudyParameters);
    return aggregateFeedbacks(prova);
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Grid item xs={12} style={{ marginTop: "2%" }}>
      <SpDataGrid headers={createColumnHeaders()} rows={createRowsData()} />

      {/* <SpButton
        style={{ maxWidth: "25%", marginBottom: "10px" }}
        onClick={() => {
          const row = createRowsData(data).map(
            ({ id, ...keepAttrs }) => keepAttrs
          );
          const objectToExcel = { StatisticsTable: row };
          downloadExcel(objectToExcel, "StatisticsTable");
        }}
        text={"Download excel"}
      /> */}
    </Grid>
  );
};

export default StatisticsTable;
