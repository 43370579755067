import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import "../../../App.css";
import Sp3DModel from "../../../components/atoms/Sp3DModel";
import SpButton from "../../../components/atoms/SpButton";
import PatientsAddSymbols from "../shared/PatientsAddSymbols";
import { labels } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { getUserPermission } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";

const PatientsMonitoringStep1 = (props) => {
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [isShowingUpdateSymbol, setIsShowingUpdateSymbol] = useState(false);
  const [permissionPatientMenu, setPermissionPatientMenu] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { setLoading } = props;

  useEffect(async () => {
    setLoading(true);
    localStorage.removeItem("pdfScreenshot");
    const tempPermissionPatientMenu = await getUserPermission(
      "patient-menu",
      getAccessTokenSilently
    );

    setPermissionPatientMenu(tempPermissionPatientMenu);
    setLoading(false);
  }, []);

  const monitoring = (
    <>
      <Grid container direction="column">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Grid
            item
            container
            xs={12}
            style={{
              alignSelf: "flex-start",
            }}
          >
            <SpText variant="h1PageTitle" style={{ paddingLeft: "2%" }}>
              {labels.patient.patientRegistry.steps[0].value.toUpperCase()}
            </SpText>
          </Grid>
          <Grid
            item
            xs={5}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {permissionPatientMenu && (
              <SpButton
                text={labels.general.updateBodymap}
                style={{ width: "100%" }}
                buttonType="accept"
                onClick={() => setIsShowingUpdateSymbol(true)}
              />
            )}
          </Grid>
        </div>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ minHeight: 400 }}>
          <Sp3DModel
            modelBehaviour="MARKER"
            type="PATIENT"
            setSelectedRegion={setSelectedRegion}
            setSelectedArea={setSelectedArea}
            selectedRegion={selectedRegion}
            selectedArea={selectedArea}
          />
        </Grid>
      </Grid>
    </>
  );
  const addSymbol = (
    <PatientsAddSymbols
      setLoading={setLoading}
      onBackPressed={() => setIsShowingUpdateSymbol(false)}
    />
  );

  return isShowingUpdateSymbol ? addSymbol : monitoring;
};

export default PatientsMonitoringStep1;
