import { Grid } from "@material-ui/core";
import moment from "moment-timezone";
import React, { Children, useEffect, useState, createRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import "../../components/atoms/App.css";
import SpButton from "../../components/atoms/SpButton";
import SpDialog from "../../components/atoms/SpDialog";
import SpCalendarDragAndDropItems from "../../components/atoms/SpDragAndDropCalendarItems";
import { SpSelect, SpSelectMenuItem } from "../../components/atoms/SpSelect";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import SpText from "../../components/atoms/SpText";
import SpTextArea from "../../components/atoms/SpTextArea";
import SpTextInput from "../../components/atoms/SpTextInput";
import {
  formatDateTimeToLocal,
  getCalendarEventsDates,
  getDateFormat,
  getUserPermission,
  titleCase,
} from "../../utils/common";

import {
  getActivityPlanTemplateById,
  getAllActivitiesTypes,
  getAllActivityPlanTemplates,
  getAllAssessmentTemplates,
  getAllFeedbackParameters,
  getAllProtocolTemplates,
  getAllRehabTemplates,
  getAllTrainingTemplates,
  getDefaultParameter,
} from "../../models/actions/Activity";
import {
  createCalendarActivityMonitoring,
  getAllCalendarActivityByMonitoring,
  getCalendarActivityById,
  updateCalendarActivityMonitoring,
} from "../../models/actions/CalendarActivity";
import { getExerciseTemplates } from "../../models/actions/Excercise";
import { findPatientByPk } from "../../models/actions/Patients";
import { getPresentationsByPatProf } from "../../models/actions/Presentation";
import { getLanguage, labels, psTranslate } from "../shared/translations";
import AgendaCalendarEventCard from "../agenda/AgendaCalendarEventCard";
import A1_AssessmentInputDetails from "../agenda/inputDetails/A1_AssessmentInputDetails";
import A2_TreatmentInputDetails from "../agenda/inputDetails/A2_TreatmentInputDetails";
import A3_ExerciseInputDetails from "../agenda/inputDetails/A3_ExerciseInputDetails";
import A4_TrainingInputDetails from "../agenda/inputDetails/A4_TrainingInputDetails";
import A5_RestInputDetails from "../agenda/inputDetails/A5_RestInputDetails";
import A6_OtherInputDetails from "../agenda/inputDetails/A6_OtherInputDetails";
import A7_RaceInputDetails from "../agenda/inputDetails/A7_RaceInputDetails";
import A8_RehabilitationInputDetails from "../agenda/inputDetails/A8_RehabilitationInputDetails";
import A9_SupplementDetails from "../agenda/inputDetails/A9_SupplementDetails";
import ColoredEventCell from "../agenda/ColoredEventCell";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { rollbar, momentDefinition } from "../../utils/common";
import printContext from "../../utils/printContext";

import PatientsReportsPDF from "../patients/patientsReports/PatientsReportsPDF";
import ColoredEventCellWeek from "../agenda/ColoredEventCellWeek";
import ColoredEventCellDay from "../agenda/ColoredEventCellDay";
import { useAuth0 } from "@auth0/auth0-react";

const INPUT_DETAILS = [
  null, //IDb indexes start from 1
  A1_AssessmentInputDetails,
  A2_TreatmentInputDetails,
  A3_ExerciseInputDetails,
  A4_TrainingInputDetails,
  A5_RestInputDetails,
  A6_OtherInputDetails,
  A7_RaceInputDetails,
  A8_RehabilitationInputDetails,
  A9_SupplementDetails,
];
const DragAndDropCalendar = withDragAndDrop(Calendar);

const PatientsPlan = ({ setLoading, ...props }) => {
  const [openAddActivity, setOpenAddActivity] = useState(false);
  const [assessmentTemplateSelected, setAssessmentTemplateSelected] =
    useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [openDetailActivity, setOpenDetailActivity] = useState(false);
  const [loadParametersData, setLoadParametersData] = useState([]);
  const [loadParametersList, setLoadParametersList] = useState([]);
  const [eventDetail, setEventDetail] = useState();
  const [eventsList, setEventsList] = useState([]);
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [activityFormError, setActivityFormError] = useState(true);
  const [presentations, setPresentations] = useState([]);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [displayDragItemInCell, setDisplayDragItemInCell] = useState(true);
  const [templatesList, setTemplatesList] = useState([]);
  const [templateHelper, setTemplateHelper] = useState(false);
  const [activityPlanTemplatesList, setActivityPlanTemplatesList] = useState(
    []
  );
  const [isPrint, setIsPrint] = useState(false);
  const [isPrintAddActivity, setIsPrintAddActivity] = useState(false);

  const [fetchDataBe, setFetchDataBe] = useState(false);

  const [error, setError] = useState("");
  const [pdfFeatureFlag, setPdfFeatureFlag] = useState(false);
  const [viewSelected, setViewSelected] = useState("month");

  const { patId } = useParams();
  const history = useHistory();

  moment.locale(getLanguage(), momentDefinition);
  const localizer = momentLocalizer(moment);

  const reference = {};
  const [selectedPDF, setSelectedPDF] = useState([]);
  const [indexPDF, setIndexPDF] = useState(0);
  const [monthSelected, setMonthSelected] = useState(moment());
  const [subactivities, setSubactivities] = useState([]);

  //React hook form
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    errors,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  useEffect(async () => {
    if (!draggedEvent) {
      setValue("id_activity_type", null);
    } else {
      setDraggedEvent(null);
    }
  }, [openAddActivity]);

  const createReference = () => {
    reference[indexPDF] = createRef(null);
    return reference;
  };
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (!openDetailActivity) resetEventDetail();
  }, [openDetailActivity]);

  const resetEventDetail = () => {
    setEventDetail(null);
    setLoadParametersList([]);
    setSubactivities([]);
    setLoadParametersData([]);
  };

  const fetchDataInitial = async () => {
    localStorage.removeItem("pdfScreenshot");
    try {
      const [activityTypeResponse, feedbackParametersList, templatesFound] =
        await Promise.all([
          getAllActivitiesTypes(getAccessTokenSilently),
          getAllFeedbackParameters(),
          getAllActivityPlanTemplates(),
        ]);
      setActivityTypeList(activityTypeResponse);

      if (eventDetail) {
        const res = await getCalendarActivityById({
          id: eventDetail.id,
        });
        if (res.patients.length > 0) {
          if (
            Array.isArray(res.patients[0]?.patientFeedbacks) &&
            res.patients[0].patientFeedbacks.length > 0
          ) {
            const temp = res.patients[0].patientFeedbacks?.map(
              (el) => el.feedback_parameter
            );

            setLoadParametersList(temp);
          }
        }
      } else {
        const resultDefaultParameter = await getDefaultParameter();

        if (
          getValues("id_activity_type") ==
          activityTypeResponse.find(({ key }) => key == "treatment")?.id
        ) {
          const tempFeedTreatment = feedbackParametersList.filter(
            ({ key }) => key == "approvalrating"
          );
          setLoadParametersList(tempFeedTreatment);
        } else {
          setLoadParametersList(resultDefaultParameter);
        }
      }

      feedbackParametersList && setLoadParametersData(feedbackParametersList);
      templatesFound && setActivityPlanTemplatesList(templatesFound);
    } catch (error) {
      rollbar.error("PatientsPlan - fetchDataInitial", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const fetchData = async () => {
    try {
      const { startOfMonth, endOfMonth } = getCalendarEventsDates({
        monthSelected,
      });
      const gpsDeviceTemp = await getUserPermission(
        "manage:gps-device",
        getAccessTokenSilently
      );
      const responseEvents = await getAllCalendarActivityByMonitoring({
        id_patient: patId,
        start_date: startOfMonth,
        end_date: endOfMonth,
        hide_feedbacks: gpsDeviceTemp ? true : false,
      });
      Rollbar.configure({
        checkIgnore: () => false,
      });

      Rollbar.configure({
        checkIgnore: () => true,
      });
      const newEvents = updateEventsList(responseEvents);

      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
      const response = await getPresentationsByPatProf({ id_patient: patId });

      let newResponseArray = [
        { id: -1, name: labels.patient.patientPlan.defaultSelectValue },
      ];
      response.map((elem) => {
        if (elem.region) {
          newResponseArray.push({
            id: elem?.id,
            name:
              (elem.pathology ? elem.pathology?.name : elem.type) +
              " " +
              elem.region?.name.toLowerCase(),
          });
        }
      });
      setPresentations(newResponseArray);
      return newEvents;
    } catch (error) {
      rollbar.error("PatientsPlan - fetchData", error);
      props.snackbarShowErrorMessage(error);
    }
    return eventsList;
  };

  const getSubactivities = () => {
    let acitivitySelected;
    if (activityTypeList)
      acitivitySelected = activityTypeList.find(
        ({ id }) => id == getValues("id_activity_type")
      );

    if (
      acitivitySelected &&
      !["rehabilitation", "supplement"].includes(acitivitySelected.key)
    )
      return (
        <Grid item xs={6}>
          <Controller
            render={(props) => (
              <SpSelect
                id={`${acitivitySelected.key}_subactivities`}
                label={labels.patient.patientPlan.selectSubactivity}
                value={props.value}
                formControlWidth={"100%"}
                selectPlaceholder={labels.patient.patientPlan.selectSubactivity}
                onChange={(e) => {
                  props.onChange(e.target.value);
                }}
                isPrint={isPrintAddActivity}
              >
                {activityTypeList
                  .find(({ id }) => id == getValues("id_activity_type"))
                  ?.subactivities.map(({ id, name }) => (
                    <SpSelectMenuItem key={id} value={id}>
                      {psTranslate(name)}
                    </SpSelectMenuItem>
                  ))}
              </SpSelect>
            )}
            defaultValue={""}
            name={`id_subactivity`}
            control={control}
          />
        </Grid>
      );

    return <></>;
  };

  const dateHeader = ({ date, label }) => {
    if (moment(monthSelected).format("MM") === moment(date).format("MM")) {
      return <h3 style={{ color: "lightGray" }}>{label}</h3>;
    } else {
      return <h3 style={{ color: "gray" }}>{label}</h3>;
    }
  };

  useEffect(async () => {
    if (fetchDataBe) {
      await fetchData();
      setIsPrint(true);
      setFetchDataBe(false);
    }
  }, [fetchDataBe]);

  const MyCustomHeader = ({ label }) => (
    <div style={{ width: "100%", backgroundColor: "#333333", color: "#FFF" }}>
      <div style={{ color: "#FFF" }}>{label}</div>
    </div>
  );

  const handleNewEventSelected = (event) => {
    setLoadParametersList([]);
    setValue("quantity_treatment", 0);
    setOpenAddActivity(true);
    setOpenDetailActivity(false);
    setValue("start_date", moment(event.start).format("YYYY-MM-DD"));
  };

  const handleEventDetailSelected = (event) => {
    setValue("start_time", event.start_time);
    setValue("end_time", event.end_time);
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == event.id_activity_type
    )?.subactivities;
    setSubactivities(tempSubactivities);
    setValue("id_activity_type", event.id_activity_type);
    setLoadParametersList(
      event.feedbacks?.map((feedback) => feedback.feedback_parameter) ?? []
    );
    setEventDetail(event);
    setOpenDetailActivity(true);
  };

  const updateEventsList = (eventsListData) => {
    const newEventsList = [];

    eventsListData.map((event, eventIdx) => {
      const dateStartLocal = formatDateTimeToLocal({
        date: event?.start_date,
        time: event?.start_time,
      });
      const dateEndLocal = formatDateTimeToLocal({
        date: event?.start_date,
        time: event?.end_time,
      });
      newEventsList.push({
        id: event?.id,
        title: event?.activity_datum?.activity_type?.name,
        description: "",
        start: getDateFormat(
          moment(dateStartLocal).format("YYYY-MM-DD"),
          moment(dateStartLocal).format("HH:mm:ss")
        ),
        end: getDateFormat(
          moment(dateStartLocal).format("YYYY-MM-DD"),
          moment(dateEndLocal).format("HH:mm:ss")
        ),
        start_time: moment(dateStartLocal).format("HH:mm:ss"),
        end_time: moment(dateEndLocal).format("HH:mm:ss"),
        type: event?.activity_datum?.name,
        event_note: event?.event_note,
        id_presentation: event?.id_presentation,
        id_subactivity: event?.activity_datum?.id_subactivity,
        presentation: event?.presentation,
        id_activity_type: event?.activity_datum?.activity_type?.id,
        media: event?.activity_datum?.activity_type?.key
          ? require(`../../assets/activities/${event?.activity_datum?.activity_type?.key}.png`)
          : null,
        color: event?.color,
        data: event?.activity_datum?.data,
        feedbacks: event?.activity_feedbacks[0]
          ?.activity_resp_parameter_feedbacks
          ? event.activity_feedbacks[0].activity_resp_parameter_feedbacks
          : [],
        groupName: event?.groupName,
        archiving_status: event?.archiving_status,
        is_from_group_plan: event?.is_from_group_plan,
        id_activity_groups: event?.id_activity_groups,
      });
    });

    setEventsList(newEventsList);
    return newEventsList;
  };

  //rendering custom card
  const AgendaEventWrapper = (props) => {
    return React.cloneElement(Children.only(props.children), {
      style: {
        ...props.children.props.style,
        width: "100%",
        backgroundColor: props.event.color,
        padding: `8px`,
        color: "white !important",
      },
    });
  };

  const goToBackGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() - 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() - 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("PatientsPlan - goToBackGeneral", error);
    }
  };
  const goToNextGeneral = (toolbar) => {
    try {
      let newDate = toolbar.date;

      switch (toolbar.view) {
        case "month":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth() + 1,
            newDate.getDate()
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "week":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 7
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;
        case "day":
          newDate = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate() + 1
          );
          toolbar.onNavigate("prev", newDate);
          setMonthSelected(newDate);
          break;

        default:
          break;
      }
    } catch (error) {
      rollbar.error("PatientsPlan - goToNextGeneral", error);
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = () => {
      goToBackGeneral(toolbar);
    };

    const goToNext = () => {
      goToNextGeneral(toolbar);
    };

    const goToCurrent = () => {
      const now = new Date();
      toolbar.onNavigate("current", now);
      setMonthSelected(now);
    };

    const label = () => {
      const date = moment(toolbar.date);
      return (
        <SpText variant="monthYearCalendar">
          {toolbar.view === "day" && (
            <>
              <b>{date.format("DD")}</b>{" "}
            </>
          )}
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </SpText>
      );
    };

    return (
      <div
        container
        className="rbc-toolbar"
        spacing={1}
        style={{ marginBottom: "1%", justifyContent: "space-evenly" }}
      >
        <label style={{ justifyContent: "center" }} className={"label-date"}>
          {label()}
        </label>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF" }}
            className="btn-back"
            onClick={() => {
              setViewSelected("month");
            }}
          >
            {labels.groups.plan.month}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-current"
            onClick={() => {
              setViewSelected("week");
            }}
          >
            {labels.groups.plan.week}
          </button>
          <button
            style={{ color: "#FFF" }}
            className="btn-next"
            onClick={() => {
              setViewSelected("day");
            }}
          >
            {labels.groups.plan.day}
          </button>
        </div>

        <div className="back-next-buttons">
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-back"
            onClick={goToBack}
          >
            {labels.groups.plan.prev}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-current"
            onClick={goToCurrent}
          >
            {labels.groups.plan.today}
          </button>
          <button
            style={{ color: "#FFF", borderColor: "#FFF" }}
            className="btn-next"
            onClick={goToNext}
          >
            {labels.groups.plan.next}
          </button>
        </div>
      </div>
    );
  };

  const handleDragStart = (event) => {
    setDraggedEvent(event);
    setDisplayDragItemInCell(false);
  };

  const dragFromOutsideItem = () => {
    return draggedEvent;
  };

  const onDropFromOutside = async ({ start, end, allDay }) => {
    const event = {
      id: draggedEvent?.id,
      title: draggedEvent.title,
      start: new Date(start),
      end: new Date(start),
      allDay: allDay,
    };

    await handleEventDropFromOutside({ draggedEvent, event, start, end });
    setDraggedEvent(null);
  };

  const handleEventDropFromOutside = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    const tempSubactivities = activityTypeList?.find(
      ({ id }) => id == draggedEvent?.id
    )?.subactivities;
    setSubactivities(tempSubactivities);

    setValue("id_activity_type", draggedEvent?.id);
    setValue("start_date", moment(start).format("YYYY-MM-DD"));
    setOpenAddActivity(true);
    setDisplayDragItemInCell(true);
  };

  const handleEventDrop = async ({
    event,
    start,
    end,
    isAllDay: droppedOnAllDaySlot,
  }) => {
    let allDay = event.allDay;
    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }
    const nextEvents = eventsList.map((existingEvent) => {
      return existingEvent?.id === event?.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });
    event.start_date = moment(start);
    event.end_date = moment(end);
    await updateActivity(event);
    setEventsList(nextEvents);
  };

  const updateActivity = async (data) => {
    if (data.start_time && data.end_time) {
      const date = moment();
      const start_time = moment(data.start_time, "HH:mm:ss");
      const end_time = moment(data.end_time, "HH:mm:ss");
      date.set({
        hour: start_time.format("HH"),
        minute: start_time.format("mm"),
      });
      data.start_time = moment(date).utc().format("HH:mm");
      date.set({
        hour: end_time.format("HH"),
        minute: end_time.format("mm"),
      });
      data.end_time = moment(date).utc().format("HH:mm");
    }

    try {
      if (data.id_presentation == -1) delete data.id_presentation;
      const updatedActivity = await updateCalendarActivityMonitoring({
        id_subactivity: getValues("id_subactivity"),
        ...data,
        id_patient: patId,
      });
      if (!updatedActivity.error) {
        props.snackbarShowMessage(updatedActivity.message);
        resetFields();
      } else {
        props.snackbarShowErrorMessage(updatedActivity.error);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - updateActivity", error);
      props.snackbarShowErrorMessage(error);
    }
  };

  const createActivityObject = (data, id_activity_type) => {
    switch (id_activity_type) {
      case 1:
        data.details_data = {
          assessment_motivation: data.assessment_motivation,
          id_template: data?.id_template,
        };
        break;
      case 2:
        data.details_data = {
          name: data.name,
          treatments: data.treatments,
          treatments_motivation: data.treatments_motivation,
        };
        data.feedback_parameter = loadParametersList.filter(
          ({ key }) => key == "approvalrating"
        );
        break;
      case 3:
        data.details_data = {
          name: data.name,
          excercises: data.excercises,
          excercises_motivation: data.excercises_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 4:
        data.details_data = {
          name: data.name,
          training: data.training,
          training_motivation: data.training_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 5:
        data.details_data = { rest_motivation: data.rest_motivation };
        data.feedback_parameter = loadParametersList;
        break;
      case 6:
        data.details_data = {
          appointment_motivation: data.appointment_motivation,
        };
        break;
      case 7:
        data.details_data = {
          race_motivation: data.race_motivation,
          race_minutes: data.race_minutes,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 8:
        data.details_data = {
          name: data.name,
          rehabilitations: data.rehabilitations,
          rehabilitation_motivation: data.rehabilitation_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      case 9:
        data.details_data = {
          name: data.name,
          supplements: data.supplements,
          supplement_motivation: data.supplement_motivation,
        };
        data.feedback_parameter = loadParametersList;
        break;
      default:
        break;
    }
    return data;
  };

  const createActivity = async (data) => {
    try {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const id_activity_template = getValues("id_activity_template");
      const id_activity_type = getValues("id_activity_type");
      const id_subactivity = getValues("id_subactivity");
      let createdActivity;

      if (id_activity_template) {
        const responseDetail = await getActivityPlanTemplateById({
          id_template: id_activity_template,
        });
        let zeroDate = new Date("1900-01-01").getTime();
        let startDate = new Date(data.start_date).getTime();
        let endDate = new Date(data.end_date).getTime();
        await Promise.all(
          responseDetail.calendar_activities.map(async (activity) => {
            //Adding the progressive days to the current activity
            //start date progressive
            let activityStartDate = new Date(activity.start_date);
            activityStartDate.setTime(
              activityStartDate.getTime() + startDate - zeroDate
            );
            //end date progressive
            let activityEndDate = new Date(activity.end_date);
            activityEndDate.setTime(
              activityEndDate.getTime() + endDate - zeroDate
            );
            activity.start_date = activityStartDate;
            activity.end_date = activityEndDate;
            activity.start_time = data.start_time;
            activity.end_time = data.end_time;
            activity.fl_template = null;
            activity.id_activity_type =
              activity.activity_datum.id_activity_type;
            activity.id_subactivity = id_subactivity;
            activity.details_data = activity.activity_datum?.data;
            activity.feedback_parameter =
              activity.activity_feedbacks?.[0]?.activity_resp_parameter_feedbacks?.map(
                (el) => el.feedback_parameter
              );
            Rollbar.configure({
              checkIgnore: () => false,
            });
            rollbar.info(
              " PatientsPlan: - createActivity",
              JSON.stringify({
                ...activity,
                id_patient: patId,
              })
            );
            Rollbar.configure({
              checkIgnore: () => true,
            });
            createdActivity = await createCalendarActivityMonitoring({
              is_from_group_plan: patId ? false : true,
              ...activity,
              id_patient: patId,
            });
          })
        );
      } else {
        const date = moment(getValues("start_date"), "YYYY-MM-DD");
        const start_time = moment(data.start_time, "HH:mm:ss");
        const end_time = moment(data.end_time, "HH:mm:ss");
        date.set({
          hour: start_time.format("HH"),
          minute: start_time.format("mm"),
        });
        data.start_time = moment(date).utc().format("HH:mm");
        date.set({
          hour: end_time.format("HH"),
          minute: end_time.format("mm"),
        });
        data.end_time = moment(date).utc().format("HH:mm");
        data.start_date = moment(date).format("YYYY-MM-DD");
        data = createActivityObject(data, id_activity_type);

        Rollbar.configure({
          checkIgnore: () => false,
        });
        rollbar.info(
          " PatientsPlan: - createActivity",
          JSON.stringify({
            ...data,
            id_patient: patId,
            is_from_agenda: 0,
          })
        );
        Rollbar.configure({
          checkIgnore: () => true,
        });

        createdActivity = await createCalendarActivityMonitoring({
          ...data,
          is_from_group_plan: patId ? false : true,
          id_patient: patId,
          is_from_agenda: 0,
        });
      }

      //Check errors
      if (!createdActivity.error) {
        props.snackbarShowMessage(createdActivity.message);
        const newEvents = await resetFields();
        return [createdActivity.id, newEvents];
      } else {
        props.snackbarShowErrorMessage(createdActivity.error);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - createActivity", error);
      props.snackbarShowErrorMessage(error);
    }
    return null;
  };

  const resetFields = () => {
    reset();
    setOpenAddActivity(false);
    setAssessmentTemplateSelected(false);
    setLoadParametersList([]);
    return fetchData();
  };

  //method to render input details based on the type of activity selected
  const renderInputDetails = () => {
    const Detail = INPUT_DETAILS[getValues("id_activity_type")];
    return (
      Detail && (
        <Detail
          subactivities={subactivities}
          idActivityType={getValues("id_activity_type")}
          watchTemplateSelect={watchTemplateSelect}
          selectedTemplate={getValues("id_template")}
          loadParametersData={loadParametersData}
          loadParametersList={loadParametersList}
          setLoadParametersList={setLoadParametersList}
          control={control}
          setValue={setValue}
          getValues={getValues}
          register={register}
          isPrint={isPrintAddActivity}
        />
      )
    );
  };

  const watchStartTime = watch("start_time");
  const watchActivityType = watch("id_activity_type");
  const watchTemplateSelect = watch("id_template");
  const watchEndTime = watch("end_time");

  const fetchTemplateByActType = async () => {
    try {
      setValue("id_template", "");
      setAssessmentTemplateSelected(false);
      let templateData = [];
      switch (getValues("id_activity_type")) {
        case 1:
          templateData = await getAllAssessmentTemplates();
          break;
        case 2:
          templateData = await getAllProtocolTemplates();
          break;
        case 3:
          templateData = await getExerciseTemplates();
          break;
        case 4:
          templateData = await getAllTrainingTemplates();
          break;
        case 8:
          templateData = await getAllRehabTemplates();
          break;
        case 9:
          templateData = [];
          break;
        default:
          break;
      }
      if (templateData) {
        setTemplatesList(templateData);
      }
    } catch (error) {
      rollbar.error("PatientsPlan - fetchTemplateByActType", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [monthSelected]);

  useEffect(() => {
    fetchDataInitial();
  }, [watchActivityType]);

  useEffect(() => {
    if (getValues("start_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchStartTime]);

  useEffect(() => {
    if (getValues("end_time") < new Date("22:00")) {
      setActivityFormError(true);
    } else {
      setActivityFormError(false);
    }
  }, [watchEndTime]);

  useEffect(() => {
    fetchTemplateByActType();
  }, [watchActivityType]);

  return (
    <printContext.Provider value={{ isPrint, setIsPrint }}>
      <div>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid item xs={12}>
              <PatientsEditSidebar
                props={props}
                patId={patId}
                history={history}
                currentPatient={currentPatient}
              />
            </Grid>
            <Grid item container xs={12}>
              <SpText variant="h1PageTitle">
                {labels.patient.sideBarPatEdit.viewPlan.toUpperCase()}
              </SpText>
            </Grid>

            <SpCalendarDragAndDropItems
              activitiesAvailable={activityTypeList}
              handleDragStart={handleDragStart}
            />

            <Grid item xs={11} style={{ minHeight: "750px" }}>
              <div ref={createReference()}>
                {
                  <DragAndDropCalendar
                    selectable
                    step={30}
                    min={new Date(0, 0, 0, 5, 0, 0)}
                    max={new Date(0, 0, 0, 23, 0, 0)}
                    events={eventsList}
                    scrollToTime={new Date()}
                    defaultDate={new Date()}
                    view={viewSelected}
                    onEventDrop={handleEventDrop}
                    popup={true}
                    dragFromOutsideItem={
                      displayDragItemInCell ? dragFromOutsideItem : null
                    }
                    onDropFromOutside={onDropFromOutside}
                    handleDragStart={handleDragStart}
                    onSelectEvent={handleEventDetailSelected}
                    onSelectSlot={handleNewEventSelected}
                    draggableAccessor={(event) =>
                      !(
                        event.archiving_status ===
                          "completed_and_feedback_not_sent" ||
                        event.archiving_status === "completed_and_feedback_sent"
                      )
                    }
                    tooltipAccessor={(event) => {
                      const tooltip = Object.entries(event?.data ?? {}).find(
                        ([key]) => key.includes("_motivation")
                      )?.[1];
                      return tooltip ? tooltip : event.title;
                    }}
                    eventPropGetter={(event, start, end, isSelected) => {
                      const backgroundColor = event.color;
                      const style = {
                        backgroundColor: backgroundColor,
                        borderRadius: "5px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        border: "1px solid white",
                        display: "block",
                      };
                      return {
                        style: style,
                      };
                    }}
                    messages={{
                      showMore: (total) => (
                        <a
                          style={{ cursor: "pointer" }}
                          onMouseOver={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                        >
                          {`+${total} ${labels.groups.plan.viewMore}`}
                        </a>
                      ),
                    }}
                    components={{
                      timeGutterHeader: (props) => (
                        <div style={{ color: "red" }}>{props.children}</div>
                      ),
                      month: {
                        toolbar: (toolbar) => CustomToolbar(toolbar),

                        header: MyCustomHeader,
                        dateHeader: dateHeader,

                        event: ColoredEventCell,
                        eventWrapper: AgendaEventWrapper,
                      },
                      week: {
                        toolbar: (toolbar) => CustomToolbar(toolbar),

                        header: MyCustomHeader,
                        dateHeader: dateHeader,

                        event: ColoredEventCellWeek,
                        eventWrapper: AgendaEventWrapper,
                      },
                      day: {
                        toolbar: (toolbar) => CustomToolbar(toolbar),

                        header: MyCustomHeader,
                        dateHeader: dateHeader,

                        event: ColoredEventCellDay,
                        eventWrapper: AgendaEventWrapper,
                      },
                    }}
                    localizer={localizer}
                  />
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="row"
          container
          spacing={2}
        ></Grid>
        {/* -------------------------- */}
        {/* DIALOG FOR UPDATE ACTIVITY */}
        {/* -------------------------- */}{" "}
        {eventDetail && (
          <SpDialog
            variant="bigDialog"
            open={openDetailActivity}
            setOpen={setOpenDetailActivity}
            title={
              !eventDetail.is_from_group_plan
                ? titleCase(
                    `${currentPatient?.givenName} ${currentPatient?.familyName}`
                  )
                : eventDetail?.groupName !== ""
                ? titleCase(eventDetail?.groupName)
                : labels.mylab.tempAdd.activityPlan.dialog.groupActTitle
            }
          >
            <AgendaCalendarEventCard
              title={
                !eventDetail.is_from_group_plan
                  ? titleCase(
                      `${currentPatient?.givenName} ${currentPatient?.familyName}`
                    )
                  : eventDetail?.groupName !== ""
                  ? titleCase(eventDetail?.groupName)
                  : labels.mylab.tempAdd.activityPlan.dialog.groupActTitle
              }
              subactivities={subactivities}
              isPrint={isPrintAddActivity}
              event={eventDetail}
              fetchData={fetchData}
              setOpenDetailActivity={setOpenDetailActivity}
              updateActivity={updateActivity}
              openDetailActivity={openDetailActivity}
              createActivityObject={createActivityObject}
              loadParametersData={loadParametersData}
              loadParametersList={loadParametersList}
              setLoadParametersList={setLoadParametersList}
              presentations={presentations}
              setValue={setValue}
              getValues={getValues}
              register={register}
              handleSubmit={handleSubmit}
              control={control}
              activitySelectedKey={
                activityTypeList.find(
                  ({ id }) => id == getValues("id_activity_type")
                )?.key
              }
            />
          </SpDialog>
        )}
        {/* -------------------------- */}
        {/* CREATE ACTIVITY */}
        {/* -------------------------- */}{" "}
        <printContext.Provider
          value={{
            isPrint: isPrintAddActivity,
            setIsPrint: setIsPrintAddActivity,
          }}
        >
          <SpDialog
            variant="bigDialog"
            open={openAddActivity}
            setOpen={setOpenAddActivity}
            onCloseFunctions={resetFields}
            title={titleCase(
              `${currentPatient?.givenName} ${currentPatient?.familyName}`
            )}
          >
            <form onSubmit={handleSubmit(createActivity)}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div ref={createReference()}>
                  <Grid item xs={12}>
                    {/* Mostro il nome del gruppo solo quando stampo il PDF altrimenti non vedrebbe nel file */}
                    {isPrintAddActivity && (
                      <h2 color="black">
                        {currentPatient?.givenName
                          ? titleCase(
                              `${currentPatient?.givenName} ${currentPatient?.familyName}`
                            )
                          : eventDetail?.groupName !== ""
                          ? titleCase(eventDetail?.groupName)
                          : labels.mylab.tempAdd.activityPlan.dialog
                              .groupActTitle}
                      </h2>
                    )}
                  </Grid>
                  <Grid item container direction="row" xs={12}>
                    <Grid item xs={2}>
                      <SpTextInput
                        isPrint={isPrintAddActivity}
                        style={{ width: "100%", paddingTop: "0.5em" }}
                        type="time"
                        name="start_time"
                        min="05:00"
                        max="22:00"
                        defaultValue={getValues("start_time")}
                        inputRef={register({ required: true })}
                        label={
                          labels.patient.viewPlan.addActivity.inputForm
                            .start_time
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <SpTextInput
                        style={{ width: "100%", paddingTop: "0.5em" }}
                        type="time"
                        name="end_time"
                        min="05:00"
                        max="22:00"
                        defaultValue={getValues("end_time")}
                        inputRef={register({ required: true })}
                        label={
                          labels.patient.viewPlan.addActivity.inputForm.end_time
                        }
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <Grid item xs={12}>
                        <Controller
                          render={(props) => (
                            <SpSelect
                              label={
                                labels.patient.patientPlan.chooseActivityPlan
                              }
                              style={{ marginTop: "1%" }}
                              formControlWidth={"100%"}
                              value={props.value}
                              onChange={(e) => {
                                props.onChange(e.target.value);
                                setTemplateHelper(!templateHelper);
                              }}
                              isPrint={isPrintAddActivity}
                            >
                              {activityPlanTemplatesList.map((p) => (
                                <SpSelectMenuItem key={p?.id} value={p?.id}>
                                  {p?.template_name}
                                </SpSelectMenuItem>
                              ))}
                            </SpSelect>
                          )}
                          defaultValue={""}
                          name={`id_activity_template`}
                          control={control}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Controller
                        render={(props) => (
                          <SpSelect
                            label={
                              labels.patient.patientPlan.selectPresentation
                            }
                            value={props.value}
                            formControlWidth={"100%"}
                            selectPlaceholder={
                              labels.patient.patientPlan.selectPresentation
                            }
                            onChange={(e) => {
                              props.onChange(e.target.value);
                            }}
                            isPrint={isPrintAddActivity}
                          >
                            {presentations.map((p) => (
                              <SpSelectMenuItem key={p?.id} value={p?.id}>
                                {p?.name}
                              </SpSelectMenuItem>
                            ))}
                          </SpSelect>
                        )}
                        defaultValue={""}
                        name={`id_presentation`}
                        control={control}
                      />
                    </Grid>
                    {!getValues("id_activity_template") && (
                      <Grid item container direction="row" xs={8}>
                        <Grid item xs={6}>
                          <Controller
                            render={(props) => (
                              <SpSelect
                                id="chooseActivitySelect"
                                label={
                                  labels.patient.viewPlan.addActivity.inputForm
                                    .chooseActivity
                                }
                                style={{ marginTop: "1%" }}
                                formControlWidth={"100%"}
                                value={props.value ?? []}
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                  setValue("quantity_training", 0);
                                  setValue("quantity_treatment", 0);
                                  setValue("quantity_rehabilitation", 0);
                                  setValue("quantity_excercise", 0);
                                }}
                                isPrint={isPrintAddActivity}
                              >
                                {activityTypeList.map((p, index) => (
                                  <SpSelectMenuItem
                                    id={`chooseActivitySelect-select-${index}`}
                                    key={p?.id}
                                    value={p?.id}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flex: 1,
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={require(`../../assets/activities/${
                                          p.key
                                        }${
                                          props.value === p?.id ? "" : "_black"
                                        }.png`)}
                                        style={{
                                          maxWidth: 25,
                                          maxHeight: 25,
                                        }}
                                        alt={"img"}
                                      />
                                      <SpText
                                        color={
                                          props?.value === p?.id
                                            ? "white"
                                            : "black"
                                        }
                                        style={{
                                          color: "black !important",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {psTranslate(p?.name)}
                                      </SpText>
                                    </div>
                                  </SpSelectMenuItem>
                                ))}
                              </SpSelect>
                            )}
                            defaultValue={""}
                            name={`id_activity_type`}
                            control={control}
                          />
                        </Grid>
                        {getSubactivities()}
                      </Grid>
                    )}
                  </Grid>

                  {templatesList.length > 0 &&
                    !getValues("id_activity_template") && (
                      <Grid item xs={12}>
                        <Controller
                          render={(props) => (
                            <SpSelect
                              label={
                                labels.patient.viewPlan.addActivity.inputForm
                                  .chooseTemplateActivity
                              }
                              style={{ marginTop: "1%" }}
                              formControlWidth={"100%"}
                              value={props.value}
                              onChange={(e) => props.onChange(e.target.value)}
                              isPrint={isPrintAddActivity}
                            >
                              {templatesList.map((p) => (
                                <SpSelectMenuItem key={p?.id} value={p?.id}>
                                  <SpText
                                    color={
                                      props.value === p?.id ? "white" : "black"
                                    }
                                    style={{
                                      color: "black !important",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    {p?.name}
                                  </SpText>
                                </SpSelectMenuItem>
                              ))}
                            </SpSelect>
                          )}
                          defaultValue={""}
                          name={`id_template`}
                          control={control}
                        />
                      </Grid>
                    )}
                  {error && (
                    <SpText variant="h4ComponentLabelError">{error}</SpText>
                  )}
                  <div style={{ marginTop: "1%", marginBottom: "1%" }}>
                    {renderInputDetails()}
                  </div>
                  <SpTextArea
                    name="event_note"
                    label={labels.patient.patientAlerts.table.button.note}
                    inputRef={register}
                    isPrint={isPrintAddActivity}
                  />
                  {/* I pulsanti non devono essere mostrati nel PDF */}
                  {!isPrintAddActivity && (
                    <Grid item container xs={12} spacing={1}>
                      <Grid item xs={6}>
                        <SpButton
                          buttonType={"accept"}
                          variant="none"
                          text={labels.patient.viewPlan.addActivity.title}
                          disabled={
                            !getValues("id_activity_type") &&
                            !getValues("id_activity_template")
                          }
                          style={{ marginTop: "4%", width: "100%" }}
                          type="submit"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SpButton
                          id="addAndCompileBtn"
                          buttonType={"accept"}
                          variant="none"
                          text={
                            labels.patient.viewPlan.addActivity.addAndCompile
                          }
                          style={{ marginTop: "4%", width: "100%" }}
                          type="submit"
                          disabled={getValues("id_activity_template")}
                          onClick={async (e) => {
                            e.preventDefault();
                            setLoading(true);
                            const result = await createActivity(getValues());
                            if (result) {
                              const [idEvent, newEventList] = result;
                              const newEvent = newEventList.find(
                                (evnt) => evnt.id === idEvent
                              );
                              if (newEvent) handleEventDetailSelected(newEvent);
                            }
                            setLoading(false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </form>
          </SpDialog>
        </printContext.Provider>
      </div>
    </printContext.Provider>
  );
};

export default withSnackbar(PatientsPlan);
