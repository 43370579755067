import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../App.css";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import PatientsPresentationAddStep1 from "../patients/patientsPresentationAdd/PatientsPresentationAddStep1";
import { Grid } from "@material-ui/core";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { findPatientByPk } from "../../models/actions/Patients";

const PatientsPresentationAdd = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();

  const { patId } = useParams();
  const { setLoading } = props;
  const history = useHistory();

  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });

      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12}>
          <PatientsPresentationAddStep1
            setLoading={setLoading}
            onSave={props.onSave}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(PatientsPresentationAdd);
