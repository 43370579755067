import { Avatar, Grid, makeStyles } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React, { useEffect, useState } from "react";
import SpIconButton from "../../../components/atoms/SpIconButton";
import SpText from "../../../components/atoms/SpText";
import SpDialog from "../../../components/atoms/SpDialog";
import { theme } from "../../../components/theme";
import { labels } from "../../shared/translations";
import {
  getColorFromPatientStatus,
  getLabelFromPatientStatus,
  getUserPermission,
  keysStatus,
  rollbar,
  titleCase,
} from "../../../utils/common";
import GroupEditSidebar from "../shared/GroupEditSidebar";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  updatePatient,
  getPatientTrainingNotes,
} from "../../../models/actions/Patients";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import { SpTableCell } from "../../../components/bundles/SpTable";
import { styled } from "../../../components/styled";
import { SpTableRow } from "../../../components/bundles/SpTable";
import SpTextInput from "../../../components/atoms/SpTextInput";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { getGroupsById } from "../../../models/actions/Groups";
import SpButton from "../../../components/atoms/SpButton";

import { getReadinessTable } from "../../../models/actions/Patients";
import SpLoader from "../../../components/atoms/SpLoader";
import SpIconAndTextButton from "../../../components/atoms/SpIconAndTextButton";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { ImageWithBackgroundColor } from "../situation/SituationTableHelper";
import SpTooltip from "../../../components/atoms/SpTooltip";
import { useAuth0 } from "@auth0/auth0-react";
import PatientsDetail from "../../patients/PatientsDetail";

const useStyles = makeStyles((theme) => ({
  largeAvatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const ReadinessTable = ({
  snackbarShowMessage,
  snackbarShowErrorMessage,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [currentGroup, setCurrentGroup] = useState([]);
  const [firstReports, setFirstReports] = useState([]);
  const [dialog, setDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [patientNotes, setPatientNotes] = useState("");
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const history = useHistory();
  const { groupId } = useParams();
  const { control } = useForm({
    shouldUnregister: false,
  });
  const { getAccessTokenSilently } = useAuth0();

  const [openInfoPatient, setOpenInfoPatient] = useState(false);

  const classes = useStyles();

  const fetchData = async () => {
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );
    setIsPatientLabel(patientLabelTemp);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Graphical part of readiness
  const SpTableRowHeader = styled(({ isRowSelected, ...other }) => (
    <TableRow {...other} />
  ))({
    height: 50,
    border: "3px solid black",

    width: "86%",
    background: theme.colors.primary.lightBlue,
    color: "#FFFFFF",
    "&.MuiTableRow-root.Mui-selected": {
      background: "#FFFFFF",
    },
  });

  const HeaderCell = ({ name }) => (
    <TableCell
      style={{ border: "1px solid black", padding: "0px", margin: "0px" }}
      rowSpan={2}
    >
      <SpText variant={"h4ComponentLabelCenter"}>{name.toUpperCase()}</SpText>
    </TableCell>
  );

  const HeaderCellMultiline = ({ name, lines }) => (
    <TableCell
      style={{ border: "1px solid black", padding: "0px", margin: "0px" }}
      colSpan={lines}
    >
      <SpText variant={"h4ComponentLabelCenter"}>{name.toUpperCase()}</SpText>
    </TableCell>
  );

  const HeaderCellMultilineContent = ({ name }) => (
    <TableCell
      style={{ border: "1px solid black", padding: "0px", margin: "0px" }}
    >
      <SpText variant={"h4ComponentLabelCenter"}>{name}</SpText>
    </TableCell>
  );

  const CellContentPatient = ({ name, url, id }) => (
    <SpTableCell style={{ width: "15%" }}>
      <Grid item container xs={12} direction="row">
        <Avatar
          imgProps={{
            style: {
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
            },
          }}
          className={classes.largeAvatar}
          src={url ? url : ""}
        />
        <SpIconAndTextButton
          isIconRight={true}
          text={`${titleCase(name)} `}
          textVariant={"tableTextDetails"}
          onClick={() => history.push(`/patients/edit/${id}`)}
        >
          {/* <SpDialog
            open={openInfoPatient}
            setOpen={setOpenInfoPatient}
            title={"Info Atleta"}
          >
            <PatientsDetail id={id} />
          </SpDialog> */}
          <ArrowForwardIosIcon
            style={{
              width: 15,
              color: theme.colors.primary.lightBlue,
              height: 30,
              cursor: "pointer",
            }}
          />
        </SpIconAndTextButton>
      </Grid>
    </SpTableCell>
  );

  const CellContent = ({ name, color }) => (
    <SpTableCell
      style={{
        border: "solid 0px black",
        padding: "0.2rem", //0px
        margin: "0px",
      }}
    >
      <Grid xs={12} style={{ backgroundColor: color }}>
        <SpText variant={"tableTextreadiness"} color="#fff" textAlign="center">
          {name}
        </SpText>
      </Grid>
    </SpTableCell>
  );
  const CellContentStatus = ({ status }) => {
    return (
      <SpTableCell style={{ width: "2em", height: "5em" }}>
        <ImageWithBackgroundColor
          imageUrl={require(`../../../assets/activities/training.png`)}
          backgroundColor={status === keysStatus.unavailable ? "red" : "green"}
        />
      </SpTableCell>
    );
  };

  // utilities

  const extractColor = (code) => {
    try {
      var colors = {
        noRisk: "#3db7e4",
        lowRisk: "#32CD32",
        mediumRisk: "#dddd00",
        highRisk: "red",
      };
      return colors[code];
    } catch (error) {
      rollbar.error(`Error extracting colors in readiness table - ${error}`);
    }
  };

  const getNotes = async (row) => {
    const res = await getPatientTrainingNotes({ id_patient: row.id });
    setPatientNotes(res);
  };

  const addNotes = async (row, data) => {
    await updatePatientFunction({
      data: { training_notes: data },
      id_patient: row.id,
    });
    setDialog(false);
  };

  const updatePatientFunction = async ({ data, id_patient }) => {
    const resultMessUpdate = await updatePatient({
      id_patient,
      data,
    });
    if (resultMessUpdate.error) {
      snackbarShowErrorMessage(resultMessUpdate.error);
    } else {
      snackbarShowMessage(resultMessUpdate.message);
    }
  };

  useEffect(async () => {
    try {
      setLoading(true);
      const groupResults = await getGroupsById({ id_group: groupId });
      setCurrentGroup(groupResults);
      const fullTemplates = await getReadinessTable({ groupId: groupId });
      setFirstReports(fullTemplates);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      rollbar.error("ReadinessTable - fetchDataInitial", error);
    }
  }, []);

  return (
    <>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid
        style={{ paddingLeft: "1%" }}
        direction="column"
        container
        spacing={2}
      >
        <Grid
          item
          container
          direction="row"
          style={{ margin: "auto 0", padding: 0 }}
        >
          <Grid item xs={12} style={{ paddingLeft: "1%" }}>
            <SpText variant={"h1PageTitle"}>
              {labels.group.groupMonitoring.titles[1].value.toUpperCase()}
            </SpText>

            <Table
              stickyHeader
              style={{ minWidth: "100%" }}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              size="small"
            >
              <TableHead>
                <SpTableRowHeader>
                  <HeaderCell
                    name={
                      isPatientLabel
                        ? labels.groups.groupReports.firstTable.header.patient
                        : labels.groups.groupReports.firstTable.header.athlete
                    }
                  />
                  <HeaderCell
                    name={labels.groups.groupReadiness.table.header.status}
                  />
                  <HeaderCell
                    name={
                      labels.groups.groupReports.firstTable.header
                        .trainingStatus
                    }
                  />
                  <HeaderCellMultiline
                    name={
                      labels.groups.groupReadiness.table.header.acuteChronic
                    }
                    lines={2}
                  />
                  <HeaderCellMultiline
                    name={labels.groups.groupReadiness.table.header.w2wVar}
                    lines={2}
                  />
                  <HeaderCell
                    name={labels.groups.groupReadiness.table.header.monotony}
                  />
                  <HeaderCell
                    name={labels.groups.groupReadiness.table.header.tqrScore}
                  />
                  <HeaderCellMultiline
                    name={labels.groups.groupReadiness.table.header.wellness}
                    lines={5}
                  />

                  <HeaderCell
                    name={labels.groups.groupReadiness.table.header.training}
                  />
                </SpTableRowHeader>

                <SpTableRowHeader>
                  {/* ACR */}
                  <HeaderCellMultilineContent
                    name={labels.groups.groupReadiness.table.subHeader.curr}
                  />
                  <HeaderCellMultilineContent
                    name={labels.groups.groupReadiness.table.subHeader.next}
                  />
                  {/* W2W */}
                  <HeaderCellMultilineContent
                    name={labels.groups.groupReadiness.table.subHeader.curr}
                  />
                  <HeaderCellMultilineContent
                    name={labels.groups.groupReadiness.table.subHeader.next}
                  />
                  {/* Wellness */}
                  <HeaderCellMultilineContent
                    name={labels.groups.groupReadiness.table.subHeader.well_gen}
                  />
                  <HeaderCellMultilineContent
                    name={
                      labels.groups.groupReadiness.table.subHeader.well_sleep
                    }
                  />
                  <HeaderCellMultilineContent
                    name={
                      labels.groups.groupReadiness.table.subHeader.well_stress
                    }
                  />
                  <HeaderCellMultilineContent
                    name={
                      labels.groups.groupReadiness.table.subHeader.well_fatigue
                    }
                  />
                  <HeaderCellMultilineContent
                    name={
                      labels.groups.groupReadiness.table.subHeader.well_aches
                    }
                  />
                </SpTableRowHeader>
              </TableHead>

              <TableBody>
                {firstReports?.map((params) => {
                  return (
                    <SpTableRow>
                      <CellContentPatient
                        id={params?.patient?.id}
                        name={params?.patient?.fullname}
                        url={params?.patient?.profileImage}
                      />
                      <SpTableCell style={{ width: "1em" }}>
                        <SpTooltip
                          title={getLabelFromPatientStatus({
                            status: params?.patient?.status,
                          })}
                        >
                          <SpIconButton
                            variant="lightBlue"
                            style={{
                              marginLeft: 0,
                              cursor: "default",
                            }}
                            small
                          >
                            <FiberManualRecordIcon
                              style={{
                                color: getColorFromPatientStatus({
                                  status: params.patient.status,
                                }),
                              }}
                            />
                          </SpIconButton>
                        </SpTooltip>
                      </SpTableCell>
                      <CellContentStatus
                        status={params?.patient?.training_status}
                      />

                      <CellContent
                        name={params.ARCWL_curr}
                        color={extractColor(params?.ARCWL_curr_status)}
                      />
                      <CellContent
                        name={params.ARCWL_next}
                        color={extractColor(params?.ARCWL_next_status)}
                      />
                      <CellContent
                        name={params.w2w_curr}
                        color={extractColor(params?.w2w_curr_status)}
                      />
                      <CellContent
                        name={params.w2w_next}
                        color={extractColor(params?.w2w_next_status)}
                      />
                      <CellContent
                        name={params.monotony}
                        color={extractColor(params?.monotony_status)}
                      />
                      <CellContent
                        name={params.tqrScore}
                        color={extractColor(params?.tqrScore_status)}
                      />
                      <CellContent
                        name={params.wellnessGeneral}
                        color={extractColor(params?.wellnessGeneral_status)}
                      />
                      <CellContent
                        name={params.wellnessSleep}
                        color={extractColor(params?.wellnessSleep_status)}
                      />
                      <CellContent
                        name={params.wellnessStress}
                        color={extractColor(params?.wellnessStress_status)}
                      />
                      <CellContent
                        name={params.wellnessFatigue}
                        color={extractColor(params?.wellnessFatigue_status)}
                      />
                      <CellContent
                        name={params.wellnessAches}
                        color={extractColor(params?.wellnessAches_status)}
                      />
                      <SpTableCell style={{ width: "5%" }}>
                        <div>
                          <Controller
                            render={(props) => (
                              <SpButton
                                buttonType={"accept"}
                                variant="none"
                                text={
                                  labels.groups.groupReadiness.table.notesButton
                                }
                                style={{ marginTop: "4%", width: "100%" }}
                                onClick={async () => {
                                  setDialog(true);
                                  setSelectedRow(params);
                                  await getNotes(params);
                                }}
                              />
                            )}
                            defaultValue={""}
                            name={`assessmentAnswers.note`}
                            control={control}
                          />
                        </div>
                      </SpTableCell>
                    </SpTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        {dialog && (
          <SpDialog
            variant="bigDialog"
            open={dialog}
            setOpen={setDialog}
            onCloseFunctions={() => setDialog(false)}
            // title={labels.bundles.assessment.renderUtils.additionalNotes}
            title={labels.groups.groupReadiness.dialog.title}
          >
            <Grid item xs={12}>
              <SpTextInput
                style={{ width: "100%" }}
                type="text"
                name="training_notes"
                label={labels.groups.groupReadiness.dialog.inputLabel}
                value={patientNotes}
                onChange={(e) => setPatientNotes(e.target.value)}
              />
              <Grid item xs={12} spacing={1}>
                <Grid item xs={12}>
                  <SpButton
                    buttonType={"accept"}
                    variant="none"
                    text={labels.groups.groupReadiness.dialog.updateNotesButton}
                    style={{ marginTop: "4%", width: "100%" }}
                    // type="submit"
                    // disabled={getValues("id_activity_template")}
                    onClick={() => addNotes(selectedRow, patientNotes)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SpDialog>
        )}
      </Grid>
    </>
  );
};
export default withSnackbar(ReadinessTable);
