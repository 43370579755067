import React from "react";
import {
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { psTranslate } from "../../shared/translations";

const CustomTooltip = ({ active, payload, label }) => {
  try {
    if (active && payload?.length > 0) {
      const keysToShow = Object.keys(payload[0].payload).filter(
        (key) => key !== "Date"
      );
      const tooltipContent = keysToShow.map((key) => {
        return (
          <p
            key={`tooltip`}
            style={{ color: "#b8b6b6", margin: "5px 0", paddingLeft: "10px" }}
          >
            {psTranslate(key)}: {payload[0].payload[key]}
          </p>
        );
      });

      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "#1f1f1f", padding: "10px" }}
        >
          <b style={{ color: "#ffffff" }}> {`Data: ${label}`}</b>
          {tooltipContent}
        </div>
      );
    }

    return null;
  } catch (error) {
    return null;
  }
};

const PromsChart = ({ dataM, dataS, selP, features, general }) => {
  features.forEach((fea) => {
    for (let el = 0; el < dataM.length; el++) {
      try {
        if (fea === "HI") {
          if (dataM[el][fea] + dataS[el][fea] * 0.4 > 28) {
            dataM[el][fea + "_upperLim"] = 28;
          } else {
            dataM[el][fea + "_upperLim"] =
              dataM[el][fea] + dataS[el][fea] * 0.4;
          }
        } else {
          dataM[el][fea + "_upperLim"] = dataM[el][fea] + dataS[el][fea] * 0.4;
        }
        if (dataM[el][fea] - dataS[el][fea] * 0.4 < 0) {
          dataM[el][fea + "_lowerLim"] = 0;
        } else {
          dataM[el][fea + "_lowerLim"] = dataM[el][fea] - dataS[el][fea] * 0.4;
        }
      } catch {}
    }
  });

  if (dataM.length === 0) {
    dataM = [
      {
        HI: 0,
        HI_lowerLim: 0,
        HI_upperLim: 0,
        TL: 0,
        TL_lowerLim: 0,
        TL_upperLim: 0,
      },
    ];
  }

  const domain =
    selP === "HI"
      ? [0, 28]
      : [
          "Muscular ache",
          "Fatigue level",
          "Stress level",
          "Sleep quality",
        ].includes(selP)
      ? [0, 7]
      : undefined;

  if (general === true) {
    return (
      <div style={{ maxWidth: "90vw" }}>
        <ResponsiveContainer width="100%" height={320}>
          <ComposedChart
            width={700}
            height={400}
            data={dataM}
            margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="#ff0000" stopOpacity={0.4} />
                <stop offset="24%" stopColor="#ffa500" stopOpacity={0.4} />
                <stop offset="45%" stopColor="#000000" stopOpacity={0.1} />
                <stop offset="70%" stopColor="#1b9100" stopOpacity={0.4} />
              </linearGradient>
            </defs>

            <CartesianGrid
              stroke="#f5f5f5"
              strokeOpacity={0.1}
              fill="url(#colorUv)"
            />

            <XAxis
              dataKey="Date"
              tick={{ angle: -30, textAnchor: "end", dy: 5 }}
            />
            <YAxis
              yAxisId="left"
              domain={[0, 28]}
              label={{
                value: "Hopper index",
                angle: -90,
                position: "Left",
                style: { fontSize: "20px", fill: "#FFFFFF" },
              }}
              width={80}
              orientation="left"
            />
            <YAxis
              yAxisId="right"
              label={{
                value: "sRPE",
                angle: -90,
                position: "Right",
                style: { fontSize: "20px", fill: "#FFFFFF" },
              }}
              width={80}
              orientation="right"
            />

            <Line
              type="monotone"
              dataKey="HI"
              stroke="#3dc5ff"
              strokeWidth={4}
              yAxisId="left"
              dot={{ fill: "#3dc5ff" }}
            />
            <Area
              type="monotone"
              dataKey="HI_lowerLim"
              stroke="#3dc5ff82"
              strokeWidth={3}
              fill="transparent"
              yAxisId="left"
            />
            <Area
              type="monotone"
              dataKey="HI_upperLim"
              stroke="#3dc5ff82"
              strokeWidth={3}
              fill="transparent"
              yAxisId="left"
            />

            <Line
              type="monotone"
              dataKey="TL"
              stroke="#ffffff"
              strokeWidth={4}
              yAxisId="right"
              dot={{ fill: "#3dc5ff" }}
            />
            <Area
              type="monotone"
              dataKey="TL_lowerLim"
              stroke="#ffffff82"
              strokeWidth={3}
              fill="transparent"
              yAxisId="right"
            />
            <Area
              type="monotone"
              dataKey="TL_upperLim"
              stroke="#ffffff82"
              strokeWidth={3}
              fill="transparent"
              yAxisId="right"
            />

            <Legend verticalAlign="top" height={25} fontSize={25} />
            <Tooltip content={<CustomTooltip />} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  } else {
    return (
      <ResponsiveContainer width="100%" height={320}>
        <ComposedChart
          width={800}
          height={400}
          data={dataM}
          margin={{ top: 10, right: 0, left: 0, bottom: 50 }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="1%" stopColor="#ff0000" stopOpacity={0.4} />
              <stop offset="24%" stopColor="#ffa500" stopOpacity={0.4} />
              <stop offset="45%" stopColor="#000000" stopOpacity={0.1} />
              <stop offset="70%" stopColor="#1b9100" stopOpacity={0.4} />
            </linearGradient>
          </defs>

          <CartesianGrid
            stroke="#f5f5f5"
            strokeOpacity={0.1}
            fill="url(#colorUv)"
          />

          <XAxis
            dataKey="Date"
            tick={{ angle: -30, textAnchor: "end", dy: 5 }}
          />
          <YAxis
            yAxisId="left"
            domain={domain}
            label={{
              value: selP,
              angle: -90,
              position: "Left",
              style: { fontSize: "20px", fill: "#FFFFFF" },
            }}
            width={80}
          />

          <Line
            type="monotone"
            dataKey={selP}
            stroke="#3dc5ff"
            strokeWidth={4}
            yAxisId="left"
            dot={{ fill: "#3dc5ff" }}
          />
          <Area
            type="monotone"
            dataKey={`${selP}_lowerLim`}
            stroke="#3dc5ff82"
            strokeWidth={3}
            fill="transparent"
            yAxisId="left"
          />
          <Area
            type="monotone"
            dataKey={`${selP}_upperLim`}
            stroke="#3dc5ff82"
            strokeWidth={3}
            fill="transparent"
            yAxisId="left"
          />

          <Legend verticalAlign="top" height={25} fontSize={25} />
          <Tooltip content={<CustomTooltip />} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};

export default PromsChart;
