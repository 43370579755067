import React, { useEffect, useState } from "react";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { useHistory } from "react-router-dom";
import {
  SpTable,
  SpTableCell,
  SpTableRow,
} from "../components/bundles/SpTable";
import { createProms, getAllProms } from "../models/actions/Proms";
import SpDialog from "../components/atoms/SpDialog";
import SpTextInput from "../components/atoms/SpTextInput";
import SpCheckbox from "../components/atoms/SpCheckbox"; // Importa il componente SpCheckbox
import { labels } from "./shared/translations";
import { Grid, Button, Typography } from "@material-ui/core";
import SpLoader from "../components/atoms/SpLoader";
import { styled } from "../components/styled";
import { theme } from "../components/theme";
import { useAuth0 } from "@auth0/auth0-react"; // Importa il hook useAuth0
import { getUserPermission } from "../utils/common";
import { ArrowBack, Create } from "@material-ui/icons";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const Proms = (props) => {
  const [displayRequests, setDisplayRequests] = useState([]);
  const [selectedProm, setSelectedProm] = useState(null);
  const [openPromDetail, setOpenPromDetail] = useState(false);
  const [openNewPromDialog, setOpenNewPromDialog] = useState(false);
  const [newPromTitle, setNewPromTitle] = useState("");
  const [newPromDescription, setNewPromDescription] = useState("");
  const [newPromQuestions, setNewPromQuestions] = useState([]);
  const [createPromPermission, setCreatePromPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  useEffect(async () => {
    const fetchProms = async () => {
      const tempproms = await getAllProms({});
      setDisplayRequests(tempproms);
    };
    const tempCreatePromPermission = await getUserPermission(
      "create:proms",
      getAccessTokenSilently
    ); // Ottiene i permessi per creare i PROM
    setCreatePromPermission(tempCreatePromPermission);
    fetchProms();
  }, []);

  const handleRowClick = (prom) => {
    setSelectedProm(prom);
    setOpenPromDetail(true);
  };

  const handleAddQuestion = () => {
    setNewPromQuestions([
      ...newPromQuestions,
      { question: "", answers: [], fl_multiple: false },
    ]);
  };

  const handleAddAnswer = (questionIndex) => {
    const updatedQuestions = [...newPromQuestions];
    updatedQuestions[questionIndex].answers.push({ answer: "", score: "" });
    setNewPromQuestions(updatedQuestions);
  };

  const handleQuestionChange = (questionIndex, field, value) => {
    const updatedQuestions = [...newPromQuestions];
    updatedQuestions[questionIndex][field] = value;
    setNewPromQuestions(updatedQuestions);
  };

  const handleAnswerChange = (questionIndex, answerIndex, field, value) => {
    const updatedQuestions = [...newPromQuestions];
    updatedQuestions[questionIndex].answers[answerIndex][field] = value;
    setNewPromQuestions(updatedQuestions);
  };

  const handleSaveNewProm = async () => {
    // Logica per salvare il nuovo PROM

    const ret = await createProms({
      title: newPromTitle,
      description: newPromDescription,
      questions: newPromQuestions,
    });
    setOpenNewPromDialog(false);
    if (ret.message) {
      props.snackbarShowMessage(ret.message);
    }
    if (ret.error) {
      props.snackbarShowErrorMessage(ret.error);
    }
  };

  const PatientRequestRow = ({ row, index }) => {
    return (
      <SpTableRow
        id={row.id}
        tabIndex={index}
        onClick={() => handleRowClick(row)}
      >
        <SpTableCell>
          <SpText variant="tableText">{row.name}</SpText>
        </SpTableCell>
      </SpTableRow>
    );
  };

  const headCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: false,
      label: "name",
      isAction: false,
    },
  ];

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container alignItems={"center"}>
        <Grid item xs={8}>
          <TitleToolbarSection>
            <ArrowBack
              onClick={() => history.push("/mylab")}
              style={{
                width: 30,
                color: theme.colors.primary.lightBlue,
                height: 50,
                cursor: "pointer",
              }}
            />
            <SpText variant="h1">
              {labels.mylab.tempDetail.backButtonList + " / "}{" "}
              <span style={{ color: "#fff" }}>{"PROMS"}</span>
            </SpText>
          </TitleToolbarSection>
        </Grid>
      </Grid>
      <div style={{ padding: "20px" }}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <SpText variant="h1">PROMs</SpText>
          </Grid>
          <Grid item>
            {createPromPermission && (
              <SpButton
                onClick={() => setOpenNewPromDialog(true)}
                text={labels.mylab.proms.create}
              />
            )}
          </Grid>
        </Grid>

        <SpTable
          pagination={true}
          headCells={headCells}
          rows={displayRequests}
          rowKey="id"
          padding={false}
          notCheckable={false}
          tableContainerMaxHeight={"calc(100vh - 320px)"}
        >
          <PatientRequestRow />
        </SpTable>
        {selectedProm && (
          <SpDialog
            open={openPromDetail}
            setOpen={setOpenPromDetail}
            title={selectedProm.name}
          >
            {selectedProm.proms_questions.map((question) => (
              <div key={question.id}>
                <SpText variant="h4ComponentLabelRegular">
                  {question.question}
                </SpText>
                <ul>
                  {question.proms_answers.map((answer) => (
                    <li key={answer.id}>
                      <SpText variant="text">{answer.answer}</SpText>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </SpDialog>
        )}
        {openNewPromDialog && (
          <SpDialog
            open={openNewPromDialog}
            setOpen={setOpenNewPromDialog}
            title={labels.mylab.proms.create}
          >
            <div>
              <SpText variant="h4ComponentLabelRegular">
                {labels.mylab.proms.title}
              </SpText>
              <SpTextInput
                text={labels.mylab.proms.addQuestion}
                type="text"
                value={newPromTitle}
                onChange={(e) => setNewPromTitle(e.target.value)}
              />
              <SpText variant="h4ComponentLabelRegular">
                {labels.mylab.proms.description}
              </SpText>
              <SpTextInput
                text={labels.mylab.proms.description}
                type="text"
                value={newPromDescription}
                onChange={(e) => setNewPromDescription(e.target.value)}
              />
            </div>
            {newPromQuestions.map((question, questionIndex) => (
              <div key={question.id}>
                <SpText variant="h4ComponentLabelRegular">
                  {labels.mylab.proms.question}
                </SpText>

                <Grid container item xs={12} spacing={2}>
                  <Grid item xs={8}>
                    <SpTextInput
                      type="text"
                      style={{ width: "100%" }}
                      value={question.question}
                      onChange={(e) =>
                        handleQuestionChange(
                          questionIndex,
                          "question",
                          e.target.value
                        )
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SpCheckbox
                      id={"cartilageLabel"}
                      label={labels.mylab.proms.multipleAnswer}
                      rightlabel={true}
                      formControlStyle={{
                        justifyContent: "flex-start",
                        marginLeft: "5%",
                        marginBottom: "0%",
                      }}
                      checked={question.fl_multiple}
                      onChange={(e) => {
                        handleQuestionChange(
                          questionIndex,
                          "fl_multiple",
                          e.target.checked
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <SpButton
                      text={labels.mylab.proms.addAnswer}
                      onClick={() => handleAddAnswer(questionIndex)}
                    />
                  </Grid>
                </Grid>

                {question.answers.map((answer, answerIndex) => (
                  <div key={answer.id}>
                    <SpText variant="h4ComponentLabelRegular">
                      {labels.mylab.proms.answer}
                    </SpText>
                    <SpTextInput
                      type="text"
                      placeholder={labels.mylab.proms.name}
                      value={answer.answer}
                      onChange={(e) =>
                        handleAnswerChange(
                          questionIndex,
                          answerIndex,
                          "answer",
                          e.target.value
                        )
                      }
                    />
                    <SpTextInput
                      type="text"
                      placeholder={labels.mylab.proms.value}
                      value={answer.score}
                      onChange={(e) =>
                        handleAnswerChange(
                          questionIndex,
                          answerIndex,
                          "score",
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            ))}
            <SpButton
              text={labels.mylab.proms.addQuestion}
              onClick={handleAddQuestion}
            />
            <SpButton
              text={labels.mylab.proms.createProm}
              onClick={handleSaveNewProm}
            />
          </SpDialog>
        )}
      </div>
    </Grid>
  );
};

export default Proms;
