import jsPDF from "jspdf";
import "jspdf-autotable";
import { labels, psTranslate } from "../pages/shared/translations";
import { getUserPermission } from "./common";
import moment from "moment";
import LogoUrl from "../assets/icon/powerset_logo_web.png";

export const generatePDF = async ({ appointments, getAccessTokenSilently }) => {
  const img = new Image();
  img.src = LogoUrl;

  const doc = new jsPDF("landscape");
  const pageWidth = doc.internal.pageSize.getWidth() - 50;

  // Barra colorata
  const barHeight = 1; // Altezza della barra
  const barWidth = pageWidth; // Larghezza della barra
  doc.setDrawColor(73, 197, 182); // Colore RGB della barra
  doc.setFillColor(73, 197, 182);
  doc.rect(0, 201, barWidth, barHeight, "F"); // Disegna la barra (F = fill)

  // Testo sotto la barra
  doc.setFont("helvetica", "italic");
  doc.setFontSize(9);
  doc.setTextColor(73, 197, 182);
  const textFooter = `${moment().format("DD/MM/YYYY")} `;
  const textWidth =
    (doc.getStringUnitWidth(textFooter) * doc.internal.getFontSize()) /
    doc.internal.scaleFactor;
  const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2; // Centra il testo orizzontalmente
  doc.text(textFooter, textX, 207);
  const imgWidth = 19;
  const imgHeight = (img.height / img.width) * imgWidth;
  doc.addImage(img, "PNG", 260, 200, imgWidth, imgHeight);

  // Aggiungi titolo
  doc.setFontSize(18);
  const patientLabelTemp = await getUserPermission(
    "patient-label",
    getAccessTokenSilently
  );
  // Aggiungi tabella
  const tableColumn = [
    labels.patient.proms.dailyActivities,
    labels.patient.monitoring.table.startDate,
    labels.patient.viewPlan.addActivity.inputForm.start_time,
    labels.patient.viewPlan.addActivity.inputForm.end_time,
    patientLabelTemp
      ? labels.nav.patients
      : labels.patient.component.backButtonLista,
  ];
  const tableRows = [];

  appointments.forEach((item) => {
    const activityData = [
      psTranslate(item?.activity_datum?.activity_type?.name),
      moment.utc(item?.start_date, "YYYY-MM-DD").local().format("DD/MM/YYYY"),
      moment.utc(item?.start_time, "HH:mm:ss").local().format("HH:mm"),
      moment.utc(item?.end_time, "HH:mm:ss").local().format("HH:mm"),
      item?.patients?.map(
        ({ givenName, familyName }) => `${givenName} ${familyName}`
      ),
    ];
    tableRows.push(activityData);
  });

  doc.autoTable(tableColumn, tableRows, {
    startY: 30,
    headStyles: {
      fillColor: "#505050",
    },
  });

  // Salva il PDF
  doc.save(
    `${moment().format("DD/MM/YYYY")}_${moment()
      .add(1, "day")
      .format("DD/MM/YYYY")}.pdf`
  );
};
