import { Divider, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../App.css";
import {
  getAnswerStructureDysfunctions,
  getAssessmentQuestionnaires,
  getAssessmentsDetail,
  getAssessmentTemplatesByProfessional,
  sendAssessmentAnswerMonitoring,
  sendAssessmentAnswerPresentation,
} from "../../../models/actions/Assessment";
import { labels, psTranslate } from "../../../pages/shared/translations";
import { dateFormat } from "../../../utils/common";
import SpButton from "../../atoms/SpButton";
import SpCheckbox from "../../atoms/SpCheckbox";
import SpDialog from "../../atoms/SpDialog";
import { withSnackbar } from "../../atoms/SpSnackBar";
import SpText from "../../atoms/SpText";
import { styled } from "../../styled";
import { AddAssessment } from "./renderUtils";
import { theme } from "../../theme";

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "2%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
  marginBottom: "3%",
});

const StyledBodyHalfColumn = styled("div")({
  flex: 1,
  height: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginRight: "10%",
});

const AddMonitoringAssessment = ({
  setLoading,
  assessmentDetail,
  isRepeatAssessment,
  isRepeatFromTemplate,
  closeAssessmentAdd,
  selectedCategoriesObj,
  date,
  idTemplate,
  ...props
}) => {
  //States
  const [assessmentsCategory, setAssessmentsCategories] = useState([]);
  const [assessmentsElements, setAssessmentsElements] = useState([]);
  const [selectedAssessmentElement, setSelectedElement] = useState();
  const [currentQuestionnairesList, setCurrentQuestionnairesList] = useState(
    []
  );
  const [assessmentTemplatesList, setAssessmentTemplatesList] = useState([]);
  const [selectedAssessmentTemplate, setSelectedAssessmentTemplate] =
    useState(null);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);
  const [openAddStructureDysfunction, setOpenAddStructureDysfunction] =
    useState(false);
  const [openConfirmOverwrite, setOpenConfirmOverwrite] = useState(false);
  const [structureDysfunctionList, setStructureDysfunctionList] = useState([]);
  const [selectedStructureDysfunction, setSelectedStructureDysfunction] =
    useState(new Set());
  const [data, setData] = useState(null);

  //Hook form
  const { control, handleSubmit, getValues, setValue } = useForm({
    name: "category",
    shouldUnregister: false,
  });
  const { remove } = useFieldArray({
    control,
    name: "assessmentAnswers",
  });

  const { userId, patId, presentationId } = useParams();

  const formattedDate = () =>
    (date ? moment(parseInt(date)) : moment()).format(dateFormat);

  //Effects
  const fetchData = async () => {
    setLoading(true);
    try {
      if (!assessmentDetail || isRepeatFromTemplate) {
        //fetch assessments templates list
        let assessmentsTempListData =
          await getAssessmentTemplatesByProfessional();
        if (assessmentsTempListData) {
          if (!isRepeatAssessment) {
            assessmentsTempListData = assessmentsTempListData.filter(
              (x) => !x.deletedAt
            );
          }
          setAssessmentTemplatesList([
            // {
            //     id: -1,
            //     name: labels.bundles.assessment.selectRegion,
            // },
            ...assessmentsTempListData,
          ]);
        }
        if (isRepeatFromTemplate) {
          setSelectedCategoriesIds(selectedCategoriesObj.categoryIds);
          setSelectedAssessmentTemplate(
            selectedCategoriesObj.templateAssessmentId
          );
        }

        // if id template is > 0 template is passed from activity plan
        if (idTemplate > 0) {
          setSelectedAssessmentTemplate(idTemplate);
          const foundElementAssTemp = assessmentsTempListData.find(
            (elem) => elem?.id == idTemplate
          );
          if (foundElementAssTemp)
            setCurrentQuestionnairesList(foundElementAssTemp.AssesmentElement);
        }
      }
      if (isRepeatAssessment) {
        setSelectedCategoriesIds(selectedCategoriesObj.categoryIds);
        !isRepeatFromTemplate &&
          setSelectedElement(selectedCategoriesObj.assessmentId);
      } else {
        if (!assessmentDetail || isRepeatFromTemplate) {
          const response = await getAssessmentsDetail();
          setAssessmentsCategories(response.categories);
          setAssessmentsElements([...response.elements]);
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    if (
      isRepeatAssessment &&
      selectedAssessmentElement &&
      selectedCategoriesIds
    )
      await updateQuestionnairesList(selectedCategoriesIds);
  }, [selectedAssessmentElement, selectedCategoriesIds]);

  useEffect(async () => {
    if (
      isRepeatAssessment &&
      isRepeatFromTemplate &&
      selectedAssessmentTemplate &&
      selectedCategoriesIds
    ) {
      await updateQuestionnairesList(selectedCategoriesIds);
    }
  }, [selectedAssessmentTemplate, selectedCategoriesIds]);

  const checkIsValid = (data) => {
    return data.assessmentAnswers.find(({ answer }) => answer == undefined);
  };

  const _exec = async (addSD, internalData) => {
    setLoading(true);
    const _data = internalData ?? data;
    setOpenAddStructureDysfunction(false);
    try {
      if (!checkIsValid(_data)) {
        const response = await (presentationId
          ? sendAssessmentAnswerPresentation
          : sendAssessmentAnswerMonitoring)({
          id_patient: patId,
          userId: userId,
          id_presentation: presentationId,
          id_assessment: selectedAssessmentElement,
          answers: _data.assessmentAnswers,
          id_assessment_template: _data.assessmentTemplate,
          addSD: addSD,
          selected_structure_dysfunctions: [...selectedStructureDysfunction],
          date: internalData?.start_date
            ? internalData?.start_date
            : formattedDate(),
        });
        //if the record as been updated i notify it and update the layout
        if (response) {
          if (response.message) props.snackbarShowMessage(response.message);
          else props.snackbarShowErrorMessage(response.error);
          closeAssessmentAdd(false);
        }
      } else {
        props.snackbarShowErrorMessage(
          labels.mylab.generalRiskFactor.addComp.error.required
        );
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  const saveAnswers = async (newData) => {
    newData = newData ?? data;
    setOpenConfirmOverwrite(false);
    setLoading(true);
    try {
      //Check if there are some answers that provide new symbols in the 3d model
      const response = await getAnswerStructureDysfunctions({
        assessment_answer_ids: newData.assessmentAnswers
          ?.filter((x) => typeof x.answer === "object")
          ?.map((x) => x.answer.id),
      });
      if (response.length > 0) {
        //Show dialog to select structure_dysfunction
        setOpenAddStructureDysfunction(true);
        setStructureDysfunctionList(response);
      } else {
        await _exec(false, newData);
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  const handleUpdateCategoriesSelected = () => {
    setLoading(true);
    const newCategoriesIdsArray = getValues("category");
    const foundIds = [];
    getValues("category") &&
      getValues("category").map((newCategoryElem, idx) => {
        if (newCategoryElem)
          foundIds.push(newCategoriesIdsArray.indexOf(newCategoryElem, idx));
      });

    setSelectedCategoriesIds(foundIds);
    setLoading(false);
  };

  const updateQuestionnairesList = async (categoryIdsFiltered) => {
    //Reset questionnaires previous answers
    remove();

    //Fetch new questionnaires
    setLoading(true);
    try {
      if (
        categoryIdsFiltered &&
        selectedAssessmentElement &&
        !selectedAssessmentTemplate
      ) {
        const response = await getAssessmentQuestionnaires({
          categories: categoryIdsFiltered,
          element: selectedAssessmentElement,
        });
        setCurrentQuestionnairesList(response);
      } else {
        if (selectedAssessmentTemplate) {
          const foundElementAssTemp = assessmentTemplatesList.find(
            (elem) => elem?.id == selectedAssessmentTemplate
          );
          if (foundElementAssTemp) {
            setCurrentQuestionnairesList(foundElementAssTemp.AssesmentElement);
          }
          if (isRepeatFromTemplate) {
            setValue("assessmentTemplate", selectedAssessmentTemplate);
          }
        }
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  const checkOverwriteAndSave = async (data) => {
    data.assessmentAnswers = data.assessmentAnswers?.filter(
      (answer) => answer.answer !== ""
    );
    setData(data);

    const overwrite =
      (isRepeatAssessment || isRepeatFromTemplate) &&
      assessmentDetail?.createdAt?.startsWith(formattedDate());

    if (overwrite) {
      setOpenConfirmOverwrite(true);
    } else {
      await saveAnswers(data);
    }
  };

  return (
    <>
      <div>
        <StyledBodyColumn>
          <StyledRow>
            {
              <StyledBodyHalfColumn>
                <SpText variant={"h1PageTitle"}>
                  {labels.patient.presentation.add.stepper.step4.title.toUpperCase()}
                </SpText>
              </StyledBodyHalfColumn>
            }
          </StyledRow>
          <Grid item xs={12}>
            <AddAssessment
              {...{
                handleUpdateCategoriesSelected,
                updateQuestionnairesList,
                handleSubmit,
                setSelectedCategoriesIds,
                assessmentsCategory,
                saveAnswers: checkOverwriteAndSave,
                isRepeatAssessment,
                getValues,
                setValue,
                selectedAssessmentElement,
                selectedAssessmentTemplate,
                setCurrentQuestionnairesList,
                control,
                currentQuestionnairesList,
                setSelectedAssessmentTemplate,
                setSelectedElement,
                assessmentsElements,
                assessmentTemplatesList,
                selectedCategoriesIds,
                idTemplate,
              }}
            />
          </Grid>
          <SpDialog
            open={openAddStructureDysfunction}
            setOpen={setOpenAddStructureDysfunction}
            title={labels.bundles.assessment.symbols.title}
          >
            <Grid container direction="column">
              <Grid item xs={12}>
                <SpText variant={"h1PageSubtitle"}>
                  {labels.bundles.assessment.symbols.description}
                </SpText>
              </Grid>
              <Divider
                style={{
                  backgroundColor: theme.colors.primary.lightBlue,
                  marginTop: "1%",
                  height: 1,
                  marginBottom: "1%",
                  width: "100%",
                }}
              />
              {structureDysfunctionList?.map((sdItem, idx) => (
                <Grid
                  key={sdItem.id}
                  item
                  xs={12}
                  container
                  direction="row"
                  style={{ marginBottom: "10px", alignContent: "center" }}
                >
                  <Grid item xs={10} style={{ alignSelf: "center" }}>
                    <SpText variant={"h4ComponentLabel"}>
                      {psTranslate(
                        sdItem.structure_dysfunction?.dysfunction?.name
                      )}{" "}
                      -{" "}
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.name
                      )}{" "}
                      (
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.area?.name
                      )}{" "}
                      -{" "}
                      {psTranslate(
                        sdItem.structure_dysfunction?.structure?.area?.region
                          ?.name
                      )}
                      )
                    </SpText>
                  </Grid>
                  <Controller
                    style={{ marginTop: "3%" }}
                    render={(props) => (
                      <SpCheckbox
                        rightlabel={false}
                        variant="column"
                        onChange={async (e) => {
                          props.onChange(e.target.checked);
                          if (e.target.checked) {
                            selectedStructureDysfunction.add(sdItem);
                          } else {
                            selectedStructureDysfunction.delete(sdItem);
                          }
                          setSelectedStructureDysfunction(
                            selectedStructureDysfunction
                          );
                        }}
                        checked={props.value}
                      />
                    )}
                    defaultValue={""}
                    name={`structure_dysfunction[${idx}].id`}
                    control={control}
                  />
                </Grid>
              ))}
              <Grid item xs={12} container direction="row">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    marginTop: "2%",
                  }}
                  direction="row"
                >
                  <SpButton
                    buttonType="accept"
                    style={{ flex: "auto" }}
                    text={labels.general.confirm}
                    onClick={async (e) => {
                      e.stopPropagation();
                      await _exec(true);
                    }}
                  />
                  <SpButton
                    buttonType="cancel"
                    style={{ flex: "auto" }}
                    text={labels.general.skip}
                    variant="h1PageTitle"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await _exec(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SpDialog>

          <SpDialog
            open={openConfirmOverwrite}
            setOpen={setOpenConfirmOverwrite}
            title={labels.general.confirm}
          >
            <Grid container direction="column">
              <Grid item xs={12}>
                <SpText variant={"h1PageSubtitle"}>
                  {labels.bundles.assessment.confirmOverwrite}
                </SpText>
              </Grid>
              <Grid item xs={12} container direction="row">
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-start",
                    marginTop: "2%",
                  }}
                  direction="row"
                >
                  <SpButton
                    buttonType="accept"
                    style={{ flex: "auto" }}
                    text={labels.general.confirm}
                    onClick={(e) => {
                      e.stopPropagation();
                      saveAnswers();
                    }}
                  />
                  <SpButton
                    buttonType="cancel"
                    style={{ flex: "auto" }}
                    text={labels.general.back}
                    variant="h1PageTitle"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenConfirmOverwrite(false);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SpDialog>
        </StyledBodyColumn>
      </div>
    </>
  );
};

export default withSnackbar(AddMonitoringAssessment);
