import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import SpTextInput from "../../atoms/SpTextInput";
import { labels } from "../../../pages/shared/translations";
import { logoutProfessional } from "../../../models/actions/Auth";
import SpButton from "../../atoms/SpButton";
import { useHistory } from "react-router-dom";
import { isBusiness, rollbar } from "../../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";

const AccountButton = (props) => {
  const { logout } = useAuth0();
  const { professionalData } = props;

  const history = useHistory();

  const logoutUser = async () => {
    try {
      await logoutProfessional();
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      logout({ returnTo: window.location.origin });
    } catch (error) {
      props.snackbarShowErrorMessage(error.message);
    }
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        alignSelf: "center",
      }}
    >
      <form
        action={process.env.WP_SILENT_LOGIN_URL}
        target="_self"
        method="POST"
      >
        {/*Start Hidden input*/}
        <SpTextInput
          name={"login_token"}
          style={{ display: "none" }}
          displayLabel={false}
          value={professionalData?.login_token}
        />
        <SpTextInput
          name={"email"}
          style={{ display: "none" }}
          displayLabel={false}
          value={professionalData?.email}
        />
        {/*End Hidden input*/}
        {isBusiness() && (
          <SpButton
            variant="none"
            buttonType={"accept"}
            text={labels.account.toolbar.go_to_account}
            type="submit"
          />
        )}
        <SpButton
          variant="none"
          buttonType={"accept"}
          style={{ marginLeft: "10px" }}
          onClick={logoutUser}
          text={labels.account.toolbar.logout}
        />
      </form>
    </Grid>
  );
};

export default AccountButton;
