import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import { styled } from "../../../components/styled";
import { labels, psTranslate } from "../../shared/translations";
import SpText from "../../../components/atoms/SpText";
import { SpTable, SpTableCell, SpTableRow } from "../../../components/bundles";
import { isMobile } from "react-device-detect";
import PatientsMonitoringAddPathology from "./patientsMonitoringStepPathologies/PatientsMonitoringAddPathology";
import PatientsMonitoringDetailPathology from "./patientsMonitoringStepPathologies/PatientsMonitoringDetailPathology";
import { getPathologiesByMonitoring } from "../../../models/actions/Pathologies";
import moment, { locale } from "moment";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import NoData from "../shared/utils/NoData";
import { useAuth0 } from "@auth0/auth0-react";
import { getUserPermission } from "../../../utils/common";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { Grid } from "@material-ui/core";
import SpDialog from "../../../components/atoms/SpDialog";

locale("it");

const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
});

const StyledTableColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumnToolbar = styled("div")({
  flex: 1,
  display: "flex",
  maxWidth: "33%",
  flexDirection: "column",
});

const PatientsMonitoringStepPathologies = (props) => {
  const [addIllnessPermission, setAddIllnessPermission] = useState();
  const [displayPathology, setDisplayPathology] = useState([]);
  const [addPresentationPathology, setAddPresentationPathology] =
    useState(false);
  const [detailPresentationPathology, setDetailPresentationPathology] =
    useState(false);
  const [pathologyDetailData, setPathologyDetailData] = useState();
  const [selectedPathology, setSelectedPathology] = useState();
  const { getAccessTokenSilently } = useAuth0();
  const { control } = useForm({
    shouldUnregister: false,
  });
  useEffect(() => {}, []);
  const { patId, presentationId } = useParams();
  const history = useHistory();
  const { endDate } = props;
  const { setLoading } = props;

  const fetchData = async () => {
    try {
      setLoading(true);

      const tempPermission = await getUserPermission(
        "new-illness",
        getAccessTokenSilently
      );
      setAddIllnessPermission(tempPermission);

      let results = await getPathologiesByMonitoring({ id_patient: patId });
      if (results) {
        results.reverse();
        setDisplayPathology(results);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!addPresentationPathology) {
      fetchData();
    }
  }, [addPresentationPathology]);

  useEffect(() => {
    if (!detailPresentationPathology) {
      fetchData();
    }
  }, [detailPresentationPathology]);

  const closeAssessmentAdd = () => {
    setAddPresentationPathology(false);
    setDetailPresentationPathology(false);
  };
  const handleDetailPathologyDetail = async (id_pathology) => {
    setSelectedPathology(id_pathology);
    setDetailPresentationPathology(true);
  };

  const handleAddPathologyDetail = async (id_pathology) => {
    setSelectedPathology(id_pathology);
    setAddPresentationPathology(false);
  };

  // Columns
  const headCellsPresentation = [
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: labels.patient.presentation.tableReason.toUpperCase(),
    },
    {
      id: "start",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.monitoring.pathologiesStep.table.toDate.toUpperCase(),
    },
    {
      id: "end",
      numeric: false,
      disablePadding: false,
      label:
        labels.patient.monitoring.pathologiesStep.table.fromDate.toUpperCase(),
    },
    {
      id: "duration",
      numeric: false,
      disablePadding: false,
      label: labels.agency.licenses.buyLicenseDialog.duration,
      isAction: false,
    },
  ];

  const _onRowClick = (path) => handleDetailPathologyDetail(path);

  //rendering the profile editing form
  const PathologyRow = ({ row }) => {
    return (
      <SpTableRow
        tabIndex={-1}
        onClick={() => _onRowClick(row)}
        style={{ cursor: "pointer" }}
      >
        <SpTableCell>
          <SpText variant="h4ComponentLabel">
            {psTranslate(row?.pathology?.name)}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.start_date || row.createdAt
              ? moment
                  .utc(row.start_date ? row.start_date : row.createdAt)
                  .format("DD/MM/yyyy")
              : ""}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row.end_date ? moment(row.end_date).format("DD/MM/yyyy") : ""}
          </SpText>
        </SpTableCell>
        <SpTableCell>
          <SpText variant="tableText">
            {row?.end_date
              ? parseInt(
                  moment
                    .duration(
                      moment(row.end_date).diff(
                        moment(row.start_date ? row.start_date : row.createdAt)
                      )
                    )
                    .asDays()
                )
              : parseInt(
                  moment
                    .duration(
                      moment().diff(
                        moment(row.start_date ? row.start_date : row.createdAt)
                      )
                    )
                    .asDays()
                )}
          </SpText>
        </SpTableCell>
      </SpTableRow>
    );
  };

  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={8}>
          <SpText variant="h1PageTitle">
            {labels.patient.monitoring.pathologiesStep.title.toUpperCase()}
          </SpText>
        </Grid>
        <Grid item xs={4}>
          {!endDate && addIllnessPermission && (
            <SpButton
              buttonType="accept"
              style={{ width: "100%" }}
              text={
                //addPresentationPathology
                //? labels.patient.presentation.closed
                //:
                labels.patient.presentation.detail.stepper.stepPathologies.add.toUpperCase()
              }
              onClick={() => setAddPresentationPathology(true)}
            />
          )}
        </Grid>

        {displayPathology?.length > 0 && (
          <StyledTableColumn>
            <StyledRow>
              <SpTable
                headCells={headCellsPresentation}
                pagination={true}
                rows={displayPathology}
                rowKey="id"
                onClick={() => setDetailPresentationPathology(true)}
                padding={false}
                notCheckable={false}
                tableContainerMaxHeight={
                  isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
                }
              >
                <PathologyRow />
              </SpTable>
            </StyledRow>
          </StyledTableColumn>
        )}
        <SpDialog
          style={{ padding: "1%" }}
          open={addPresentationPathology}
          setOpen={setAddPresentationPathology}
          title={labels.patient.presentation.detail.stepper.stepPathologies.add.toUpperCase()}
        >
          <PatientsMonitoringAddPathology
            closeAssessmentAdd={closeAssessmentAdd}
            handlePathologyDetail={handleAddPathologyDetail}
            setLoading={setLoading}
          />
        </SpDialog>

        {detailPresentationPathology && (
          <SpDialog
            // style={{ padding: "1%" }}
            open={detailPresentationPathology}
            setOpen={setDetailPresentationPathology}
            title={labels.patient.presentation.detail.stepper.stepPathologies.modify.toUpperCase()}
            setLoading={setLoading}
          >
            <PatientsMonitoringDetailPathology
              closeAssessmentAdd={closeAssessmentAdd}
              pathology={selectedPathology}
              setLoading={setLoading}
              control={control}
            />
          </SpDialog>
        )}
      </Grid>

      {displayPathology.length === 0 && (
        <NoData label={labels.patient.monitoring.noPathologies} />
      )}
    </>
  );
};

export default withSnackbar(PatientsMonitoringStepPathologies);
