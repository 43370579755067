import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import "../../App.css";
import SpTextInput from "../../components/atoms/SpTextInput";
import SpText from "../../components/atoms/SpText";
import SpButton from "../../components/atoms/SpButton";
import {
  addPatientToProfessional,
  getSports,
} from "../../models/actions/Patients";
import { styled } from "../../components/styled";
import { ArrowBack } from "@material-ui/icons";
import { FormControlLabel, Grid, Radio } from "@material-ui/core";
import { labels, psTranslate } from "../shared/translations";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import moment from "moment";
import { theme } from "../../components/theme";
import { getUserPermission } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SpRadio from "../../components/atoms/SpRadio";
import SpAutocomplete from "../../components/atoms/SpAutocomplete";

const TitleToolbarSection = styled("div")({
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "flex-start",
});

const formControlStyleCheck = {
  minWidth: 60,
  paddingLeft: "2%",
  paddingRight: "2%",
  paddingTop: "2%",
  justifyContent: "flex-start",
  color: theme.colors.primary.lightBlue,
};

const TitleWrapper = styled("div")({
  display: "flex",
  flex: 1,
  width: "40%",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "center",
  padding: "0 !important",
  margin: "0 !important",
  backgroundColor: theme.colors.primary.lightBlue,
});
const CustomRadioButton = styled(Radio)({
  border: `1px solid ${theme.colors.primary.lightBlue}`,
});
const StyledColumn = styled("div")({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
});
const StyledWrapper = styled("div")({
  flex: 1,
  display: "flex",
  width: "40%",
  minHeight: "60%",
  alignSelf: "center",
  padding: "3%",
  background: "transparent",
  border: `1px solid ${theme.colors.primary.lightBlue}`,
});

const PatientsAdd = (props) => {
  const [isPatientLabel, setIsPatientLabel] = useState(false);
  const [sports, setSports] = useState([]);
  const [sportsSelected, setSportsSelected] = useState([]);

  const { control, register, handleSubmit, reset } = useForm({
    shouldUnregister: false,
  });
  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const submitRequest = async (param) => {
    try {
      const response = await addPatientToProfessional({
        ...param,
        sport: sportsSelected,
      });
      if (response.error) {
        props.snackbarShowErrorMessage(response.error);
      } else {
        props.snackbarShowMessage(response.message);
        setSportsSelected([]);
        reset();
      }
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(async () => {
    const patientLabelTemp = await getUserPermission(
      "patient-label",
      getAccessTokenSilently
    );
    const tempSports = await getSports();
    setSports(tempSports);
    setIsPatientLabel(patientLabelTemp);
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <TitleToolbarSection>
        <ArrowBack
          id={"arrowBack"}
          onClick={() => history.push("/patients")}
          style={{
            width: 30,
            color: theme.colors.primary.lightBlue,
            height: 50,
            cursor: "pointer",
          }}
        />
        <SpText variant="h1" id={"navPatients"}>
          {isPatientLabel ? labels.nav.patients : labels.nav.athletes}
        </SpText>
      </TitleToolbarSection>

      <TitleWrapper>
        <SpText variant="h1PageTitle" id={"addPatientTitle"}>
          {isPatientLabel
            ? labels.patient.patientAdd.addPatient
            : labels.patient.patientAdd.title}
        </SpText>
      </TitleWrapper>
      <StyledWrapper>
        <form onSubmit={handleSubmit(submitRequest)} style={{ width: "100%" }}>
          <StyledColumn>
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <SpTextInput
                  id={"givenName"}
                  variant={"column"}
                  name={"givenName"}
                  inputRef={register}
                  style={{ width: "100%" }}
                  label={labels.patient.patientAdd.givenName}
                  value={control.givenName}
                />
              </Grid>
              <Grid item xs={6}>
                <SpTextInput
                  id={"familyName"}
                  variant={"column"}
                  name={"familyName"}
                  inputRef={register}
                  style={{ width: "100%" }}
                  label={labels.patient.patientAdd.familyName}
                  value={control.surName}
                />
              </Grid>
            </Grid>
            <SpTextInput
              id={"email"}
              variant={"column"}
              name={"email"}
              inputRef={register}
              label={labels.patient.patientAdd.email}
              value={control.email}
            />
            <Grid item container xs={12}>
              <Grid item xs={6}>
                <SpTextInput
                  id={"birth_date"}
                  style={{ "padding-top": "8px", width: "100%" }}
                  name="birth_date"
                  type="date"
                  maxValue={"2100-12-31"}
                  inputRef={register}
                  label={labels.patient.patientEditInput.birthDate}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  as={
                    <SpRadio
                      style={{
                        flex: 1,
                        display: "flex",
                        paddingBottom: "2%",
                      }}
                      aria-label="gender"
                    >
                      <FormControlLabel
                        style={formControlStyleCheck}
                        value="M"
                        control={<CustomRadioButton />}
                        label={labels.patient.patientRegistry.sexMan.toUpperCase()}
                      />
                      <FormControlLabel
                        style={formControlStyleCheck}
                        value="F"
                        control={<CustomRadioButton />}
                        label={labels.patient.patientRegistry.sexWoman.toUpperCase()}
                      />
                    </SpRadio>
                  }
                  name={"gender"}
                  control={control}
                  defaultValue={"M"}
                />
              </Grid>
            </Grid>
            <SpAutocomplete
              multiple
              id={"sportID"}
              style={{ width: "100%" }}
              formControlWidth={"100%"}
              selectPlaceholder={"Sports"}
              value={sportsSelected}
              onChange={(_, newValue) => setSportsSelected(newValue)}
              displayLabel={false}
              renderTags={(tagValue) =>
                tagValue.map((option) => (
                  <SpText
                    style={{ marginLeft: "8px" }}
                    variant="h4ComponentLabel"
                  >
                    {psTranslate(option.answer)}
                  </SpText>
                ))
              }
              options={sports}
              getOptionLabel={(option) => psTranslate(option.answer)}
              getOptionSelected={(option, value) => option.id === value?.id}
            />
            <SpButton
              id={"addPatientSubmit"}
              variant={"column"}
              type={"submit"}
              buttonType={"accept"}
              text={labels.groups.groupAdd.addProfessionalTable.add}
            />
          </StyledColumn>
        </form>
      </StyledWrapper>
    </Grid>
  );
};

export default withSnackbar(PatientsAdd);
