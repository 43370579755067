import React, { useState } from "react";
import SpText from "../../../components/atoms/SpText";
import Feedback from "../../../components/bundles/calendar_plan/Feedback";
import Feedbacks from "../../../components/bundles/calendar_plan/Feedbacks";
import { labels } from "../../shared/translations";
import SpButton from "../../../components/atoms/SpButton";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { showFeedbacksGrid } from "../../../utils/common";

const A5_RestDisabledDetails = ({
  event,
  allowFeedback,
  isGroupCard,
  updateActivityFeedback,
  setValue,
  register,
  handleSubmit,
  isPrint = false,
}) => {
  const data = event.data;
  const [showFeedback, setShowFeedback] = useState(false);
  const { patId, groupId } = useParams();
  const history = useHistory();
  return (
    <>
      <SpText variant="text">{labels.agenda.card.subject}</SpText>
      <SpText variant={isPrint ? "textPrint" : "h4ComponentLabel"}>
        {data?.rest_motivation}
      </SpText>

      {allowFeedback &&
        showFeedbacksGrid({
          event,
          patId,
          groupId,
          activity: "rest",
          showFeedback,
          setShowFeedback,
        })}

      {allowFeedback &&
        showFeedback &&
        (isGroupCard ? (
          <Feedbacks
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ) : (
          <Feedback
            click={handleSubmit(updateActivityFeedback)}
            setValue={setValue}
            event={event}
            register={register}
            isPrint={isPrint}
          />
        ))}
    </>
  );
};

export default A5_RestDisabledDetails;
