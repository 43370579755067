import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { SpStepper } from "../../components/bundles";
import "../../App.css";
import { styled } from "../../components/styled";
import { labels } from "../shared/translations";
import SpText from "../../components/atoms/SpText";
import PatientsEditSidebar from "./shared/PatientsEditSidebar";
import { findPatientByPk } from "../../models/actions/Patients";
import { withSnackbar } from "../../components/atoms/SpSnackBar";
import { Grid } from "@material-ui/core";
import { TempChart } from "./patientsStatisticsAnalytics/tempChart";
import PatientsAlerts from "./patientAlerts/PatientAlerts";
import { useFlags } from "launchdarkly-react-client-sdk";

import PatientsStatistics from "./PatientsStatistics";
import PatientsStatisticsAnalytics from "./patientsStatisticsAnalytics/PatientsStatisticsAnalyticsDashboard";
import { getUserPermission } from "../../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import SpLoader from "../../components/atoms/SpLoader";
import PatientsReports from "./PatientsReports";
import InjurySurveillanceConfigurable from "./patientsReports/InjurySurveillanceConfigurable";
import PatientsReportsStep5Exposure from "../patients/patientInjSurGen/PatInjSurGen";

const PatientsControlRoomInternalMenu = (props) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [isAlertEnabled, setIsAlertEnabled] = useState(false);
  const [isChartEnabled, setIsChartEnabled] = useState(false);
  const [statisticsPermission, setStatisticsPermission] = useState(false);
  const [analyticsPermission, setAnalyticsPermission] = useState(false);
  const [myLoading, mySetLoading] = useState(false);
  const [injurySuerveillancePermission, setInjurySurveillancePermission] =
    useState(false);
  const [spMenuLabels, setspMenuLabels] = useState([]);

  const { statisticsAnalytics, statistics, analytics } = useFlags();

  const { getAccessTokenSilently } = useAuth0();
  const { patId } = useParams();
  const history = useHistory();

  const { control, register, errors } = useForm({
    shouldUnregister: false,
  });

  const { setLoading } = props;

  //Fetching patient data
  const fetchData = async () => {
    try {
      const responseDetail = await findPatientByPk({ userId: patId });
      setCurrentPatientDetail(responseDetail);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const StepTitle = ({ children }) => <div>{children}</div>;

  const StepperControls =
    () =>
    ({}) =>
      (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: 7,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        />
      );

  useEffect(async () => {
    mySetLoading(true);
    let tmpLabels = labels.patient.controlRoomInternalMenu.titles;

    const tempAlertPermission = await getUserPermission(
      "manage:alert",
      getAccessTokenSilently
    );

    const tempChartPermission = await getUserPermission(
      "manage:chart",
      getAccessTokenSilently
    );
    const tempStatisticPermission = await getUserPermission(
      "manage:patient-statistics",
      getAccessTokenSilently
    );

    const tempAnalyticPermission = await getUserPermission(
      "manage:patient-analytics",
      getAccessTokenSilently
    );

    const injurySurveillancePermission = await getUserPermission(
      "injury-surveillance",
      getAccessTokenSilently
    );

    setIsAlertEnabled(tempAlertPermission);
    setIsChartEnabled(tempChartPermission);
    setStatisticsPermission(tempStatisticPermission);
    setAnalyticsPermission(tempAnalyticPermission);
    setInjurySurveillancePermission(injurySurveillancePermission);

    if (!tempAlertPermission)
      tmpLabels = tmpLabels.filter((item) => item.key !== "alerts");
    if (!statisticsAnalytics)
      tmpLabels = tmpLabels.filter(
        (item) => item.key !== "statistic-analytics"
      );
    console.log(statistics, tempStatisticPermission, analytics);
    if (!tempChartPermission)
      tmpLabels = tmpLabels.filter((item) => item.key !== "control_room");
    if (!statistics || !tempStatisticPermission)
      tmpLabels = tmpLabels.filter((item) => item.key !== "statistics");
    if (!analytics || !tempAnalyticPermission)
      tmpLabels = tmpLabels.filter((item) => item.key !== "analytics");
    if (!injurySurveillancePermission)
      tmpLabels = tmpLabels.filter(
        (item) => item.key !== "injury_surveillance"
      );

    tmpLabels = tmpLabels.map((item) => item.value);

    setspMenuLabels(tmpLabels.map((t) => <StepTitle>{t}</StepTitle>));
    mySetLoading(false);
  }, [statisticsAnalytics]);

  return (
    <div>
      {myLoading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: mySetLoading,
          componentName: props.componentName,
        })
      )}
      {!myLoading && (
        <Grid
          style={{ paddingLeft: "1%" }}
          direction="column"
          container
          spacing={2}
        >
          <Grid item xs={12} container spacing={2} direction="row">
            <Grid item xs={12}>
              <PatientsEditSidebar
                props={props}
                patId={patId}
                history={history}
                currentPatient={currentPatient}
              />
            </Grid>
            <Grid item xs={8}>
              <SpText variant="h1PageTitle">
                {labels.patient.sideBarPatEdit.controlRoom.toUpperCase()}
              </SpText>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} container spacing={2} direction="row">
                <SpStepper
                  titles={spMenuLabels}
                  stepperControls={StepperControls(errors)}
                >
                  {isChartEnabled && (
                    <TempChart
                      register={register}
                      control={control}
                      setLoading={mySetLoading}
                    />
                  )}
                  {statistics && statisticsPermission && (
                    <PatientsStatistics
                      register={register}
                      control={control}
                      setLoading={mySetLoading}
                    />
                  )}
                  {analytics && analyticsPermission && (
                    <PatientsReports
                      register={register}
                      control={control}
                      setLoading={mySetLoading}
                    />
                  )}
                  {statisticsAnalytics && (
                    <PatientsStatisticsAnalytics
                      register={register}
                      control={control}
                      setLoading={mySetLoading}
                    />
                  )}
                  {injurySuerveillancePermission && (
                    <>
                      <Grid
                        item
                        container
                        xs={12}
                        style={{
                          alignSelf: "flex-start",
                        }}
                      >
                        <SpText variant="h1PageTitle">
                          {labels.group.controlRoomInternalMenu.titles[4]?.value.toUpperCase()}
                        </SpText>
                      </Grid>
                      <PatientsReportsStep5Exposure />
                      <br />
                      <hr></hr>
                      <h1 style={{ color: "#FFFFFF" }}>
                        {" "}
                        {labels.injSur.details}{" "}
                      </h1>
                      <InjurySurveillanceConfigurable />
                    </>
                  )}
                  {isAlertEnabled && (
                    <PatientsAlerts
                      register={register}
                      control={control}
                      setLoading={setLoading}
                    />
                  )}
                </SpStepper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withSnackbar(PatientsControlRoomInternalMenu);
