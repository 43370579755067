import { Checkbox, Chip, Grid } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import React from "react";
import SpAutocomplete from "../../../components/atoms/SpAutocomplete";
import { labels, psTranslate } from "../../shared/translations";
import { theme } from "../../../components/theme";

const INTERNAL_LOAD = 1;

const LoadParametersField = ({
  loadParametersData,
  loadParametersList,
  setLoadParametersList,
  defaultParameter,
  internalLoadOnly = false,
  isPrint = false,
}) => {
  const loadParametersDataSorted = loadParametersData.sort((paramA, paramB) =>
    paramA.name.localeCompare(paramB.name)
  );

  return (
    loadParametersData && (
      <Grid
        item
        xs={12}
        container
        style={{ justifyContent: "flex-start", alignItems: "center" }}
      >
        <SpAutocomplete
          id="chooseParameterToAdd"
          multiple
          isPrint={isPrint}
          style={{ width: "100%", height: "100%", maxHeight: "100%" }}
          formControlWidth={"100%"}
          selectPlaceholder={
            labels.patient.viewPlan.addActivity.inputForm.chooseLoadParameters
          }
          options={
            internalLoadOnly
              ? loadParametersDataSorted.filter(
                  (param) => param.id_category_load === INTERNAL_LOAD
                )
              : loadParametersDataSorted
          }
          getOptionLabel={(option) => psTranslate(option.name)}
          getOptionSelected={(opt, value) => {
            return opt?.id === value?.id;
          }}
          value={loadParametersList}
          onChange={(_, newValue) => {
            setLoadParametersList(newValue);
          }}
          renderOption={(props, option) => (
            <li
              key={`option_${props.id}`}
              style={{
                color: option.selected
                  ? theme.colors.primary.white
                  : theme.colors.primary.black,
              }}
            >
              <Checkbox
                name={props.id}
                icon={<CheckBoxOutlineBlankIcon small />}
                checkedIcon={<CheckBoxIcon small />}
                style={{
                  marginRight: 5,
                  color: option.selected
                    ? theme.colors.primary.white
                    : theme.colors.primary.black,
                }}
                checked={option.selected}
              />
              {psTranslate(props.name)}
            </li>
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const _val = loadParametersDataSorted.find(
                (x) => x?.id === (option?.id ?? option)
              );
              return (
                <Chip
                  key={_val?.id}
                  style={{
                    backgroundColor: theme.colors.primary.lightBlue,
                    color: theme.colors.primary.white,
                  }}
                  label={psTranslate(_val?.name)}
                  size="medium"
                  {...getTagProps({ index })}
                />
              );
            })
          }
        />
      </Grid>
    )
  );
};

export default LoadParametersField;
