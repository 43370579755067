import { Divider, Grid } from "@material-ui/core";
import { nanoid } from "nanoid";
import React, { createRef, useEffect, useState } from "react";
import "../../../App.css";
import SpButton from "../../../components/atoms/SpButton";
import SpLoader from "../../../components/atoms/SpLoader";
import { SpSelect, SpSelectMenuItem } from "../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../components/atoms/SpSnackBar";
import SpText from "../../../components/atoms/SpText";
import {
  getAnalyticsConfigurationBy,
  setAnalyticsConfigurationBy,
} from "../../../models/actions/Patients";
import withSaveConfig from "../../shared/SaveWrapper";
import { labels } from "../../shared/translations";
import PatientsReportsStep6GeneralInjury from "./PatientsReportsStep6GeneralInjury";
import PatientsReportsStep7TrainingInjury from "./PatientsReportsStep7TrainingInjury";
import PatientsReportsStep8SevereInjury from "./PatientsReportsStep8SevereInjury";
import PatientsReportsStep9MuscleInjury from "./PatientsReportsStep9MuscleInjury";
import PatientsReportsStep10LigamentInjury from "./PatientsReportsStep10LigamentInjury";
import PatientsReportsStep11Reinjury from "./PatientsReportsStep11Reinjury";
import PatientsReportsStep12MatchInjury from "./PatientsReportsStep12MatchInjury";
import { theme } from "../../../components/theme";

const N_MAX_GRAPHS = 10;

const InjurySurveillanceConfigurable = ({
  requestSaveFlag,
  setConfig,
  loadConfiguration,
  startSave,
  ...props
}) => {
  const [selectedType, setSelectedType] = useState({});
  const [currentGraphs, setCurrentGraphs] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentType, setCurrentType] = useState({});

  // oggetto reference utilizzato per la creazione dei PDF
  const reference = {};

  useEffect(async () => {
    setCurrentType({
      ...currentType,
      generalInjury: {
        type: "generalInjury",
        value: PatientsReportsStep6GeneralInjury,
      },
      trainingInjury: {
        type: "trainingInjury",
        value: PatientsReportsStep7TrainingInjury,
      },
      matchInjury: {
        type: "matchInjury",
        value: PatientsReportsStep12MatchInjury,
      },
      severeInjury: {
        type: "severeInjury",
        value: PatientsReportsStep8SevereInjury,
      },
      muscleInjury: {
        type: "muscleInjury",
        value: PatientsReportsStep9MuscleInjury,
      },
      ligamentInjury: {
        type: "ligamentInjury",
        value: PatientsReportsStep10LigamentInjury,
      },
      reinjury: {
        type: "reinjury",
        value: PatientsReportsStep11Reinjury,
      },
    });

    setSelectedType(currentType[Object.keys(currentType)[0]]);
  }, []);

  // TODO: FIX
  // useEffect(() => {
  //   //Set graphs like saved
  //   if (loadConfiguration) {
  //     const newCurrentGraphs = {};
  //     Object.entries(loadConfiguration).forEach(([key, element]) => {
  //       newCurrentGraphs[key] = currentType[element.type];
  //     });
  //     console.log(newCurrentGraphs);
  //     setCurrentGraphs(newCurrentGraphs);
  //     localStorage.removeItem("pdfScreenshot");
  //   }
  // }, [loadConfiguration]);

  const MyDivider = ({ label }) => {
    const _dividerStyle = {
      padding: "1px",
      backgroundColor: theme.colors.primary.lightBlue,
      marginBottom: "5px",
      marginTop: "5px",
    };
    return (
      <Grid item xs={12} container direction="row">
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
        <Grid item xs={2} container direction="row">
          <SpText variant={"h4ComponentLabelPrint"}>{label}</SpText>
        </Grid>
        <Grid xs={12}>
          <Divider style={_dividerStyle} />
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container>
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      {/* Graph array */}
      <Grid xs={12} item container>
        {Object.entries(currentGraphs).map(([key, { type, value: Graph }]) => {
          // creazione della reference per ogni grafico aggiunto
          reference[key] = createRef(null);
          return (
            <Grid container xs={12} item key={key}>
              <Grid container xs={12} item>
                {/* div contenente la porzione di pagina da stampare */}
                <div ref={reference[key]} key={`devGrd ${key}`}>
                  <MyDivider
                    label={labels.patient.graphReport.section[type].title}
                  />
                  <Graph
                    config={loadConfiguration[key]?.value}
                    setConfig={(data) =>
                      setConfig({ [key]: { type: type, value: data } })
                    }
                    requestSaveFlag={requestSaveFlag}
                  />
                </div>
              </Grid>
              <Grid
                container
                xs={12}
                item
                style={{ marginBottom: "2%", marginTop: "10px" }}
              >
                <SpButton
                  text={labels.analytics.remove}
                  onClick={() => {
                    const newGraphs = { ...currentGraphs };
                    delete newGraphs[key];
                    setCurrentGraphs(newGraphs);
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {/* New graph controls */}
      <Grid xs={12} item container style={{ alignItems: "self-end" }}>
        <Grid xs={3} item>
          <SpSelect
            label={""}
            formControlWidth={"100%"}
            labelPaddingTop={"0"}
            value={selectedType}
            onChange={(evnt) => setSelectedType(evnt.target.value)}
          >
            {Object.entries(currentType).map(([key, value]) => (
              <SpSelectMenuItem key={key} value={value}>
                {labels.patient.graphReport.section[key].title}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>
        <Grid xs={3} item style={{ marginLeft: "3px" }}>
          <SpButton
            text={labels.analytics.add}
            buttonType="accept"
            onClick={() => {
              setCurrentGraphs({ ...currentGraphs, [nanoid()]: selectedType });
            }}
            disabled={Object.entries(currentGraphs).length >= N_MAX_GRAPHS}
          />
        </Grid>
        <Grid xs item style={{ textAlign: "right" }}>
          <SpButton
            text={labels.analytics.save}
            buttonType="accept"
            onClick={() => {
              startSave();
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withSaveConfig(
  withSnackbar(InjurySurveillanceConfigurable),
  getAnalyticsConfigurationBy,
  setAnalyticsConfigurationBy
);
