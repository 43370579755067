import Lsa from "./StackedLineChart.jsx";
import Sca from "./scatterplot.jsx";
import Agg from "./workload_analysis.jsx";
import Cards from "./Cards.jsx";
import dataMock from "./data_stackarea.jsx";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { findPatientByPk } from "../../../../models/actions/Patients";
import PatientsEditSidebar from "../../shared/PatientsEditSidebar";
import SpLoader from "../../../../components/atoms/SpLoader";
import GroupEditSidebar from "../../../groups/shared/GroupEditSidebar";
import { getGroupsById } from "../../../../models/actions/Groups";
import { getPromsResults, mapTestToTable } from "./chartHelper.js";
import { getFeedbackForChart } from "../../../../models/actions/Activity.js";
import moment from "moment";
import { labels, psTranslate } from "../../../shared/translations.js";
import {
  getAssessmentMonitList,
  getPromsResult,
  getTestsResultChart,
} from "../../../../models/actions/Assessment.js";
import TtabC from "./test_analysis_card.jsx";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../components/atoms/SpSelect.js";
import PaginatedTable from "./paginatedTable.js";
import { ChartSummary } from "./chartSummary.js";
import { controlRoomStep } from "../../../../utils/common.js";
import SpText from "../../../../components/atoms/SpText";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const TempChart = ({ ...props }) => {
  const [currentPatient, setCurrentPatientDetail] = useState();
  const [currentGroup, setCurrentGroup] = useState([]);
  const [promsDataChart, setPromsDataChart] = useState(dataMock);
  const [dataWorkloadChart, setDataWorkloadChart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStep, setSelectedStep] = useState(controlRoomStep.default);
  const [listProms, setlistProms] = useState([]);
  const [selectedProms, setSelectedProms] = useState([]);
  const [selectedTest, setSelectedTest] = useState("");
  const [uniqueTestNames, setUniqueTestNames] = useState([]);
  const [allTests, setAllTests] = useState([]);
  const [testTable, setTestTable] = useState([]);
  const { patId, groupId } = useParams();
  const history = useHistory();

  // translate each key of object
  const renameKeys = (objectToRename, mapKeys) => {
    const nuovoOggetto = {};
    for (const chiave in objectToRename) {
      if (mapKeys.hasOwnProperty(chiave)) {
        nuovoOggetto[mapKeys[chiave]] = objectToRename[chiave];
      } else {
        nuovoOggetto[chiave] = objectToRename[chiave];
      }
    }
    return nuovoOggetto;
  };

  useEffect(() => {
    if (allTests.length > 0) {
      setTestTable(mapTestToTable(allTests[0][selectedTest]));
    }
  }, [selectedTest]);

  useEffect(() => {
    if (listProms.length > 0) {
      setSelectedProms([listProms[0]]);
    }
  }, [listProms]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (patId) {
        const responseDetail = await findPatientByPk({ userId: patId });
        if (responseDetail) {
          setCurrentPatientDetail(responseDetail);
        }
        const data = await getPromsResult({ patId });
        const startDate = moment(moment().subtract(2, "months")).format(
          "YYYY-MM-DD"
        );
        let lstProms = [];
        if (data) {
          const firstKey = Object.keys(data)[0];
          for (let key in data[firstKey]) {
            if (key !== "Date") {
              lstProms.push(key);
            }
          }
        }
        setlistProms(lstProms.reverse());
        const endDate = moment().format("YYYY-MM-DD");
        let resultWorkload = await getFeedbackForChart({
          id_patient: patId,
          startDate,
          endDate,
        });
        setPromsDataChart(data);

        let keyTranslated = {};

        if (resultWorkload.length > 0) {
          Object.keys(resultWorkload[0]).map((key) => {
            if (key.includes("_")) {
              keyTranslated[key] = `${key.split("_")[0]}_${psTranslate(
                key.split("_")[1]
              )}`;
            } else {
              keyTranslated[key] = psTranslate(key);
            }
          });

          resultWorkload = resultWorkload.map((oggetto) =>
            renameKeys(oggetto, keyTranslated)
          );
          setDataWorkloadChart(resultWorkload);
        }

        const assessments = await getAssessmentMonitList({ id_patient: patId });
        const ids = assessments.map(({ id }) => id);
        const tempres = await getTestsResultChart({
          ids_monitoring_assessment: ids,
        });
        const tempUniqueName = Object.keys(tempres).sort();
        setUniqueTestNames(tempUniqueName);

        setAllTests([tempres]);
        if (tempres) {
          const tempKey = tempUniqueName[0];
          setSelectedTest(tempKey);
        }
      }
      if (groupId) {
        const groupResults = await getGroupsById({ id_group: groupId });
        setCurrentGroup(groupResults);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [expanded, setExpanded] = useState(false);

  const handleSelectChange = (e) => {
    setSelectedProms(e.target.value);
  };

  const handleAccordionChange = (isExpanded) => {
    setExpanded(isExpanded);
  };

  const Proms = () => {
    return (
      <>
        <Grid item xs={8} container direction="row" spacing={1}>
          <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
            PROMS
          </h1>
        </Grid>
        <Cards data={promsDataChart} />

        <Lsa
          data={promsDataChart}
          selP={selectedProms}
          features={listProms}
          general={true}
        />

        <Grid item xs={12} container direction="row" spacing={1}>
          <Accordion
            expanded={expanded}
            onChange={(event, isExpanded) => handleAccordionChange(isExpanded)}
            style={{
              background: "transparent",
              width: "90vw",
              display: "block",
              padding: "2em",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#31caad" }} />}
              style={{ background: "transparent", border: "1px solid #31caad" }}
            >
              <SpText variant="text">
                {labels.patient.chart.trendDetails}
              </SpText>
            </AccordionSummary>
            <AccordionDetails>
              <SpSelect
                displayLabel={true}
                label={labels.patient.chart.selectProm}
                onChange={handleSelectChange}
                value={selectedProms}
                multiple={true}
              >
                <SpSelectMenuItem value=""></SpSelectMenuItem>
                {listProms.map((testName, index) => (
                  <SpSelectMenuItem key={index} value={testName}>
                    {testName}
                  </SpSelectMenuItem>
                ))}
              </SpSelect>
            </AccordionDetails>
            <Grid item xs={12} container direction="row" spacing={1}>
              {selectedProms.map((prom, index) => (
                <Lsa
                  key={index}
                  data={promsDataChart}
                  selP={prom}
                  features={prom}
                />
              ))}
            </Grid>
          </Accordion>
        </Grid>

        {/*<Grid item xs={4} container direction="row" spacing={1}>
          <Sca data={promsDataChart} />
        </Grid>*/}

        {promsDataChart.length > 0 && (
          <PaginatedTable data={[...promsDataChart].reverse()} />
        )}
      </>
    );
  };

  const Workload = () => {
    return (
      <>
        <div style={{ maxWidth: "90vw" }}>
          <Grid item xs={8} container direction="row" spacing={1}>
            <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
              WORKLOADS
            </h1>
          </Grid>

          {dataWorkloadChart.length > 0 && (
            <Grid item xs={12} container direction="row" spacing={1}>
              <Agg data={dataWorkloadChart} />
            </Grid>
          )}

          {dataWorkloadChart.length > 0 && (
            <PaginatedTable data={[...dataWorkloadChart].reverse()} />
          )}
        </div>
      </>
    );
  };

  const Test = () => {
    return (
      <>
        <Grid item xs={12} container direction="row" spacing={1}>
          <h1 style={{ color: "#3d988e", textAlign: "center", fontSize: 50 }}>
            TEST
          </h1>
        </Grid>

        <Grid item xs={4} container direction="row" spacing={1}>
          <SpSelect
            displayLabel={true}
            label={labels.patient.chart.selectTest}
            onChange={(e) => setSelectedTest(e.target.value)}
            value={selectedTest}
          >
            <SpSelectMenuItem value=""></SpSelectMenuItem>

            {uniqueTestNames.map((testName, index) => (
              <SpSelectMenuItem key={index} value={testName}>
                {testName}
              </SpSelectMenuItem>
            ))}
          </SpSelect>
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          spacing={1}
          style={{ paddingTop: "2em" }}
        >
          {selectedTest && (
            <>
              {allTests.map((testData, index) => {
                const testMetrics = Object.keys(testData[selectedTest] || {});
                return testMetrics.map((testMetric, c) => (
                  <TtabC
                    key={`${index}-${c}`}
                    data={allTests}
                    test={selectedTest}
                    metrica={testMetric}
                  />
                ));
              })}

              <PaginatedTable data={[...testTable].reverse()} />
            </>
          )}
        </Grid>
      </>
    );
  };

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      {loading && <SpLoader />}
      {React.Children.map(props.children, (child) =>
        React.cloneElement(child, {
          setLoading: setLoading,
          componentName: props.componentName,
        })
      )}
      <Grid item xs={12} container spacing={2} direction="row">
        <Grid
          item
          container
          xs={12}
          style={{
            alignSelf: "flex-start",
          }}
        >
          <SpText variant="h1PageTitle">
            {labels.patient.controlRoomInternalMenu.titles[0].value.toUpperCase()}
          </SpText>
        </Grid>

        <Grid item xs={12} container direction="row" spacing={1}>
          <Grid item xs={12}>
            <ChartSummary
              currentPatient={currentPatient}
              selectedStep={selectedStep}
              setSelectedStep={setSelectedStep}
            />
          </Grid>
          {selectedStep === controlRoomStep.proms && <Proms />}
          {selectedStep === controlRoomStep.test && <Test />}
          {selectedStep === controlRoomStep.workload && <Workload />}
        </Grid>
      </Grid>
    </Grid>
  );
};
