import { Grid } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import SpButton from "../../../../components/atoms/SpButton";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import SpText from "../../../../components/atoms/SpText";
import {
  SpTable,
  SpTableCell,
  SpTableRow,
} from "../../../../components/bundles";
import {
  getAssessmentPresentationList,
  getAssessmentMonitList,
} from "../../../../models/actions/Assessment";
import { labels, psTranslate } from "../../../shared/translations";
import NoData from "../../shared/utils/NoData";

const AssessmentTable = ({
  openAssessmentDetail,
  addAssessment,
  repeatAssessment,
  endDate,
  setLoading,
  ...props
}) => {
  const [displayAssessments, setDisplayAssessments] = useState([]);

  const { presentationId, patId } = useParams();

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = presentationId
        ? await getAssessmentPresentationList({
            id_presentation: presentationId,
            id_patient: patId,
          })
        : await getAssessmentMonitList({ id_patient: patId });
      response?.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
      setDisplayAssessments(response);
    } catch (error) {
      props.snackbarShowErrorMessage(error);
    }
    setLoading(false);
  };

  useEffect(fetchData, []);

  // Columns
  const headCellsAssessment = [
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.name,
      isAction: false,
    },
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: labels.patient.monitoring.assessments.header.date,
      isAction: false,
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "",
      isAction: true,
    },
  ];

  const AssessmentRow = ({ row, index }) => (
    <>
      <SpTableRow key={row.id} tabIndex={index}>
        <SpTableCell
          onClick={() => openAssessmentDetail(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {row.assessment?.name
              ? psTranslate(row.assessment.name)
              : row.assessment_template.name}
          </SpText>
        </SpTableCell>
        <SpTableCell
          onClick={() => openAssessmentDetail(row)}
          style={{ cursor: "pointer" }}
        >
          <SpText variant="h4ComponentLabel">
            {moment(row.createdAt).format("DD/MM/yyyy")}
          </SpText>
        </SpTableCell>
        <SpTableCell
          style={{
            padding: 0,
            verticalAlign: "bottom",
            borderBottom: 0,
          }}
        >
          <SpButton
            variant={"standard"}
            type="tableList"
            onClick={() => repeatAssessment(row)}
            text={labels.patient.monitoring.repeatAssessment.toUpperCase()}
          />
        </SpTableCell>
      </SpTableRow>
    </>
  );

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          container
          xs={12}
          style={{
            alignSelf: "flex-start",
          }}
        >
          <SpText variant="h1PageTitle">
            {labels.patient.monitoring.steps[1].value.toUpperCase()}
          </SpText>
        </Grid>
        <Grid
          item
          xs={5}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <SpButton
            buttonType="accept"
            style={{ maxWidth: "25%", marginBottom: "10px" }}
            onClick={() => addAssessment()}
            text={labels.patient.monitoring.addAssessment}
          />
        </Grid>
      </div>
      {endDate &&
        (displayAssessments.length > 0 ? (
          <Grid item xs={12}>
            <SpText variant="h1PageSubtitle">
              {
                labels.patient.presentation.detail.stepper.step5
                  .closedPresentation
              }
            </SpText>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <SpText variant="h1PageSubtitle">
              {labels.patient.presentation.detail.stepper.step5.noData}
            </SpText>
          </Grid>
        ))}
      {displayAssessments.length === 0 ? (
        <NoData label={labels.patient.monitoring.assessments.noAssessments} />
      ) : (
        <SpTable
          headCells={headCellsAssessment}
          pagination={true}
          rows={displayAssessments}
          rowKey="id"
          padding={false}
          notCheckable={false}
          tableContainerMaxHeight={
            isMobile ? "calc(100vh - 300px)" : "calc(100vh - 320px)"
          }
        >
          <AssessmentRow />
        </SpTable>
      )}
    </>
  );
};

export default withSnackbar(AssessmentTable);
