import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import SpText from "../components/atoms/SpText";
import SpButton from "../components/atoms/SpButton";
import { labels } from "./shared/translations";
import { styled } from "../components/styled";
import { useHistory } from "react-router-dom";
import { getUserPermission } from "../utils/common";
import { useAuth0 } from "@auth0/auth0-react";
import { useFlags } from "launchdarkly-react-client-sdk";

import {
  getGenericRiskFactor,
  getGpsDevice,
  getManageOrganization,
  getManageSchedule,
} from "./MyLabFunction";
import { theme } from "../components/theme";
import {
  getBookingsList,
  isProfessionalInOrganizzation,
} from "../models/actions/Organization";

const StyledRowTemplateItem = styled(Grid)({
  border: `1px solid ${theme.colors.primary.lightBlue}`,
  padding: "1%",
  marginBottom: "1%",
});

const MyLab = (props) => {
  const history = useHistory();
  const [roleGeneralRiskFactor, setRoleGeneralRiskFactor] = useState(false);
  const { mylabAssessmentTestDataset } = useFlags();

  useEffect(async () => {
    const ret = await getBookingsList();
    props.setMyLabNotification(ret.some(({ status }) => status == "pending"));
  }, []);
  const { getAccessTokenSilently } = useAuth0();

  useState(false);

  const [gpsDevicePermission, setGpsDevicePermission] = useState(false);
  const [organizationPermission, setOrganizationPermission] = useState(false);
  const [enableOrganizationDetails, setEnableOrganizationDetails] =
    useState(false);
  const [activityPlanPermission, setActivityPlanPermission] = useState(false);
  const [exercisePermission, setExercisePlanPermission] = useState(false);
  const [testPermission, setTestPermission] = useState(false);
  const [handbookPermission, setHandbookPermission] = useState(false);
  const [workoutPermission, setWorkoutPermission] = useState(false);
  const [rehabPermission, setRehabPermission] = useState(false);
  const [treatmentPermission, setTreatmentsPermission] = useState(false);
  const [riskFactorPermission, setRiskFactorPermission] = useState(false);
  const [exerciseDatasetPermission, setExerciseDatasetPermission] =
    useState(false);
  const [disordersPermission, setDisordersPermission] = useState(false);

  useEffect(async () => {
    const disordersPermissionTemp = await getUserPermission(
      "manage:mylab-disorders",
      getAccessTokenSilently
    );
    setDisordersPermission(disordersPermissionTemp);

    const exerciseDatasetPermissionTemp = await getUserPermission(
      "manage:mylab-exercise-dataset",
      getAccessTokenSilently
    );
    setExerciseDatasetPermission(exerciseDatasetPermissionTemp);

    const riskFactorPermissionTemp = await getUserPermission(
      "manage:mylab-risk-factor",
      getAccessTokenSilently
    );
    setRiskFactorPermission(riskFactorPermissionTemp);

    const treatmentPermissionTemp = await getUserPermission(
      "manage:mylab-treatments",
      getAccessTokenSilently
    );
    setTreatmentsPermission(treatmentPermissionTemp);

    const rehabPermissionTemp = await getUserPermission(
      "manage:mylab-rehab",
      getAccessTokenSilently
    );
    setRehabPermission(rehabPermissionTemp);

    const activityPlanPermissionTemp = await getUserPermission(
      "manage:mylab-activity-plan",
      getAccessTokenSilently
    );
    setActivityPlanPermission(activityPlanPermissionTemp);

    const exercisePermissionTemp = await getUserPermission(
      "manage:mylab-exercise",
      getAccessTokenSilently
    );
    setExercisePlanPermission(exercisePermissionTemp);

    const testPermissionTemp = await getUserPermission(
      "manage:mylab-test",
      getAccessTokenSilently
    );
    setTestPermission(testPermissionTemp);

    const handbookPermissionTemp = await getUserPermission(
      "manage:mylab-handbook",
      getAccessTokenSilently
    );
    setHandbookPermission(handbookPermissionTemp);

    const workoutPermissionTemp = await getUserPermission(
      "manage:mylab-workout",
      getAccessTokenSilently
    );
    setWorkoutPermission(workoutPermissionTemp);

    const gpsDeviceTemp = await getUserPermission(
      "manage:gps-device",
      getAccessTokenSilently
    );
    setGpsDevicePermission(gpsDeviceTemp);

    const riskFactorTemp = await getUserPermission(
      "manage:general-risk-factor",
      getAccessTokenSilently
    );
    setRoleGeneralRiskFactor(riskFactorTemp);

    const manageOrganizationTemp = await getUserPermission(
      "manage:organization",
      getAccessTokenSilently
    );
    setOrganizationPermission(manageOrganizationTemp);

    const isProfOrg = await isProfessionalInOrganizzation({});
    setEnableOrganizationDetails(isProfOrg);
  }, []);

  return (
    <Grid
      style={{ paddingLeft: "1%" }}
      direction="column"
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <SpText variant="h1">{labels.mylab.page.title}</SpText>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: "2em" }}
        direction="column"
        container
        spacing={3}
      >
        {activityPlanPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.activityPlan}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/activity-plan");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/activity-plan/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {exercisePermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.excerciseSheet}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/excercise");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/excercise/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {testPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.assesment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/assesment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/assesment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {handbookPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.handbook}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/handbook");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/handbook/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {workoutPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.training}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/training");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/training/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {rehabPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.rehab}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/rehab");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/rehab/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {treatmentPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.treatment}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/treatment");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/treatment/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}

        {/* GENERIC RISK FACTOR */}
        {getGenericRiskFactor(roleGeneralRiskFactor, history)}

        {riskFactorPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">{labels.mylab.tempList.riskFactor}</SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/risk_factor");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/risk_factor/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {exerciseDatasetPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.customExercise}
              </SpText>
            </Grid>
            <Grid item xs={2}>
              <SpButton
                id="viewCustomExerciseButton"
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/custom-exercise");
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <SpButton
                id="addCustomExerciseButton"
                buttonType="accept"
                text={labels.mylab.tempList.action.add}
                onClick={() => {
                  history.push("/mylab/custom-exercise/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {mylabAssessmentTestDataset && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.assessmentTest}
              </SpText>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <SpButton
                buttonType="accept"
                text={labels.mylab.tempList.action.view}
                onClick={() => {
                  history.push("/mylab/assessment-test");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {disordersPermission && (
          <StyledRowTemplateItem item xs={12} container>
            <Grid item xs={8}>
              <SpText variant="h4">
                {labels.mylab.tempList.dysfunctionWeights}
              </SpText>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <SpButton
                id={"weightDisordersButton"}
                buttonType="accept"
                text={labels.mylab.tempList.action.manage}
                onClick={() => {
                  history.push("/mylab/dysfunction_weights/add");
                }}
              />
            </Grid>
          </StyledRowTemplateItem>
        )}
        {/*GPS DEVICE*/}
        {gpsDevicePermission && getGpsDevice(gpsDevicePermission, history)}
        {getManageOrganization(
          organizationPermission,
          history,
          props.myLabNotification
        )}
        {getManageSchedule(
          enableOrganizationDetails,
          history,
          props.myLabNotification
        )}
        <StyledRowTemplateItem item xs={12} container>
          <Grid item xs={8}>
            <SpText variant="h4">{"PROMs"}</SpText>
          </Grid>
          <Grid item xs={2}>
            <SpButton
              id={"proms"}
              buttonType="accept"
              text={labels.mylab.tempList.action.view}
              onClick={() => {
                history.push("/proms");
              }}
            />
          </Grid>
        </StyledRowTemplateItem>
      </Grid>
    </Grid>
  );
};

export default MyLab;
