import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import "../../../../App.css";
import SpButton from "../../../../components/atoms/SpButton";
import { styled } from "../../../../components/styled";
import { labels, psTranslate } from "../../../shared/translations";
import SpTextArea from "../../../../components/atoms/SpTextArea";
import {
  SpSelect,
  SpSelectMenuItem,
} from "../../../../components/atoms/SpSelect";
import { withSnackbar } from "../../../../components/atoms/SpSnackBar";
import {
  getMedicalSystemTypes,
  getPathologies,
  saveMonitoringPathology,
} from "../../../../models/actions/Pathologies";
import { getSortedAndTranslatedArray, rollbar } from "../../../../utils/common";
import SpTextInput from "../../../../components/atoms/SpTextInput";
import moment from "moment";
const StyledRow = styled("div")({
  flex: 1,
  display: "flex",
  paddingBottom: "1%",
});

const StyledBodyColumn = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
  paddingRight: "2%",
});

const StyledBodyHalfColumn = styled("div")({
  flex: 1,
  height: "80%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginRight: "10%",
});

const PatientsMonitoringAddPathology = (props) => {
  const [selectedPathology, setSelectedPathology] = useState();
  const [pathologies, setPathologies] = useState([]);
  const [filteredPathologies, setFilteredPathologies] = useState([]);
  const [medicalSystemTypes, setMedicalSystemTypes] = useState([]);
  const { patId } = useParams();
  const { setLoading } = props;

  const {
    reset,
    register,
    errors,
    control,
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm({
    shouldUnregister: false,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "assessments",
    keyName: "id",
    shouldUnregister: false,
  });

  const watchFilterParamsMedicalSystemType = watch("id_medical_system_type");

  const handleChange = async (value) => {
    await setSelectedPathology(value);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const resultPathology = await getPathologies();
      const resultMedicalSystemTypes = await getMedicalSystemTypes();
      setPathologies(getSortedAndTranslatedArray(resultPathology));
      setFilteredPathologies(getSortedAndTranslatedArray(resultPathology));
      setMedicalSystemTypes(
        getSortedAndTranslatedArray(resultMedicalSystemTypes)
      );
    } catch (error) {
      rollbar.error("PatientsMonitoringAddPathology - fetchData", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(async () => {
    await fetchData();
  }, []);

  useEffect(() => {
    if (
      getValues("id_medical_system_type") &&
      getValues("id_medical_system_type") !== -1
    ) {
      setFilteredPathologies(
        pathologies.filter(
          (pathologyElem) =>
            pathologyElem.id_medical_system_type ===
            getValues("id_medical_system_type")
        )
      );
    }
  }, [watchFilterParamsMedicalSystemType]);

  const savePathologyToMonitoring = async (data) => {
    try {
      const result = await saveMonitoringPathology({
        id_pathology: data.id_pathology,
        id_patient: patId,
        notes: data.notes,
        start_date: moment(getValues("start_date")).format("YYYY-MM-DD"),
      });
      if (result?.error) {
        props.snackbarShowErrorMessage(result.error);
      } else {
        props.snackbarShowMessage(result.message);
        return result?.id;
      }
    } catch (error) {
      rollbar.error(
        "PatientsMonitoringAddPathology - savePathologyToMonitoring",
        error
      );
      props.snackbarShowErrorMessage(error);
    }
  };

  const resetForm = () => {
    setFilteredPathologies(pathologies);
    setValue("id_medical_system_type", null);
    setValue("id_pathology", null);
  };

  return (
    <StyledBodyColumn>
      <form onSubmit={handleSubmit(savePathologyToMonitoring)}>
        <StyledRow>
          <StyledBodyHalfColumn>
            <SpTextInput
              name="start_date"
              type="date"
              id="startDate"
              style={{ width: "100%" }}
              maxValue={"2100-12-31"}
              defaultValue={
                getValues("start_date")
                  ? getValues("start_date")
                  : moment(new Date()).format("YYYY-MM-DD")
              }
              inputRef={register}
              // disabled={disableControl}
              label={labels.patient.monitoring.pathologiesStep.table.toDate}
            />

            <Controller
              style={{ marginTop: "3%" }}
              render={(props) => (
                <SpSelect
                  label={
                    labels.patient.monitoring.addPathology.medicalSystemType
                  }
                  value={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    handleChange(e.target.value);
                  }}
                >
                  {medicalSystemTypes?.map((p) => (
                    <SpSelectMenuItem key={p.id} value={p.id}>
                      {p.name
                        ? psTranslate(p.name)
                        : labels.patient.monitoring.pathologiesStep
                            .selectMedicalSystem}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={0}
              name={`id_medical_system_type`}
              control={control}
            />
          </StyledBodyHalfColumn>
        </StyledRow>
        <StyledRow>
          <StyledBodyHalfColumn>
            <Controller
              style={{ marginTop: "1%" }}
              render={(props) => (
                <SpSelect
                  label={labels.patient.monitoring.addPathology.pathology}
                  value={props.value}
                  onChange={(e) => {
                    props.onChange(e.target.value);
                    handleChange(e.target.value);
                  }}
                >
                  {filteredPathologies?.map((p) => (
                    <SpSelectMenuItem key={p.id} value={p.id}>
                      {p.name
                        ? psTranslate(p.name)
                        : labels.patient.monitoring.pathologiesStep
                            .selectPathology}
                    </SpSelectMenuItem>
                  ))}
                </SpSelect>
              )}
              defaultValue={0}
              name={`id_pathology`}
              control={control}
            />
          </StyledBodyHalfColumn>
        </StyledRow>
        <StyledRow>
          <StyledBodyHalfColumn>
            <SpTextArea
              name="notes"
              label={labels.patient.monitoring.pathologiesStep.note}
              inputRef={register}
            />
          </StyledBodyHalfColumn>
        </StyledRow>

        <StyledRow>
          {getValues("id_pathology") && (
            <SpButton
              style={{ marginTop: "1%" }}
              buttonType="accept"
              text={labels.patient.monitoring.pathologiesStep.saveAndClose}
              onClick={async () => {
                const idPathology = getValues("id_pathology");
                const idMedical = getValues(`id_medical_system_type`);
                const notes = getValues(`notes`);
                const pathology = filteredPathologies.find(
                  ({ id }) => id === idPathology
                );
                const res = await savePathologyToMonitoring({
                  id_pathology: idPathology,
                  id_medical_system_type: idMedical,
                  notes: notes,
                  assessments: [],
                });
                props.closeAssessmentAdd(false);

                await props.handlePathologyDetail({
                  id: res,
                  pathology,
                  id_pathology: idPathology,
                });
              }}
            />
          )}
          <SpButton
            style={{ marginTop: "1%" }}
            buttonType="accept"
            text={labels.patient.monitoring.pathologiesStep.reset}
            onClick={resetForm}
          />
        </StyledRow>
      </form>
    </StyledBodyColumn>
  );
};

export default withSnackbar(PatientsMonitoringAddPathology);
