import React from "react";
import amplitude from "amplitude-js";
import axios from "axios";
import moment from "moment-timezone";
import { useLocation, useHistory } from "react-router-dom";
import ReactPDF, {
  Document,
  Page,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { call } from "../models/actions/utils";
import { labels, psTranslate } from "../pages/shared/translations";
import jwt_decode from "jwt-decode";
import { convertPngToBW } from "../models/actions/Common";
import * as XLSX from "xlsx";
import { Grid } from "@material-ui/core";
import SpText from "../components/atoms/SpText";
import { Controller } from "react-hook-form";
import { SpSelect, SpSelectMenuItem } from "../components/atoms/SpSelect";
import SpButton from "../components/atoms/SpButton";

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * Object used in moment to define that the month and the year have to start from monday
 * @type {{week: {dow: number, doy: number}}}
 */
export const momentDefinition = {
  week: {
    dow: 1,
    doy: 1,
  },
};

/**
 * @param array list of items to sort and translate
 * @param param optional param to search in the object
 * @param sorting ASC|DESC
 * @returns {*} array sorted and translated in the "param" object parameter
 */
export const getSortedAndTranslatedArray = (
  array,
  param = "name",
  sorting = "ASC"
) => {
  let res;
  //If the database content language is the same of the client, return just the sorted list
  //To check if the language of the content is the same as that of the client, we have to take one content of the array and see if it is equal to its translation
  let sameLanguage = false; //We save in this variable if the client has the same language of the database contents (fallback language = "IT")
  if (array.length > 0) {
    const item = array[0];
    sameLanguage = param
      ? item[param] === psTranslate(item[param])
      : item === psTranslate(item);
  }

  if (sameLanguage) {
    res = [
      ...array?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      ),
    ];
  } else {
    res = array
      ?.map((x) => {
        param ? (x[param] = psTranslate(x[param])) : (x = psTranslate(x));
        return x;
      })
      ?.sort((a, b) =>
        param ? a[param].localeCompare(b[param]) : a.localeCompare(b)
      );
  }
  //The reverse() function can be replaced directly by the opposite compare() within the sort function
  return sorting === "DESC" ? res.reverse() : res;
};

export const isBusiness = () => {
  return window.location.hostname.includes("business");
};

export const barColors = () => {
  return [
    "#32CD32",
    "#98FB98",
    "#00FA9A",
    "#2E8B57",
    "#9ACD32",
    "#808000",
    "#20B2AA",
    "#AFEEEE",
    "#4682B4",
    "#B0C4DE",
    "#1E90FF",
    "#FFDEAD",
    "#F4A460",
  ];
};

export const barWithDimension = (nCols) => {
  const avarageWidth = 250; // media della grandezza del grafico
  const minCols = 6; // numero minimo di colonne per la grandezza impostata
  const maxCols = 10; // numero massimo di colonne per la grandezza impostata
  const max = avarageWidth / minCols; // massima dimensione calcolata per colonne
  const min = avarageWidth / maxCols; // minima dimensione calcolata per colonne
  let res = avarageWidth / nCols;
  if (res < max && res > min) return res;
  if (res < min) return min;
  if (res > max) return max;
};

const getWeekRange = (weeks, firstDay, endDay, calendar) => {
  for (let index = 0; index < weeks.length; index++) {
    let weeknumber = weeks[index];

    let firstWeekDay =
      index === 0
        ? moment(firstDay).week(weeknumber).day(0)
        : moment(firstDay).week(weeknumber).day(1);
    if (firstWeekDay.isBefore(firstDay)) {
      firstWeekDay = firstDay;
    }

    let lastWeekDay = moment(endDay).week(weeknumber).day(7);
    if (lastWeekDay.isAfter(endDay)) {
      lastWeekDay = endDay;
    }
    let weekRange = moment.range(firstWeekDay, lastWeekDay);
    calendar.push(weekRange);
  }
  return calendar;
};

export const getMonthList = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};

export const getCalendar = (date, dateRange) => {
  let calendar = [];

  let year = date?.year();
  let month = date?.month();
  let startDate = moment([year, month]);
  let firstDay = dateRange?.start;
  let endDay = moment(dateRange?.start).endOf("month");

  let monthRange = moment?.range(firstDay, endDay);
  let weeks = [];
  for (let mday of monthRange?.by("days")) {
    if (weeks.indexOf(mday.week()) === -1) {
      weeks.push(mday.week());
    }
  }

  calendar = getWeekRange(weeks, firstDay, endDay, calendar);
  // se il range di date scelto e' in due mesi diversi popolo l'array delle
  // settimane per i diversi mesi
  if (dateRange.start.month() !== dateRange.end.month()) {
    month = dateRange.end.month();
    startDate = moment([year, month]);
    firstDay = moment(dateRange.end).startOf("month");
    endDay = dateRange.end;

    monthRange = moment.range(firstDay, endDay);
    weeks = [];
    for (let mday of monthRange.by("days")) {
      if (weeks.indexOf(mday.week()) === -1) {
        weeks.push(mday.week());
      }
    }
    calendar = getWeekRange(weeks, firstDay, endDay, calendar);
  }
  return calendar;
};

// ==============================================
//LOG_EVENTS CALL
export const ELEMENT_TYPE = {
  SYMBOL: "SYMBOL",
  CALENDAR_ACTIVITY: "CALENDAR_ACTIVITY",
};

/**
 *  API Call to get all events of certain element es.(SYMBOL, ACTIVITY, ... )
 *  @param data: {element_type: String, record_table_id: number }
 *  @param record_table_id id of the record that is saved on the table specified in "element_type" param
 *  @param element_type constant that rampresent the name of element
 *  @returns Object that contain all event of an element categorized by event type es.(createdBy, modifiedBy, deletedBy, archivedBy)
 */
export const getLoggedEvents = async (data) => {
  return await call({
    url: "/get/log_events",
    data: { data },
  });
};

// ===============================================================================
//ROLLBAR UTILS
export const setRollbarUser = (rollbarUserId, rollbarUserEmail) => {
  Rollbar.configure({
    // logLevel: "info",
    payload: {
      person: {
        id: rollbarUserId, // required
        username: rollbarUserEmail,
        email: rollbarUserEmail,
      },
    },
  });
};

export const rollbar = {
  info: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME === "dev"
      ? console.info(component + " - " + msg)
      : Rollbar.info(component + " - " + msg);
  },
  debug: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME === "dev"
      ? console.debug(component + " - " + msg)
      : Rollbar.debug(component + " - " + msg);
  },
  warn: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME === "dev"
      ? console.warn(component + " - " + msg)
      : Rollbar.warn(component + " - " + msg);
  },
  error: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME === "dev"
      ? console.error("No Rollbar: " + component + " - " + msg)
      : Rollbar.error(component + " - " + msg);
  },
  critical: (component, msg) => {
    return process.env.REACT_APP_ENV_NAME === "dev"
      ? console.error(component + " - " + msg)
      : Rollbar.critical(component + " - " + msg);
  },
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
//=============================================================
export const actionPDF = async (takeScreenShot, props) => {
  return new Promise(async (resolve, reject) => {
    const screenshot = await takeScreenShot(props.reference.current); //base64
    const screenshotBW = await convertPngToBW({ base64: screenshot });
    let tempImagesScreenshot = await localStorage.getItem("pdfScreenshot");
    tempImagesScreenshot = JSON.parse(tempImagesScreenshot);
    if (!props.selectedPDF.includes(props.label)) {
      // il grafico non e' stato mai selezionato
      if (!tempImagesScreenshot) tempImagesScreenshot = [];
      tempImagesScreenshot.push({ value: screenshotBW, key: props.label });
      props.setSelectedPDF([...props.selectedPDF, props.label]);
    } else {
      // il grafico e' gia' stato inserito quindi va aggioranto
      tempImagesScreenshot = tempImagesScreenshot.filter(
        ({ key }) => key !== props.label
      );
      tempImagesScreenshot.push({ value: screenshotBW, key: props.label });
    }
    resolve(tempImagesScreenshot);
  });
};

// render component
const renderComponent = async (FeeAcceptance) =>
  new Promise(async (resolve, reject) => {
    const blob = await ReactPDF.pdf(
      <FeeAcceptance member_detail={"test"} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    if (url && url.length > 0) {
      resolve(url);
    }
  })
    .then((res) => res)
    .catch((error) => {
      snackbarShowErrorMessage(error);
      rollbar.error("common - pdfDownload", error);
    });

const generateDocument = (images) => {
  let pages = [];
  const styles = StyleSheet.create({
    page: { padding: 10 },
  });

  for (let i = 0; i <= images.length - 1; i += 2) {
    pages.push(
      <Page key={`pages ${i}`} size="A4" style={styles.page}>
        <Image source={images[i].value} />
        {images.length - 1 > i && <Image source={images[i + 1].value} />}
      </Page>
    );
  }
  const MyDocument = () => (
    <Document>
      {pages.map((page) => {
        return page;
      })}
    </Document>
  );
  return MyDocument;
};

//string to titleCase
export const titleCase = (str) => {
  try {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  } catch (error) {
    rollbar.error("titleCase: Error during string convertion", error);
    return "";
  }
};

// download the PDF
export const pdfDownload = async (
  images,
  snackbarShowErrorMessage,
  pdfName
) => {
  try {
    const MyDocument = generateDocument(images);
    const sampleTab = window.open();
    if (sampleTab) {
      try {
        let generatedUrl = await renderComponent(
          MyDocument,
          snackbarShowErrorMessage
        );
        if (generatedUrl) {
          const aTag = document.createElement("a");
          aTag.href = generatedUrl;
          aTag.style = "display: none";
          aTag.download = pdfName ? `${pdfName}.pdf` : "FeeAcceptance.pdf";
          document.body.appendChild(aTag);
          aTag.click();
        }
      } catch (error) {
        snackbarShowErrorMessage(error);
        rollbar.error("common - pdfDownload", error);
      }
    }
  } catch (error) {
    rollbar.error("common - pdfDownload", error);
  }
};

export const downloadScreenshot = async (props, setSelectedPDF, pdfName) => {
  try {
    let tempImagesScreenshot = await localStorage.getItem("pdfScreenshot");
    let imageList = JSON.parse(tempImagesScreenshot);
    pdfDownload(imageList, props.snackbarShowErrorMessage, pdfName);
    localStorage.removeItem("pdfScreenshot");
    setSelectedPDF([]);
  } catch (error) {
    props.snackbarShowErrorMessage(error);
    rollbar.error("PatientsReportsConfigurable - downloadScreenshot", error);
  }
};

export const getUserPermission = async (permission, getAccessTokenSilently) => {
  // se é presente accessToken implica che é stato effettuato il login con auth0
  // quindi i permessi dell'utente sono definiti altrimenti di default tutti i
  // permessi sono disabilitati e getAccessTokenSilently restituisce un'eccezione
  try {
    const accessToken = await getAccessTokenSilently();
    const accessTokenDecoded = jwt_decode(accessToken);
    return accessTokenDecoded?.permissions.includes(permission);
  } catch (error) {
    return false;
  }
};

//DATE FORMAT used in date fields
export const dateFormat = "YYYY-MM-DD";
export const dateFormatEndOfDay = `${dateFormat} 23:59:59`;
export const useQueryParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const UPLOAD_LIMIT = 100; //MB

export const loadFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = async () => {
      let imageDataUri = reader.result;
      resolve(imageDataUri);
    };
    reader.readAsDataURL(file);
  });
};

export const downloadExcel = (jsonExcel, fileName) => {
  const workbook = XLSX.utils.book_new();

  Object.keys(jsonExcel).map((item) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonExcel[item]);
    XLSX.utils.book_append_sheet(workbook, worksheet, item);
  });
  XLSX.writeFile(workbook, `${fileName ? fileName : "DataSheet"}.xlsx`);
};

export const getSelectmenu = (
  placeholderSelect,
  choise,
  name,
  nametext,
  control
) => {
  const selectmenuact = (
    <Controller
      style={{ marginTop: "3%", minWidth: "100%", width: "100%" }}
      render={(props) => (
        <SpSelect
          value={props.value}
          selectPlaceholder={placeholderSelect}
          onChange={(e) => {
            props.onChange(e.target.value);
          }}
        >
          {choise?.map((p) => (
            <SpSelectMenuItem key={p.key} value={p.key}>
              {p.value}
            </SpSelectMenuItem>
          ))}
        </SpSelect>
      )}
      defaultValue={""}
      name={name}
      control={control}
    />
  );

  const actduringinjury = (
    <Grid
      container
      item
      direction="column"
      xs={12}
      style={{ marginBottom: "2%", width: "100%" }}
    >
      <SpText variant="text" className="actduringinjuryLabel">
        {nametext}
      </SpText>

      <Grid
        container
        direction="column"
        xs={12}
        style={{ paddingRight: "8px" }}
      >
        {selectmenuact}
      </Grid>
    </Grid>
  );
  return actduringinjury;
};

/**
 * Return dateRange variable, a date range starting from today's date back to number of days specified
 *
 * @remarks
 * This is used by many methods
 *
 * @param days - Days before today
 * @return Object with start/end keys
 */
export const updateDates = (days) => {
  try {
    const mock = okFilters;
    mock.startDate = moment().subtract(days, "d").format("YYYY-MM-DD");
    mock.endDate = moment().format("YYYY-MM-DD");
    let dateRange = moment().range(mock.startDate, mock.endDate);
    return dateRange;
  } catch (error) {
    rollbar.error("common - updateDates", error);
  }
};

/**
 * Return a simply days subtraction from the today
 *
 * @param days - Days before today
 * @returns String with the specified date
 */
export const beforeDays = (days) => {
  if (days === 1) return moment().format("YYYY-MM-DD");
  else
    return moment()
      .subtract(days - 1, "d")
      .format("YYYY-MM-DD");
};

export const getCalendarEventsDates = ({ monthSelected }) => {
  const momentDate = moment(monthSelected).format("YYYY-MM-DD");
  let startOfMonth = moment(momentDate).startOf("month").format("YYYY-MM-DD");
  startOfMonth = moment(startOfMonth).subtract(7, "d").format("YYYY-MM-DD");
  let endOfMonth = moment(monthSelected).endOf("month").format("YYYY-MM-DD");
  endOfMonth = moment(endOfMonth).add(7, "days").format("YYYY-MM-DD");

  return { startOfMonth, endOfMonth };
};

export const getStandardDeviation = (array) => {
  const filtered = array.filter((x) => x != null);
  const n = filtered.length;
  const mean = filtered.reduce((a, b) => a + b) / n;
  return Math.sqrt(
    filtered.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n
  );
};

export const keysStatus = {
  unavailable: "unavailable",
  fit: "fit",
  any_complaint: "any_complaint",
  illness: "illness",
  illness_1: "in_illness",
  medical_attention: "medical_attention",
  time_loss: "time_loss",
};

export const getLabelFromPatientStatus = ({ status }) => {
  switch (status) {
    case keysStatus.time_loss:
      return labels.patient.list.table.patientRow.status.timeLoss;
    case keysStatus.illness:
      return labels.patient.list.table.patientRow.status.sick;
    case keysStatus.in_illness:
      return labels.patient.list.table.patientRow.status.sick;
    case keysStatus.any_complaint:
      return labels.patient.list.table.patientRow.status.any_complaint;
    case keysStatus.fit:
      return labels.patient.list.table.patientRow.status.healthy;
    case keysStatus.medical_attention:
      return labels.patient.list.table.patientRow.status.medical_attention;

    default:
      return labels.patient.list.table.patientRow.status.healthy;
  }
};

export const riskScoreThreshold = {
  low: 20, // low
  moderate: 60, // moderate
  hight: 80, // hight
};

export const getLabelRangeFromPatientStatus = ({
  value,
  threshold1,
  threshold2,
  threshold3,
}) => {
  if (value > threshold3) return labels.patient.injuryRisk.high;
  if (value > threshold2 && value <= threshold3)
    return labels.patient.injuryRisk.moderate;
  if (value > threshold1 && value <= threshold2)
    return labels.patient.injuryRisk.medium;
  if (value <= threshold1) return labels.patient.injuryRisk.low;
};

export const getColorFromPatientStatus = ({ status }) => {
  switch (status) {
    case keysStatus.time_loss:
      return "red";
    case "in_presentation":
      return "red";
    case keysStatus.illness:
      return "blue";
    case "in_illness":
      return "blue";
    case keysStatus.any_complaint:
      return "yellow";
    case keysStatus.medical_attention:
      return "orange";
    case "in_good_health":
      return "green";
    case keysStatus.fit:
      return "green";

    default:
      return "green";
  }
};

export const formatDateTimeToLocal = ({ date, time }) => {
  const offsetFormatted = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dateStartUTC = moment.utc(date, "YYYY-MM-DD");
  const start_time = moment.utc(time, "HH:mm:ss");
  dateStartUTC.set({
    hour: start_time.format("HH"),
    minute: start_time.format("mm"),
  });
  const dateStartLocal = dateStartUTC.tz(offsetFormatted);
  return dateStartLocal;
};

export const controlRoomStep = {
  default: "default",
  test: "test",
  workload: "workload",
  proms: "proms",
};

export const getDateFormat = (giornoStringa, oraStringa) => {
  const [anno, mese, giorno] = giornoStringa.split("-");
  const [ora, minuti] = oraStringa.split(":");

  const dataOra = new Date(anno, mese - 1, giorno, ora, minuti);
  return dataOra;
};

export const getAge = (patientAge) => {
  const currentDate = moment();
  const patAge = moment(patientAge, "YYYY");
  if (currentDate.diff(patAge, "years")) {
    return currentDate.diff(patAge, "years");
  } else {
    return ""; // calculates patient's age in years
  }
};

export const showFeedbacksGrid = ({
  event,
  patId,
  groupId,
  activity,
  showFeedback,
  setShowFeedback,
}) => {
  const history = useHistory();

  return (
    <Grid container item xs={12}>
      <Grid item xs={6} style={{ paddingTop: "1em" }}>
        <SpButton
          style={{ width: "100%" }}
          text={labels.patient.viewPlan.addActivity.inputForm.showFeedbacks}
          onClick={() => {
            history.push(
              groupId
                ? `/groups/statistics/${groupId}/${moment(event.start).format(
                    "YYYY-MM-DD"
                  )}/${activity}`
                : `/patients/edit/${
                    patId
                      ? patId
                      : event?.patients
                      ? event.patients[0].id
                      : event?.patient?.id
                  }/statistics/${moment(event.start).format(
                    "YYYY-MM-DD"
                  )}/${activity}`
            );
          }}
        />
      </Grid>
      {!(
        event.archiving_status === "completed_and_feedback_not_sent" ||
        event.archiving_status === "completed_and_feedback_sent"
      ) && (
        <Grid item xs={6} style={{ paddingTop: "1em" }}>
          <SpButton
            style={{ width: "100%" }}
            text={
              showFeedback
                ? labels.patient.viewPlan.addActivity.inputForm.hideFeedbacks
                : labels.patient.viewPlan.addActivity.inputForm.updateFeedbacks
            }
            onClick={() => setShowFeedback(!showFeedback)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export const generateExcel = async (appointments, getAccessTokenSilently) => {
  const patientLabelTemp = await getUserPermission(
    "patient-label",
    getAccessTokenSilently
  );
  const data = appointments.map((item) => ({
    [labels.patient.proms.dailyActivities]:
      item?.activity_datum?.activity_type?.name,
    [labels.patient.monitoring.table.startDate]: item?.start_date,
    [labels.patient.viewPlan.addActivity.inputForm.start_time]: moment
      .utc(item?.start_time, "HH:mm")
      .local()
      .format("HH:mm"),
    [labels.patient.viewPlan.addActivity.inputForm.end_time]: moment
      .utc(item?.end_time, "HH:mm")
      .local()
      .format("HH:mm"),
    [patientLabelTemp
      ? labels.nav.patients
      : labels.patient.component.backButtonLista]: item.patients
      .map((patient) => patient?.patientName)
      .join(", "),
  }));

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Appointments");

  XLSX.writeFile(
    workbook,
    `${moment().format("DD/MM/YYYY")}_${moment()
      .add(1, "day")
      .format("DD/MM/YYYY")}.xlsx`
  );
};
