import { Color3 } from "@babylonjs/core";
import Marker from "../Marker/Marker";

export default class MarkerBehaviour {
  constructor(scene, human, camera, markerGraphicsPool, colors, callbacks) {
    this.human = human;
    this.camera = camera;
    this.markerPool = markerGraphicsPool;
    this.scene = scene;
    this.areaColors = colors.map((elem) => ({
      color: Color3.FromHexString(elem.color),
      step: elem.step,
    }));

    //Update callbacks
    this.onEnterCallback = callbacks.onEnter;
    this.onExitCallback = callbacks.onExit;
    this.onRegionClickCallback = callbacks.onRegionMarkerClick;
    this.onRegionZoomCallback = callbacks.onRegionZoomClick;
    this.onAreaClickCallback = callbacks.onAreaMarkerClick;
    this.onAreaZoomCallback = callbacks.onAreaZoomClick;
    this.onStructureClickCallback = callbacks.onStructureMarkerClick;

    this.zoomedRegion = null;
    this.downClickResult = false;

    //Precreate all marker in the correct position, but with no graphics
    this.rootMarkersByName = {};
    human.skinAreas.forEach((area) => {
      this.rootMarkersByName[area.name] = new Marker(
        area,
        null,
        true,
        this.onAreaClickCallback,
        this.onEnterCallback,
        this.onExitCallback,
        (mark) => this._fakezoomToExpanded(mark)
      );
      this.rootMarkersByName[`${area.name}_structure`] = new Marker(
        area,
        null,
        false,
        this.onStructureClickCallback,
        this.onEnterCallback,
        this.onExitCallback,
        (mark) => this._fakezoomToExpanded(mark)
      );
    });
    this.rootMarkers = Object.values(this.rootMarkersByName);
    this.currentRegion = null;
    this.currentMarker = null;
  }

  //---------------------------------PUBLIC----------------------------------------
  setActive(isActive) {
    if (isActive) {
      //Update hover callbacks
      this.human.skinRegions.forEach((meshGroup) => {
        meshGroup.updateCallbacks(
          (group) => this.onRegionZoomCallback(group.name),
          (group) => this.onEnterCallback(group.name),
          (group) => this.onExitCallback(group.name)
        );
      });
      this.human.skinAreas.forEach((meshGroup) => {
        meshGroup.updateCallbacks(
          (group) => this.onAreaZoomCallback(group.name),
          (group) => this.onEnterCallback(group.name),
          (group) => this.onExitCallback(group.name)
        );
      });
      //Prepare zoom & callbacks
      this._exitLayers();
    } else {
      //Deactivate all marker & clicks
      this.rootMarkers.forEach((marker) => marker.setActive(false));
    }
  }

  zoomTo(regionName) {
    if (!regionName) {
      this._exitLayers();
      this.camera.zoomTo(null);
    } else {
      this._zoomToRegion(regionName);
    }
  }
  onDownClick() {
    //Got a click, check if we are hovering any symbol
    this.downClickResult = this.zoomedMarker?.children.every(
      (child) => !child.isHovering
    );
    return !!this.downClickResult;
  }
  onUpClick() {
    const prevValue = this.downClickResult;
    //If no hover (which means click) inside markers, we can exit expanded marker mode
    if (this.downClickResult) this.zoomTo(this.zoomedRegion?.name);
    this.downClickResult = false;
    return prevValue;
  }

  updateMarkers(markers) {
    //Regenerate markers child hierarchy
    let editedAreas = [];
    this.rootMarkers.forEach((elem) => elem.clear());
    markers.forEach((elem) => {
      if (!elem.name) return;
      const parts = elem.name.split("_");

      const region = this.human.skinRegions.find(
        (elem) => elem.name === parts[0]
      );

      if (parts.length > 1) {
        //Area section
        const area = region.children.find(
          (elem) => elem.name === `${parts[0]}_${parts[1]}`
        );
        if (region && area) {
          if (parts.length === 2) {
            this.rootMarkersByName[area.name].children.push(
              new Marker(
                area,
                elem,
                true,
                this.onAreaClickCallback,
                this.onEnterCallback,
                this.onExitCallback,
                (mark) => this._fakezoomToExpanded(mark)
              )
            );
          } else if (parts.length > 2) {
            this.rootMarkersByName[`${area.name}_structure`].children.push(
              new Marker(
                area,
                elem,
                false,
                this.onStructureClickCallback,
                this.onEnterCallback,
                this.onExitCallback,
                (mark) => this._fakezoomToExpanded(mark)
              )
            );
          }
          editedAreas.push(area);
        } else
          console.warn(
            `Requested marker area ${parts[1]} in region ${parts[0]} does not exist!`
          );
      }
    });

    //After tree is ready, update marker colors (which are based on tree structure)
    this.human.skinAreas.forEach((area) =>
      area.setAlternateMaterialColor(null)
    );
    this._computeColors(editedAreas);

    this.rootMarkers.forEach((elem) => elem.updateGraphics(this.markerPool));
    this.markerPool.clearCache();
  }

  _computeColors(editedAreas) {
    //Compute weights
    let minWeight = Infinity;
    let maxWeight = 0;
    const areaWeights = {};
    editedAreas.forEach((area) => {
      const allMarkers = this.rootMarkersByName[area.name].children.concat(
        this.rootMarkersByName[`${area.name}_structure`].children
      );

      const weight = allMarkers.reduce(
        (accum, elem) =>
          elem.marker.weight &&
          (accum += elem.marker.weight * elem.marker.severity),
        0
      );

      if (!isNaN(weight) && weight !== 0) {
        //"Special" formula
        areaWeights[area.name] = weight;

        if (weight > maxWeight) maxWeight = weight;
        if (weight < minWeight) minWeight = weight;
      }
    });

    //Prevent divide by 0
    if (maxWeight === minWeight) minWeight = 0;

    //Compute weighted colors, such as min is 0 and max is 1
    editedAreas.forEach((area) => {
      const lerpVal =
        (areaWeights[area.name] - minWeight) / (maxWeight - minWeight); //Map range
      if (lerpVal != null) {
        let col;
        for (let i = 1; i < this.areaColors.length; i++) {
          const low = this.areaColors[i - 1].step;
          const hi = this.areaColors[i].step;
          if (low <= lerpVal && lerpVal <= hi) {
            const adjLerp = (lerpVal - low) / (hi - low);
            col = Color3.Lerp(
              this.areaColors[i - 1].color,
              this.areaColors[i].color,
              adjLerp
            );
            break;
          }
        }
        area.setAlternateMaterialColor(col);
      } else area.setAlternateMaterialColor(null); //Fallback
    });
  }

  //---------------------------------PRIVATE----------------------------------------
  _exitLayers() {
    this._fakezoomToExpanded(null);
    this.zoomedRegion = null;

    //Update markers
    this.rootMarkers.forEach((marker) => marker.setActive(false));
    this.human.skinRegions.forEach((region) => region.setActive(true));
    this.human.skinAreas.forEach((area) => {
      area.setActive(false);
      area.setAlternateMaterialActive(true);
    });
  }

  _zoomToRegion(regionName) {
    const currentRegion = this.human.skinRegions.find(
      (region) => region.name === regionName
    );

    this._fakezoomToExpanded(null);
    this.zoomedRegion = currentRegion;

    if (currentRegion) {
      //Change visibility
      this.human.skinRegions.forEach((region) => region.setActive(false));
      this.human.skinAreas.forEach((area) =>
        area.setAlternateMaterialActive(false)
      );
      currentRegion.children.forEach((child) => {
        child.setActive(true);
        this.rootMarkersByName[child.name].setActive(true);
        this.rootMarkersByName[`${child.name}_structure`].setActive(true);
      });

      //Move camera
      this.camera.zoomTo(currentRegion.center);
    } else {
      console.warn(`Requested zoom region ${regionName} does not exist.`);
    }
  }

  _fakezoomToExpanded(marker) {
    this.zoomedMarker = marker;
    this.zoomedRegion?.children.forEach((child) => {
      child.setActive(false);
      this.rootMarkersByName[child.name].setActive(false, true);
      this.rootMarkersByName[`${child.name}_structure`].setActive(false, true);
    });
  }
}
